import PropTypes from 'prop-types';

const CloseIcon = ({ closeButtonClick, fontSize, weight }) => {
  const myStyle = {
    fontSize: `${fontSize}`,
    fontVariationSettings: `'FILL' 0, 'wght' ${weight}, 'GRAD' -25`,
  };

  return (
    <button
      className="mb-6 mr-auto material-symbols-rounded text-slate-600"
      style={myStyle}
      onClick={() => closeButtonClick()}
    >
      close
    </button>
  );
};

CloseIcon.propTypes = {
  closeButtonClick: PropTypes.func.isRequired,
};

export default CloseIcon;
