import { ReactNode, createContext, useContext, useState } from 'react';

type HeaderVisibilityContext = {
  isHeaderVisible: () => boolean;
  setHeaderVisible: () => void;
  setHeaderInvisible: () => void;
};

const HeaderVisibilityContext = createContext({} as HeaderVisibilityContext);

export function useHeaderVisibility() {
  return useContext(HeaderVisibilityContext);
}

export function HeaderVisibilityContextProvider(props: {
  children: ReactNode;
}) {
  const { children } = props;
  const [headerVisibility, setHeaderVisibility] = useState(true);

  function isHeaderVisible() {
    return headerVisibility;
  }

  function setHeaderVisible() {
    setHeaderVisibility(true);
  }

  function setHeaderInvisible() {
    setHeaderVisibility(false);
  }
  return (
    <HeaderVisibilityContext.Provider
      value={{
        isHeaderVisible,
        setHeaderVisible,
        setHeaderInvisible,
      }}
    >
      {children}
    </HeaderVisibilityContext.Provider>
  );
}
