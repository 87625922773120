import { useShoppingCart } from '@/context/ShoppingCartContext';

import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';
import * as Dialog from '@radix-ui/react-dialog';
import { ShoppingBagIcon } from 'lucide-react';

function CartBottomBar(props: { visible: boolean }) {
  const { visible } = props;
  const { getTotalItemsCount } = useShoppingCart();

  return (
    <Dialog.Root open={visible && getTotalItemsCount() > 0} modal={false}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed bottom-0 left-[10vw] max-w-[80vw]" />
        <Dialog.Content
          className="data-[state=closed]:animate-barDown data-[state=open]:animate-alphaBarUp
          fixed  bottom-0 left-[10vw] max-h-[85vh] w-full  max-w-[80vw]
          focus:outline-none px-[25px] py-3 text-white text-xs font-medium
          bg-buttonGreen cursor-pointer shadow-black shadow-xl drop-shadow-2xl rounded-t-lg"
          onClick={() => navigateToUrlPostOrigin('/checkout')}
        >
          <div className={'flex justify-between'}>
            <RenderCartItems />
            <div className="flex gap-1.5 items-center">
              <p className="text-xs font-medium uppercase">view cart</p>
              <ShoppingBagIcon size={16} strokeWidth={1.4} />
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function RenderCartItems() {
  const { getTotalItemsCount } = useShoppingCart();

  return (
    <>
      {getTotalItemsCount() === 0 && <p className="text-sm">Cart Empty</p>}

      {getTotalItemsCount() === 1 && (
        <p>
          <span className="text-sm">{`${getTotalItemsCount()} `}</span>
          Item added
        </p>
      )}

      {getTotalItemsCount() > 1 && (
        <p>
          <span className="text-sm">{`${getTotalItemsCount()} `}</span>
          Items added
        </p>
      )}
    </>
  );
}

export default CartBottomBar;
