import { useState } from 'react';

function CloudImage(props: { src: string; errorUI?: JSX.Element }) {
  const { src, errorUI } = props;
  const [loadError, setLoadError] = useState<boolean>(false);
  return (
    <>
      {!loadError && (
        <img
          src={src}
          className="object-cover w-full h-full"
          onError={() => setLoadError(true)}
        />
      )}
      {loadError && errorUI}
    </>
  );
}

export default CloudImage;
