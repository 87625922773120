import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';
import CloudImage from '../CloudImage';
import { CART_EMPTY_CHECKOUT_IMAGE_URL } from '../../../constants';

function CartEmptyUI() {
  return (
    <div className="flex flex-col items-center justify-center min-w-full min-h-screen overflow-scroll gap-12 w-max h-max bg-slate-200 ">
      <div className="relative ">
        <div className="relative w-full h-64">
          <CloudImage
            src={CART_EMPTY_CHECKOUT_IMAGE_URL}
            errorUI={
              <div className="flex items-center justify-center w-full h-full px-4 border rounded-lg border-slate-400">
                <p>Error loading empty cart image</p>
              </div>
            }
          />
        </div>
      </div>

      <p className="text-base font-semibold tracking-wide">
        Your Cart is Empty
      </p>

      <button
        className="px-6 py-3 text-sm font-semibold text-white uppercase bg-orangeHover"
        onClick={() => navigateToUrlPostOrigin('/')}
      >
        See Restaurants near you
      </button>
    </div>
  );
}
export default CartEmptyUI;
