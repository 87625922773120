// const FETCH_URL =
//   'https://www.swiggy.com/dapi/restaurants/list/v5?lat=28.65420&lng=77.23730&is-seo-homepage-enabled=true&page_type=DESKTOP_WEB_LISTING';

const BANNER_BASE_URL =
  'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_288,h_360/';

const RESTAURANT_CHAIN_IMG_BASE_URL =
  'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_660/';

/**
 * Used in case we failed to load restaurant image
 */
const RESTAURANT_IMAGE_PLACEHOLDER_URL =
  'https://media-assets.swiggy.com/swiggy/image/upload/dls-web/assets/images/placeholder-light.png';

const RESTAURANT_MENU_BASE_API =
  'https://www.swiggy.com/dapi/menu/pl?page-type=REGULAR_MENU&complete-menu=true&lat=28.65420&lng=77.23730&';

/**
 * Represents route for restaurant menu, append it with restaurantID before use
 */
const ROUTE_RESTAURANT_MENU = '/restaurants/menu/';

const DISH_IMAGE_URL =
  'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_208,h_208,c_fit/';

const CART_RESTAURANT_IMAGE_URL =
  'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_100,h_100,c_fill/';

const MENU_CAROUSEL_IMAGE_URL =
  'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_628,h_704/';

const LOGIN_IMAGE_ROUTE =
  'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/Image-login_btpq7r';

const CART_EMPTY_CHECKOUT_IMAGE_URL =
  'https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/2xempty_cart_yfxml0';

export {
  BANNER_BASE_URL,
  RESTAURANT_CHAIN_IMG_BASE_URL,
  RESTAURANT_IMAGE_PLACEHOLDER_URL,
  RESTAURANT_MENU_BASE_API,
  ROUTE_RESTAURANT_MENU,
  DISH_IMAGE_URL,
  CART_RESTAURANT_IMAGE_URL,
  MENU_CAROUSEL_IMAGE_URL,
  LOGIN_IMAGE_ROUTE,
  CART_EMPTY_CHECKOUT_IMAGE_URL,
};
