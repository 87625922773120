import React, { useState, useEffect, forwardRef } from 'react';

/**
 * InputLabel Component
 *
 * A reusable component for displaying a labeled input field.
 *
 * @param {string} props.label - tailwind class names for 'label-element'. NOTE : label text-size is animated internally, that's why it's not exposed as prop, callers are expected to not pass text size related classes in labelClassName
 * @param {function} [props.onInputChange] - (Optional) callback function that receives current input value.
 * @param inputElemRef - React ref for input element
 * @returns {JSX.Element} - The rendered InputLabel component. Note: label text-size is animated internally, that's why it's not exposed as prop, callers are expected to not pass text size related classes in labelClassName
 */
function InputLabel(
  props: {
    label: string;
    containerClassName: string;
    labelClassName: string;
    inputClassName: string;
    focussedByDefault: boolean;
    inputType: string;
    placeHolder: string;
    onInputChange?: (currentInput: string) => void;
  },
  inputElemRef: React.RefObject<HTMLInputElement>,
): JSX.Element {
  const {
    label,
    containerClassName,
    labelClassName,
    inputClassName,
    focussedByDefault,
    inputType,
    placeHolder,
    onInputChange,
  } = props;
  if (!inputElemRef)
    throw new Error(
      'passed inputRef is falsy, ensure you pass ref to input element from parent',
    );

  const focussedTextSize = 'text-[12px]';
  const blurTextSize = 'text-base';
  const [textSize, setTextSize] = useState(
    focussedByDefault ? focussedTextSize : blurTextSize,
  );

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onInputChange) onInputChange(e.target.value);
  }

  function handleOnFocus() {
    setTextSize(focussedTextSize);
  }

  function handleOnBlur() {
    if (inputElemRef.current)
      setTextSize(
        inputElemRef.current.value.length === 0
          ? blurTextSize
          : focussedTextSize,
      );
  }

  function focusInput() {
    if (inputElemRef.current) inputElemRef.current.focus();
  }
  useEffect(() => {
    if (focussedByDefault && inputElemRef.current) inputElemRef.current.focus();
  }, []);

  return (
    <div
      className={containerClassName}
      onClick={() => focusInput()}
      onFocus={() => handleOnFocus()}
      onBlur={() => handleOnBlur()}
    >
      <p
        className={` ${textSize} transition-all duration-300 min-h-6 mb-2 ${labelClassName}
        `}
      >
        {label}
      </p>
      <input
        ref={inputElemRef}
        type={inputType}
        name={label}
        className={`outline-none border-none bg-transparent  ${inputClassName}`}
        placeholder={placeHolder}
        onChange={(e) => handleInputChange(e)}
      />
    </div>
  );
}

export default forwardRef(InputLabel);
