import SignIn from './SignIn';
import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';
import SwiggyIcon from '../../assets/swiggy-1.svg';
import { useEffect, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

function HelpPopOver() {
  const popoverRef = useRef<HTMLDivElement>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const handleTouchOutside = (event: TouchEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      closePopover();
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchOutside);
    return () => {
      document.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);

  return (
    <Popover.Root open={isPopoverOpen} modal={true}>
      <Popover.Trigger
        className={`hover:text-[rgb(237,131,46)] ${isPopoverOpen ? 'text-[rgb(237,131,46)]' : 'text-slate-800'} min-w-12 min-h-12`}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        Help
      </Popover.Trigger>

      <Popover.Content
        className="w-max min-w-52 mt-4 mr-4 shadow-2xl p-4 bg-white rounded-lg border-x-0 border-y-0 border-t-2  border-[rgb(237,134,57)]"
        onInteractOutside={closePopover}
        onEscapeKeyDown={closePopover}
        onPointerDownOutside={closePopover}
        onFocusOutside={closePopover}
      >
        <div
          ref={popoverRef}
          className="flex flex-col gap-5 px-4 pt-4 pb-8 mr-16 text-sm font-normal tracking-wide text-gray-600 w-max mt-3mb-4 hover:font-semibold hover:text-gray-700 min-w-40"
        >
          <p>Help not included in</p>
          <p>Demo Project</p>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}

function HeaderCheckout() {
  return (
    <div className="sticky top-0 z-10 flex items-center justify-between w-full h-full min-w-full px-16 py-8 bg-white shadow-lg ">
      <div className="flex items-center gap-8">
        <p
          className="duration-200 cursor-pointer hover:scale-110"
          onClick={() => navigateToUrlPostOrigin('/')}
        >
          <img
            src={SwiggyIcon}
            alt="Logo"
            className="w-12 h-12 cursor-pointer ms-auto"
            onClick={() => {
              navigateToUrlPostOrigin('/');
            }}
          ></img>
        </p>
        <p>Secure Checkout</p>
      </div>
      <div className="flex items-center gap-16">
        <HelpPopOver />
        <SignIn />
      </div>
    </div>
  );
}

export default HeaderCheckout;
