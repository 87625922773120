import { Address } from '@/types/user';
import { GET_DELIVERY_CHARGE_URL } from './endpoints/deliverychargeendpoints';
import axios, { HttpStatusCode } from 'axios';
import { DeliveryChargesResponse } from './apiResponseTypes';

export async function fetchDeliveryCharge(
  deliveryAddress: Address,
  restaurantAddress: Address,
  successCallback: (data: DeliveryChargesResponse) => void,
  errCallback: (err: string) => void,
) {
  try {
    const getRequest = await axios.get(GET_DELIVERY_CHARGE_URL, {
      headers: {
        'X-Delivery-Address': JSON.stringify(deliveryAddress),
        'X-Restaurant-Address': JSON.stringify(restaurantAddress),
      },
      withCredentials: true,
    });

    if (getRequest.status === HttpStatusCode.Ok) {
      const data: DeliveryChargesResponse = getRequest.data;
      successCallback(data);
    } else errCallback('some error occurred');
  } catch (err: unknown) {
    console.error(`error while fetching delivery charges `);
    console.log(err);
  }
}
