import React, { useEffect } from 'react';
import { gsap } from 'gsap';

export function animateMarker(
  marker,
  newPosition,
  updateMarkerPosition,
  currentRoute,
  completionListener,
) {
  if (
    !marker ||
    !marker.current ||
    !newPosition ||
    !updateMarkerPosition ||
    !currentRoute ||
    !completionListener
  )
    return;
  const { props } = marker.current;
  //   const startLat = props.position.lat();
  const startLng = props.position.lng;
  const newLat = newPosition.lat();
  let newLng = newPosition.lng();

  // Crossing the 180° meridian and going the long way around the earth?
  if (Math.abs(newLng - startLng) > 180) {
    if (newLng > startLng) {
      newLng -= 360;
    } else {
      newLng += 360;
    }
  }

  // Animate the marker's position
  gsap.to(marker, {
    duration: Math.max(0.5, Math.random() * 2),
    lat: newLat,
    lng: newLng,
    ease: 'power1.out',
    // Assuming you have a setState or similar function to update the marker's position
    onUpdate: () => {
      const currentLat = gsap.getProperty(marker, 'lat');
      const currentLng = gsap.getProperty(marker, 'lng');
      // Update the state that holds the marker's position
      // This will depend on how your state management is set up

      const pos = { lat: currentLat, lng: currentLng };
      const index = currentRoute.findIndex(
        (route) => route.lat() === pos.lat && route.lng() === pos.lng,
      );
      console.log('index', index);

      // if (index !== -1) {
      //     currentRoute[index] = pos;
      // }
      updateMarkerPosition({ lat: currentLat, lng: currentLng });
    },
    onComplete: () => {
      updateMarkerPosition({ lat: newPosition.lat(), lng: newPosition.lng() });
      console.log(currentRoute);

      //   completionListener();
    },
  });
}
