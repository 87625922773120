import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

type GoogleMapContext = {
  getAwayTime: () => number;
  setCompletionListener: () => void;
  getRoute: () => google.maps.LatLng[];
  getCurrentRoute: () => google.maps.LatLng[];

  getPositionLatLng: () => google.maps.LatLng;
  getRiderPositionLatLng: () => google.maps.LatLng;
  startSimulation: () => void;
  getAccumulatedStepIndexes: () => number[];
  isUserLocationPermissionDenied: () => boolean;
};

const GoogleMapContext = createContext({} as GoogleMapContext);

export function useGoogleMap() {
  return useContext(GoogleMapContext);
}

export type MapPosition = {
  lat: number;
  lng: number;
  timestamp?: number;
};
export function GoogleMapContextProvider(props: { children: ReactNode }) {
  const { children } = props;

  const [isUserLocationAvailable, setIsUserLocationAvailable] = useState(false);
  const [route, setRoute] = useState<google.maps.LatLng[]>([]);
  const [currentRoute, setCurrentRoute] = useState<google.maps.LatLng[]>([]);

  const [accumulatedStepIndexes, setAccumulatedStepIndexes] = useState<
    number[]
  >([]);
  const [awayTime, setAwayTime] = useState(-1);
  const [position, setPosition] = useState<MapPosition | undefined>();
  const [riderPosition, setRiderPosition] = useState<MapPosition | undefined>();
  const [requestUserForlocation, setRequestUserForLocation] =
    useState<boolean>(false);
  const [userLocationPermissionDenied, setUserLocationPermissionDenied] =
    useState<boolean>(false);
  const offsetDirection = Math.random() > 0.5 ? 1 : -1;
  const offset = Math.max(0.5, Math.random()) * 0.02225 * offsetDirection;

  const { isLoaded: isScriptLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  });

  const simulationDuration = Math.floor(
    Math.max(1.5, Math.random() * 2) * 1000 * 60,
  );

  let stepIndex = 0;
  let simulationOngoing = false;

  useEffect(() => {
    if (isScriptLoaded) {
      requestUserLocationPermission();
    }
  }, [isScriptLoaded]);

  function getAwayTime() {
    return awayTime;
  }

  function setCompletionListener() {
    // completionListener();
  }

  function getRoute() {
    return route;
  }

  function getCurrentRoute() {
    return currentRoute;
  }

  function getPositionLatLng() {
    return new google.maps.LatLng(position?.lat || 0, position?.lng || 0);
  }

  function getRiderPositionLatLng() {
    return new google.maps.LatLng(
      riderPosition?.lat || 0,
      riderPosition?.lng || 0,
    );
  }

  function startSimulation() {
    if (route.length > 0 && !simulationOngoing) {
      simulationOngoing = true;
      stepIndex = 0;
      let elapsedtime = 0;
      const startTime = Date.now();
      const intervalTime = simulationDuration / route.length; // time per step
      console.log('simulationDuration', simulationDuration);
      console.log('route.length', route.length);

      console.log('intervalTime', intervalTime);

      const myInterval = setInterval(() => {
        stepIndex++;
        elapsedtime = Date.now() - startTime;

        if (stepIndex < route.length) {
          accumulateStepIndex(stepIndex);
        }
        if (stepIndex >= route.length) {
          clearInterval(myInterval);
          simulationOngoing = false;

          return;
        }
      }, 1000);
    }
  }

  function accumulateStepIndex(index: number) {
    setAccumulatedStepIndexes((prevIndexes) => {
      return [...prevIndexes, index];
    });
  }

  function getAccumulatedStepIndexes() {
    return accumulatedStepIndexes;
  }

  function needToRequestUserForLocation() {
    return requestUserForlocation;
  }

  function requestUserLocationPermission() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          timestamp: position.timestamp,
        });

        const riderLocation = {
          lat: position.coords.latitude + offset,
          lng: position.coords.longitude + offset,
        };

        setRiderPosition({
          lat: riderLocation.lat,
          lng: riderLocation.lng,
          timestamp: position.timestamp,
        });

        const directionsService = new window.google.maps.DirectionsService();
        const directionsRequest = {
          origin: riderLocation,
          destination: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(directionsRequest, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const routeSteps = result.routes[0].overview_path;
            setRoute(routeSteps);
            setCurrentRoute(routeSteps);
          }
        });
        setIsUserLocationAvailable(true);
        setRequestUserForLocation(false);
      },
      (error) => {
        console.error('Error getting user location:', error);
        if (error.code === error.PERMISSION_DENIED) {
          // setRequestUserForLocation(true);
          setUserLocationPermissionDenied(true);
        }
      },
    );
  }

  function isUserLocationPermissionDenied() {
    return userLocationPermissionDenied;
  }

  return (
    <GoogleMapContext.Provider
      value={{
        getAwayTime,
        setCompletionListener,
        getRoute,
        getCurrentRoute,
        getPositionLatLng,
        getRiderPositionLatLng,
        startSimulation,
        getAccumulatedStepIndexes,
        isUserLocationPermissionDenied,
      }}
    >
      {children}
    </GoogleMapContext.Provider>
  );
}
