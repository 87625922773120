import { useShoppingCart } from '@/context/ShoppingCartContext';

import { CART_RESTAURANT_IMAGE_URL } from '../../../constants';
import React, { useEffect, useRef, useState } from 'react';

import { generateKey } from '@/utils/keygen';

import VegIcon from '../../../images/Veg_symbol.svg';
import NonVegIcon from '../../../images/Non_veg_symbol.svg';
import { IndianRupee } from 'lucide-react';

import {
  CART_ITEM_NAME_COLOR,
  CART_ITEM_NAME_COLOR_CLASS_NAME,
} from '../themecolors';

import { gsapTransXAlpha_delay, gsapTransYAlpha } from '@/utils/gsap';
import { MinusIcon, PlusIcon, UtensilsIcon } from 'lucide-react';
import { CartRestaurant } from '@/types/carttypes';
import { Separator } from '@/components/ui/separator';
import { Address } from '@/types/user';

import { GstAndRestaurantCharges } from '@/api/apiResponseTypes';
import { fetchDeliveryCharge } from '@/api/deliveryChargeFetcher';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { navigateToUrlPostOrigin_newTab } from '@/utils/urlNavigator';
import { useCheckout } from '@/context/CheckoutContext';

function RestaurantImage(props: { restaurant: CartRestaurant }) {
  const { restaurant } = props;
  const [isError, setIsError] = useState(false);
  if (!isError)
    return (
      <img
        src={`${CART_RESTAURANT_IMAGE_URL}${restaurant.imageId}`}
        className="size-16"
        loading="lazy"
        onError={() => setIsError(true)}
      />
    );
  return (
    <UtensilsIcon
      size={64}
      color="rgb(289 145 62)"
      className="px-2 py-3 border border-orange-400 rounded-lg"
    />
  );
}

function CartDishes() {
  const { getCartItems } = useShoppingCart();

  const items = getCartItems();
  const itemElems = items.map((item) => {
    return (
      <div key={generateKey()} className="flex justify-center w-full py-2">
        <div className="flex items-center gap-2">
          <img
            src={item.veg ? VegIcon : NonVegIcon}
            alt="veg icon"
            className="w-4 h-4"
          ></img>
          <p
            key={generateKey()}
            className={`max-w-40 my-4 mr-4 text-sm font-normal tracking-tight  ${CART_ITEM_NAME_COLOR_CLASS_NAME} `}
          >
            {item.name}
          </p>
          <QuantityUpdaterButton
            preFilledQuantity={item.quantity}
            dishId={item.id}
          />
        </div>

        <div className="flex items-center mx-6 font-thin gap-1 text-slate-500">
          <div className="flex items-center">
            <IndianRupee
              className="size-3"
              strokeWidth={1.34}
              color={`${CART_ITEM_NAME_COLOR}`}
            />
            <p
              className={`text-xs whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
            >
              {item.price}
            </p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      {itemElems}
      <hr className="my-4 ml-1 border border-dashed" />
    </>
  );
}

function QuantityUpdaterButton(
  props: Readonly<{
    preFilledQuantity: number;
    dishId: number;
  }>,
) {
  const { preFilledQuantity, dishId } = props;
  const minusElemRef = useRef(null);
  const quantityElemRef = useRef(null);
  const plusElemRef = useRef(null);
  const { decreaseQuantity, increaseQuantity } = useShoppingCart();

  const animateIncreaseQuantity = (
    quantityElement: React.MutableRefObject<HTMLElement>,
    dishId: number,
  ) => {
    gsapTransYAlpha(quantityElement, 0, -14, 1, 0, 0.1, () => {
      increaseQuantity(dishId);
      gsapTransYAlpha(quantityElement, 9, 0, 0, 1, 0.1, undefined);
    });
  };

  const animateDecreaseQuantity = (
    quantityElement: React.MutableRefObject<HTMLElement>,
    dishId: number,
  ) => {
    gsapTransYAlpha(quantityElement, 0, 9, 1, 0, 0.1, () => {
      decreaseQuantity(dishId);
      gsapTransYAlpha(quantityElement, -14, 0, 0, 1, 0.1, undefined);
    });
  };

  return (
    <div className="flex items-center justify-around h-10 text-base text-green-600 uppercase bg-white border border-gray-300 select-none min-w-28 font-extralight">
      <MinusIcon
        ref={minusElemRef}
        className="h-full px-3 cursor-pointer me-auto w-9 hover:scale-110"
        onClick={() => {
          if (quantityElemRef.current)
            animateDecreaseQuantity(quantityElemRef.current, dishId);
          else decreaseQuantity(dishId);
        }}
      />
      <p ref={quantityElemRef} className="text-sm font-light">
        {preFilledQuantity}
      </p>
      <PlusIcon
        ref={plusElemRef}
        className="h-full px-3 cursor-pointer ms-auto w-9 hover:scale-125"
        onClick={() => {
          if (quantityElemRef.current)
            animateIncreaseQuantity(quantityElemRef.current, dishId);
          else increaseQuantity(dishId);
        }}
      />
    </div>
  );
}

function BillDetails_deliveryAddressAvailable() {
  const { getCartTotal } = useShoppingCart();
  const { getDeliveryCharges, getLocationAddress, getDeliveryChargesTotal } =
    useCheckout();

  function computeGstAndRestaurantChargesTotal() {
    const deliveryCharges = getDeliveryCharges();
    if (!deliveryCharges) return 0;
    return (
      deliveryCharges.gstAndRestaurantCharges.gst +
      deliveryCharges.gstAndRestaurantCharges.gstOnPlatformFee +
      (deliveryCharges.gstAndRestaurantCharges.packagingCharge
        ? deliveryCharges.gstAndRestaurantCharges.packagingCharge
        : 0)
    );
  }

  return (
    <div className="flex flex-col gap-3">
      <p className="text-sm font-medium tracking-wide">Bill Details</p>

      <BillLabelWithInfo label="Item Total" fee={getCartTotal()} />
      <BillLabelWithInfo
        label="Delivery Fee"
        label2={getDeliveryCharges()?.deliveryDistance}
        fee={getDeliveryCharges()?.deliveryFee}
        toolTip={<DeliveryFeeToolTip fee={getDeliveryCharges()?.deliveryFee} />}
      />

      <hr />
      <BillLabelWithInfo
        label="Platform Fee"
        fee={getDeliveryCharges()?.platformFee}
        toolTip={<PlatformFeeToolTip />}
      />
      <BillLabelWithInfo
        label="GST and Restaurant Charges"
        fee={computeGstAndRestaurantChargesTotal()}
        toolTip={
          <GstAndRestaurantFeeToolTip
            charges={getDeliveryCharges()?.gstAndRestaurantCharges}
          />
        }
      />
      <hr className="my-4 border border-black" />
      <div className="flex items-center justify-between text-sm font-semibold">
        <p className="uppercase">To Pay</p>
        <div className="flex items-center">
          <IndianRupee
            size={12}
            strokeWidth={3}
            color={`${CART_ITEM_NAME_COLOR}`}
          />

          <p>
            {(
              getCartTotal() +
              (getLocationAddress() ? getDeliveryChargesTotal() : 0)
            ).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
}
function BillDetails_deliveryChargesNotAvailable() {
  const { getCartTotal } = useShoppingCart();
  const { updateDeliveryCharges, setIsCartUpdating } = useCheckout();

  useEffect(() => {
    setIsCartUpdating(true);
    fetchDeliveryCharge(
      {} as Address,
      {} as Address,
      (data) => {
        updateDeliveryCharges(data);
        setIsCartUpdating(false);
      },
      (error) => {
        console.error(error);
        setIsCartUpdating(false);
      },
    );
  }, []);
  return (
    <div className="flex flex-col gap-3">
      <p className="text-sm font-medium tracking-wide">Bill Details</p>

      <BillLabelWithInfo label="Item Total" fee={getCartTotal()} />
    </div>
  );
}

function BillDetails() {
  const { getLocationAddress, getDeliveryCharges } = useCheckout();

  return (
    <>
      {getDeliveryCharges() && getLocationAddress() && (
        <BillDetails_deliveryAddressAvailable />
      )}
      {(!getDeliveryCharges() || !getLocationAddress()) && (
        <BillDetails_deliveryChargesNotAvailable />
      )}
    </>
  );
}

function DeliveryFeeToolTip(props: { fee: number | undefined }) {
  const { fee } = props;
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>
          <InfoCircledIcon className=" text-[rgb(61,74,92)]" />
        </TooltipTrigger>
        <TooltipContent
          className={`w-max  mb-2 shadow-2xl drop-shadow px-5 py-4 bg-white rounded-lg text-sm font-medium ${CART_ITEM_NAME_COLOR_CLASS_NAME}   `}
        >
          <p className="mt-1 mb-4">Delivery fee breakup for this order</p>
          <div className="flex items-center justify-between min-w-full my-4">
            <p
              className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
            >
              Standard Fee
            </p>
            <div className="flex items-center">
              <IndianRupee
                className="size-3"
                strokeWidth={1.34}
                color={`${CART_ITEM_NAME_COLOR}`}
              />
              <p
                className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
              >
                {fee}
              </p>
            </div>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

function PlatformFeeToolTip() {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>
          <InfoCircledIcon className=" text-[rgb(61,74,92)]" />
        </TooltipTrigger>
        <TooltipContent
          className={`w-max  mb-2 shadow-2xl drop-shadow px-5 py-4 bg-white rounded-lg text-sm font-medium ${CART_ITEM_NAME_COLOR_CLASS_NAME}   `}
        >
          <p className="mt-1 mb-4">Platform Fee</p>

          <p
            className={`text-xs max-w-[200px] break-words  font-thin ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
          >
            This fee helps us operate and improve our platform, delivering a
            seamless app experience.
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

function GstAndRestaurantFeeToolTip(props: {
  charges: GstAndRestaurantCharges | undefined;
}) {
  const { charges } = props;
  if (!charges) return null;

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>
          <InfoCircledIcon className=" text-[rgb(61,74,92)]" />
        </TooltipTrigger>
        <TooltipContent
          className={`w-max  mb-2 shadow-2xl drop-shadow px-5 py-4 bg-white rounded-lg text-sm font-medium ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
        >
          <p className="mt-1 mb-4">GST and Restaurant Charges</p>
          <div className="flex flex-col items-center justify-between min-w-full my-4 gap-2">
            {charges.packagingCharge && (
              <div className="flex justify-between w-full ">
                <p
                  className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
                >
                  Restaurant Packaging
                </p>
                <div className="flex items-center">
                  <IndianRupee
                    className="size-3"
                    strokeWidth={1.34}
                    color={`${CART_ITEM_NAME_COLOR}`}
                  />
                  <p
                    className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
                  >
                    {charges.packagingCharge}
                  </p>
                </div>
              </div>
            )}
            <div className="flex justify-between w-full ">
              <p
                className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
              >
                Restaurant GST
              </p>
              <div className="flex items-center">
                <IndianRupee
                  className="size-3"
                  strokeWidth={1.34}
                  color={`${CART_ITEM_NAME_COLOR}`}
                />
                <p
                  className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
                >
                  {charges.gst}
                </p>
              </div>
            </div>
            <p className="text-xs text-gray-400 font-thin mr-[30%]">
              Swiggy plays no role in taxes and charges levied by the govt. and
              restaurant
            </p>
            <div className="flex justify-between w-full ">
              <p
                className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
              >
                GST on Platform fee
              </p>
              <div className="flex items-center">
                <IndianRupee
                  className="size-3"
                  strokeWidth={1.34}
                  color={`${CART_ITEM_NAME_COLOR}`}
                />
                <p
                  className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
                >
                  {charges.gstOnPlatformFee}
                </p>
              </div>
            </div>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

function BillLabelWithInfo(props: {
  label: string;
  label2?: string;
  fee: number | undefined;
  toolTip?: JSX.Element;
}) {
  const { label, label2, fee, toolTip } = props;

  if (!fee) return null;

  return (
    <div className="flex items-center justify-between h-5">
      <div className="flex items-center gap-2">
        <p
          className={`text-sm font-light tracking-tight ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
        >
          {label}
        </p>
        {label2 && (
          <>
            <Separator className="w-[1px] h-3 bg-gray-500 " color="gray-500" />
            <p
              className={`text-sm font-light tracking-tight ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
            >
              {label2}
            </p>
          </>
        )}

        {toolTip}
      </div>
      <div className="flex items-center">
        <IndianRupee
          className="size-3"
          strokeWidth={1.34}
          color={`${CART_ITEM_NAME_COLOR}`}
        />
        <p
          className={`text-xs font-thin whitespace-nowrap ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
        >
          {fee}
        </p>
      </div>
    </div>
  );
}

function CancellationPolicy(props: {
  marginVertical: string;
  paddingHorizontal: string;
  paddingVertical: string;
}) {
  const { getDeliveryCharges } = useCheckout();

  if (!getDeliveryCharges()) return null;

  const { marginVertical, paddingHorizontal, paddingVertical } = props;
  return (
    <div
      className={`h-min bg-white ${marginVertical} ${paddingHorizontal} ${paddingVertical}`}
    >
      <div className="flex flex-col justify-center w-full px-6 py-4 border border-gray-300 rounded-lg max-w-96 ">
        <p className="">
          Review your order and address details to avoid cancellations
        </p>
        <p
          className={`text-xs tracking-wide font-thin mt-2 ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
        >
          <span className="mr-1 text-rose-600 font-extralight">Note:</span>
          If you cancel within 60 seconds of placing your order, a 100% refund
          will be issued. No refund for cancellations made after 60 seconds.
        </p>
        <p className="mt-4 mb-3 text-xs text-gray-400">
          Avoid cancellation as it leads to food wastage.
        </p>
        <p
          className="text-sm font-medium text-orange-600 underline cursor-pointer decoration-dotted underline-offset-4"
          onClick={() => navigateToUrlPostOrigin_newTab('/cancellation-policy')}
        >
          Read cancellation policy
        </p>
      </div>
    </div>
  );
}
function CheckoutCart(props: {
  marginVertical: string;
  paddingHorizontal: string;
  paddingVertical: string;
}) {
  const { marginVertical, paddingHorizontal, paddingVertical } = props;
  const { getCartRestaurantDetails } = useShoppingCart();
  const restaurant = getCartRestaurantDetails();

  return (
    <div className="min-w-64 ">
      <div
        className={`h-min bg-white ${marginVertical} ${paddingHorizontal} ${paddingVertical}`}
      >
        <div className="flex">
          <RestaurantImage restaurant={restaurant} />
          <div className="relative pl-4">
            <p className="font-semibold tracking-tight">{restaurant.name}</p>
            <p className="text-sm font-thin tracking-tighter text-gray-500">
              {restaurant.areaName}
            </p>
            <hr className="absolute bottom-0 w-10  border-[1.3px] border-black" />
          </div>
        </div>

        <CartDishes />
        <BillDetails />
      </div>

      <CancellationPolicy
        marginVertical={marginVertical}
        paddingHorizontal={paddingHorizontal}
        paddingVertical={paddingVertical}
      />
    </div>
  );
}

export default CheckoutCart;
