import { Separator } from '@/components/ui/separator';
import { PastOrder } from '@/types/types';
import { generateKey } from '@/utils/keygen';
import { IndianRupeeIcon } from 'lucide-react';
import {
  BG_COLOR_ORANGE,
  BORDER_COLOR_ORANGE,
  TEXT_COLOR_ORANGE,
} from '../themecolors';

const DASHBOARD_SEPEARTOR_COLOR = 'bg-slate-300';
const RESTAURANT_NAME_COLOR = 'text-[rgb(61,64,75)]';

const ORDER_LOCATION_COLOR = 'text-[rgb(130,132,130)]';

const DELIVERY_STATUS_COLOR = 'text-[rgb(120,122,120)]';

function PastOrders() {
  const testOrder: Array<PastOrder> = [
    {
      orderID: 1653454545,
      restaurantID: 34576356735673,
      restaurantName: 'Pizza Hut',
      imageUrl: `https://media-assets.swiggy.com/swiggy/image/upload/dls-web/assets/images/placeholder-light.png`,
      location: 'Sector37',
      orderDate: 'Sat, Jan 27, 2024',
      orderTime: '09:11PM',
      orderValue: `274`,
      orderDescription:
        'Veg Mayonnaise Dip x 1, Create Your Flavour Fun Combo - Box Of 2 - Veg Pizza x 1, Cheesy Dip x 1',
      deliveryDate: 'Sat, Jan 27, 2024',
      deliveryTime: '09:42 PM',
    },
    {
      orderID: 1653454545,
      restaurantID: 34576356735673,
      restaurantName: 'Pizza Hut',
      imageUrl: `https://media-assets.swiggy.com/swiggy/image/upload/dls-web/assets/images/placeholder-light.png`,
      location: 'Sector37',
      orderDate: 'Sat, Jan 27, 2024',
      orderTime: '09:11PM',
      orderValue: `274`,
      orderDescription:
        'Veg Mayonnaise Dip x 1, Create Your Flavour Fun Combo - Box Of 2 - Veg Pizza x 1, Cheesy Dip x 1',
      deliveryDate: 'Sat, Jan 27, 2024',
      deliveryTime: '09:42 PM',
    },
    {
      orderID: 1653454545,
      restaurantID: 34576356735673,
      restaurantName: 'Pizza Hut',
      imageUrl: `https://media-assets.swiggy.com/swiggy/image/upload/dls-web/assets/images/placeholder-light.png`,
      location: 'Sector37',
      orderDate: 'Sat, Jan 27, 2024',
      orderTime: '09:11PM',
      orderValue: `274`,
      orderDescription:
        'Veg Mayonnaise Dip x 1, Create Your Flavour Fun Combo - Box Of 2 - Veg Pizza x 1, Cheesy Dip x 1',
      deliveryDate: 'Sat, Jan 27, 2024',
      deliveryTime: '09:42 PM',
    },
    {
      orderID: 1653454545,
      restaurantID: 34576356735673,
      restaurantName: 'Pizza Hut',
      imageUrl: `https://media-assets.swiggy.com/swiggy/image/upload/dls-web/assets/images/placeholder-light.png`,
      location: 'Sector37',
      orderDate: 'Sat, Jan 27, 2024',
      orderTime: '09:11PM',
      orderValue: `274`,
      orderDescription:
        'Veg Mayonnaise Dip x 1, Create Your Flavour Fun Combo - Box Of 2 - Veg Pizza x 1, Cheesy Dip x 1',
      deliveryDate: 'Sat, Jan 27, 2024',
      deliveryTime: '09:42 PM',
    },
    {
      orderID: 1653454545,
      restaurantID: 34576356735673,
      restaurantName: 'Pizza Hut',
      imageUrl: `https://media-assets.swiggy.com/swiggy/image/upload/dls-web/assets/images/placeholder-light.png`,
      location: 'Sector37',
      orderDate: 'Sat, Jan 27, 2024',
      orderTime: '09:11PM',
      orderValue: `274`,
      orderDescription:
        'Veg Mayonnaise Dip x 1, Create Your Flavour Fun Combo - Box Of 2 - Veg Pizza x 1, Cheesy Dip x 1',
      deliveryDate: 'Sat, Jan 27, 2024',
      deliveryTime: '09:42 PM',
    },
  ];
  return (
    <div>
      <h2 className="my-4 text-xl font-semibold">Past Orders</h2>
      <div className="mt-8 mb-16 ">
        <Orders orders={testOrder} />
      </div>
    </div>
  );
}

function Orders(props: { orders: PastOrder[] }) {
  const { orders } = props;
  return orders.map((o) => {
    return (
      <div
        key={generateKey()}
        className="px-6 mb-8 border shadow-md w-max rounded-xl border-slate-400 bg-card text-card-foreground"
      >
        <div className="flex justify-between">
          <RestaurantAndOrderDetails order={o} />
        </div>

        <hr className="mt-5 border border-dashed mb-9" />

        <OrderDescriptionAndTotal order={o} />
        <ReorderAndHelp />
      </div>
    );
  });
}
function DeliveryStatus(props: { order: PastOrder }) {
  const { order } = props;
  return (
    <p className={`text-xs whitespace-nowrap ${DELIVERY_STATUS_COLOR}`}>
      {order.deliveryDate}, {order.deliveryTime}
    </p>
  );
}

function RestaurantAndOrderDetails(props: { order: PastOrder }) {
  const { order } = props;
  return (
    <div className="flex items-center w-full gap-4">
      <img src={order.imageUrl} className="size-36"></img>
      <div className="flex justify-between w-full">
        <div>
          <p className={`${RESTAURANT_NAME_COLOR}`}>{order.restaurantName}</p>
          <p className={`text-sm ${ORDER_LOCATION_COLOR}`}>{order.location}</p>
          <div
            className={`flex h-fit items-center text-xs ${ORDER_LOCATION_COLOR}`}
          >
            <p className="uppercase  whitespace-nowrap">
              order #{order.orderID}
            </p>
            <Separator
              orientation="vertical"
              className={`${DASHBOARD_SEPEARTOR_COLOR} mx-2 py-1.5 rounded-full`}
            />
            <p className="whitespace-nowrap">
              {order.orderDate}, {order.orderTime}
            </p>
          </div>
          <button
            className={`w-fit uppercase font-semibold text-[13px] ${TEXT_COLOR_ORANGE}`}
          >
            View Details
          </button>
        </div>
        <DeliveryStatus order={order} />
      </div>
    </div>
  );
}

function OrderDescriptionAndTotal(props: { order: PastOrder }) {
  const { order } = props;
  return (
    <div className="flex justify-between gap-8 my-8 text-[rgb(81,83,94)]">
      <p className="max-w-[70%] text-sm">{order.orderDescription}</p>
      <div className="flex items-center text-sm text-[rgb(111,113,124)]">
        <p>Total Paid : </p>
        <IndianRupeeIcon className="size-3" />

        <p>{order.orderValue} </p>
      </div>
    </div>
  );
}

function ReorderAndHelp() {
  return (
    <div className="flex mx-4 mb-8 gap-8">
      <button
        className={`px-9 py-3  uppercase font-bold text-[13px] text-white tracking-wider ${BG_COLOR_ORANGE}`}
      >
        Reorder
      </button>
      <button
        className={`px-9 py-3 uppercase border font-bold text-[13px] tracking-wider  ${TEXT_COLOR_ORANGE} ${BORDER_COLOR_ORANGE}`}
      >
        Help
      </button>
    </div>
  );
}

export default PastOrders;
