import { ReactNode, MouseEventHandler } from 'react';

function CheckoutContainer(props: {
  children: ReactNode;
  className: string;
  clickHandler?: MouseEventHandler<HTMLElement> | undefined;
}) {
  const { children, className, clickHandler } = props;
  return (
    <div className={className} onClick={clickHandler}>
      {children}
    </div>
  );
}

export default CheckoutContainer;
