import React, { useRef, useState } from "react";
import Dish from "./Dish";

const Hr = ({ showBottomDivider }) => {
  if (!showBottomDivider) return;
  return <hr className="w-full h-2 my-4 bg-slate-50"></hr>;
};

const CategoryHeader = ({ title, itemsCount, visibility }) => {
  return (
    <div className="flex justify-between w-full cursor-none hover:cursor-pointer">
      <p className="text-[rgb(159,156,151)]">{`${title} (${itemsCount})`}</p>
      <span className="material-symbols-rounded text-[rgb(170,166,161)] ">
        {visibility ? "expand_less" : "expand_more"}
      </span>
    </div>
  );
};

const CategoryDishes = ({ items, visibility }) => {
  const divRef = useRef(null);
  if (divRef.current) {
    if (visibility) divRef.current.classList.remove("hidden");
    else divRef.current.classList.add("hidden");
  }
  return (
    <div ref={divRef} className="hidden">
      {items.map((dish, index) => {
        return (
          <Dish
            key={dish.card.info.id}
            dish={dish}
            showBottomDivider={index < items.length - 1}
          />
        );
      })}
    </div>
  );
};

const CategoryItem = ({ item, showBottomDivider }) => {
  const [visibility, setVisibility] = useState(false);
  const { title, itemCards } = item;
  return (
    <div>
      <div
        className="flex justify-between cursor-none hover:cursor-pointer"
        onClick={() => setVisibility(!visibility)}
      >
        <CategoryHeader
          className="flex justify-between cursor-none hover:cursor-pointer"
          title={title}
          itemsCount={itemCards.length}
          visibility={visibility}
        />
      </div>

      <Hr showBottomDivider={showBottomDivider} />

      <CategoryDishes items={itemCards} visibility={visibility} />
    </div>
  );
};
const CategoryItems = ({ data }) => {
  const { title, categories } = data;
  const categoryItems = categories.map((c, index) => {
    return (
      <CategoryItem
        key={`cat_item_${title}_${index}`}
        item={c}
        showBottomDivider={index < categories.length - 1}
      />
    );
  });

  return (
    <div>
      <div className="w-full h-4 mt-6 bg-[rgb(50,54,55)]"></div>
      <div className="font-black mt-8 mb-2 text-[rgb(172,168,160)]">
        {title}
      </div>
      <div className="flex flex-col gap-8">{categoryItems}</div>
    </div>
  );
};

const Categories = ({ data }) => {
  const titles = data.map((c, index) => {
    return <CategoryItems key={`cat_${c.title}_${index}`} data={c} />;
  });

  return <div>{titles}</div>;
};

const RestaurantMenuCategoryNestedItem = ({ items }) => {
  if (!items) return;

  return (
    <div>
      <Categories data={items} />
    </div>
  );
};

export default RestaurantMenuCategoryNestedItem;
