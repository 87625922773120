import React, { useState } from 'react';
import { MENU_CAROUSEL_IMAGE_URL } from '../../constants';

import { computeDishPrice } from '../utils/pricecomputer';
import AddButton from './AddButton';
import { generateKey } from '../utils/keygen';

import CustomScrollbar from './CustomScrollbar';

const MenuCarousel = ({ cards }) => {
  console.log(cards);
  if (!cards || cards.length === 0) return null;

  const { title, carousel } = cards[0];
  const [scrollX, setScrollX] = useState(0);

  function handleScroll(e) {
    if (e.target) {
      const maxScrollWidth = e.target.scrollWidth - e.target.clientWidth;
      setScrollX(e.target.scrollLeft / maxScrollWidth);
    }
  }
  const carouselItems = carousel.map((c) => (
    <CarouselItem key={generateKey()} item={c} /> // using generateKey as key, as got duplicated for both dishId and creativeId
  ));
  return (
    <>
      {title}
      <div
        className="max-w-full overflow-x-scroll no-scrollbar"
        onScroll={handleScroll}
      >
        <div className="flex w-full mb-8 mr-32  gap-32">{carouselItems}</div>
      </div>
      <CustomScrollbar scrollPosition={scrollX} />
    </>
  );
};

const CarouselItem = ({ item }) => {
  const { creativeId, dish } = item;
  const { price, defaultPrice } = dish.info;
  function addButtonClickHandler() {
    console.log('add button clicked');
  }

  return (
    <div className="my-8 ">
      <img
        src={`${MENU_CAROUSEL_IMAGE_URL}${creativeId}`}
        className="min-w-[330px]"
      ></img>
      <div className="flex items-center justify-between w-full px-4 -mt-16">
        <p className="text-[#FFFFFF] text-xs ml-1">
          &#x20b9;{computeDishPrice(price, defaultPrice)}
        </p>
        <AddButton clickHandler={addButtonClickHandler} />
      </div>
    </div>
  );
};

export default MenuCarousel;
