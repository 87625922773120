import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchRestaurantMenu } from '../utils/swiggyDataFetcher';
import RestaurantMenuCardTypeV2RestaurantInfo from './RestaurantMenuCardTypeV2RestaurantInfo';
import RestaurantMenuCategoryNestedItem from './RestaurantMenuCategoryNestedItem';
import RestaurantMenuItemCategoryCards from './RestaurantMenuItemCategoryCards';
import MenuCarousel from './MenuCarousel';
import SwitchCartRestaurantDialog from './SwitchCartRestaurantDialog';
import CartBottomBar from './checkout/CartBottomBar';
import { useShoppingCart } from '@/context/ShoppingCartContext';

const RestaurantMenu = () => {
  const { resId } = useParams();
  const [itemCategoryCards, setItemCategoryCards] = useState(null);
  const [restaurantInfoCards, setRestaurantInfoCards] = useState(null);
  const [menuCarouselCards, setMenuCarouselCards] = useState(null);
  const [nestedItemCategoryCards, setNestedItemCategoryCards] = useState(null);
  const [renderCartBar, setRenderCartBar] = useState(false);

  const { getCartRestaurantSwitchDialogVisibility } = useShoppingCart();
  useEffect(() => {
    fetchRestaurantMenu(
      resId,
      (menu) => {
        setItemCategoryCards(menu.itemCategoryCards);
        setRestaurantInfoCards(menu.restaurantInfoCards);
        setNestedItemCategoryCards(menu.nestedItemCategoryCards);
        setMenuCarouselCards(menu.menuCarouselCards);
        setRenderCartBar(true);
        // logExtractedMenu(menu);
      },
      (errorStatus, errorStatusText) => {
        console.error(errorStatus);
        console.error(errorStatusText);
      },
    );
  }, []);
  return (
    <div className="relative px-[10vw] py-[10vh] select-none">
      <SwitchCartRestaurantDialog />
      <RestaurantMenuCardTypeV2RestaurantInfo card={restaurantInfoCards} />
      <MenuCarousel cards={menuCarouselCards} />
      <RestaurantMenuItemCategoryCards
        restaurantId={resId}
        cards={itemCategoryCards}
        infoCard={restaurantInfoCards ? restaurantInfoCards[0] : undefined}
      />
      <RestaurantMenuCategoryNestedItem items={nestedItemCategoryCards} />
      {renderCartBar && (
        <CartBottomBar visible={!getCartRestaurantSwitchDialogVisibility()} />
      )}
    </div>
  );
};

export default RestaurantMenu;
