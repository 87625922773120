import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';
import { useEffect, useRef, useState } from 'react';

import Logo from '../../assets/logo.svg';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import OrderTrackerMap from './OrderTrackerMap';
import OrderTrackerDeliveryPartner from './OrderTrackerDeliveryPartner';
import OrderTrackerDishes from './OrderTrackerDishes';
import axios, { HttpStatusCode } from 'axios';
import { ORDER_IN_PROGRESS_URL } from '@/api/endpoints/orderendpoints';
import { ProgressBar } from 'react-loader-spinner';

enum FetchStatus {
  Fetching = 'fetching',
  Error = 'error',
  Success = 'success',
}

function OrderTrackerPage() {
  const [fetchStatus, setFetchStatus] = useState<string>(FetchStatus.Fetching);
  const [gotPendingOrders, setGotPendingOrders] = useState<boolean>(false);
  const [dishes, setDishes] = useState<string[]>([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState<number>(0);
  useEffect(() => {
    async function fetchData() {
      setFetchStatus(FetchStatus.Fetching);
      try {
        const pendingOrdersRequest = await axios.get(ORDER_IN_PROGRESS_URL, {
          withCredentials: true,
        });
        console.log(pendingOrdersRequest);
        const requestStatusOk =
          pendingOrdersRequest.status === HttpStatusCode.Ok;
        if (!requestStatusOk) {
          setFetchStatus(FetchStatus.Error);
        } else {
          setFetchStatus(FetchStatus.Success);
          setGotPendingOrders(
            requestStatusOk && pendingOrdersRequest.data.length > 0,
          );

          const pendingOrders = pendingOrdersRequest.data;

          setPendingOrdersCount(pendingOrders.length);

          const dishesArray =
            pendingOrders.length > 1
              ? pendingOrders[pendingOrders.length - 1].dishes.map(
                  (dish: unknown) => dish.name,
                )
              : pendingOrders[0].dishes.map((dish: unknown) => dish.name);

          setDishes(dishesArray);
        }
      } catch (error) {
        console.log(error);
        setFetchStatus(FetchStatus.Error);
      }
    }
    fetchData();
  }, []);

  if (fetchStatus === FetchStatus.Fetching)
    return (
      <div className="flex w-full h-[100vh] justify-center items-center">
        <p className="text-sm">Fetching Pending Orders</p>
        <ProgressBar
          visible={true}
          height="40"
          borderColor="#444"
          barColor="rgb(120,20,100)"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  if (fetchStatus === FetchStatus.Error)
    return (
      <div>
        <p className="p-8">Error fetching pending orders, refresh to retry</p>
      </div>
    );
  if (!gotPendingOrders)
    return (
      <div>
        <p>No Pending Orders</p>
      </div>
    );

  return (
    <div className="mx-2 my-3 md:mx-10 ">
      <div className="flex items-center justify-between w-full px-2 mb-16 gap-8 ">
        <button
          className="cursor-pointer duration-200 h-max size-10 hover:scale-110"
          onClick={() => navigateToUrlPostOrigin('')}
          style={{ border: 'none', background: 'none', padding: 0 }}
        >
          <img src={Logo} alt="Swiggy logo" />
        </button>

        <RenderHelpButton />
      </div>

      <div className="flex flex-col w-full gap-10">
        {pendingOrdersCount > 1 && (
          <p className="bg-black text-white flex items-center  rounded-md px-[14px] md:px-5 text-sm md:text-lg h-16 font-thin no-underline border-none hover:no-underline">
            <span className="font-bold text-orange-500 ">
              {pendingOrdersCount} pending orders ! &nbsp;
            </span>
            For simplicity in demo displaying only the latest order.
          </p>
        )}
        <OrderTrackerMap />
        <OrderTrackerDeliveryPartner />
        <OrderTrackerDishes dishes={dishes} />
      </div>
    </div>
  );
}

function RenderHelpButton() {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const emptyCartTextStyle = 'text-base text-gray-500 font-thin';

  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  }

  const handleMouseEnterOnTrigger = () => {
    setIsOpen(true);
  };

  const handleMouseLeaveOnTrigger = (e: React.MouseEvent) => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();

      if (
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.bottom
      ) {
        return;
      }
    }
    setIsOpen(false);
  };

  const mouseLeaveOnContent = () => {
    setIsOpen(false);
  };

  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip open={isOpen}>
        <TooltipTrigger
          ref={triggerRef}
          className="text-sm hover:text-orangeHover underline-transition md:text-base"
          onClick={handleClick}
          onMouseEnter={handleMouseEnterOnTrigger}
          onMouseLeave={handleMouseLeaveOnTrigger}
        >
          Help
        </TooltipTrigger>
        <TooltipContent
          className="px-12 pt-8 pb-10 mt-8 mr-4 bg-white border-t-2 rounded-lg shadow-2xl w-max border-orangeHover"
          onMouseLeave={mouseLeaveOnContent}
        >
          <h1 className="mb-6 text-4xl font-semibold text-gray-500">
            Not Available
          </h1>
          <p className={emptyCartTextStyle}>
            Help not included in Demo Project !
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default OrderTrackerPage;
