import { useCheckout } from '@/context/CheckoutContext';
import { useShoppingCart } from '@/context/ShoppingCartContext';
import { useUser } from '@/context/UserContext';

import { Separator } from '@radix-ui/react-separator';

import { Circle, Dot, IndianRupeeIcon } from 'lucide-react';
import { useState } from 'react';
import AnimateWhenDisabledButton from '../AnimateWhenDisabledButton';
import CartEmptyUI from './CartEmptyUI';

const offsetTextColor = 'text-gray-500';
const separatorColor = 'bg-gray-500';

function ItemsCountAndTotal(props: { itemsCount: number; total: string }) {
  const { itemsCount, total } = props;
  return (
    <div
      className={`flex items-center gap-0 text-sm font-light tracking-tighter ${offsetTextColor}`}
    >
      <p>
        {itemsCount} {itemsCount === 1 ? 'item' : 'items'}
      </p>
      <Dot className={`scale-125 ${offsetTextColor}`} size={14} />

      <div className="flex items-center ">
        <div className="flex">
          <p>Total</p>
          <div className="flex flex-col items-center justify-center  ml-[1px] mr-0.5 ">
            <Dot size={5} className="scale-[2.23] text-gray-500/80 " />
            <Dot size={5} className="scale-[2.23] text-gray-500/80 " />
          </div>
        </div>

        <IndianRupeeIcon size={12} strokeWidth={2.5} />
        <p>{total}</p>
      </div>
    </div>
  );
}

function RestaurantAndDeliveryAddress(props: { restaurantName: string }) {
  const { restaurantName } = props;

  const { getLocationAddress } = useCheckout();

  const locationAddress = getLocationAddress && getLocationAddress();

  if (!locationAddress) return null;
  const { city, label, state, street, flatNumber, pincode, country } =
    locationAddress.address;
  const { time, timeUnit } = locationAddress;
  const dumbellColor = 'rgb(94,70,220)';

  return (
    <div className="flex items-center w-full h-20 gap-3 ">
      <div className="flex flex-col items-center justify-center gap-0 h-max">
        <Circle
          size={12}
          strokeWidth={4}
          color={dumbellColor}
          className="z-20"
        />

        <div className="w-[2.4px] h-[22px] bg-[rgb(94,70,220)] rounded-none -mt-[2px] -mb-[2px] "></div>
        <Circle
          size={12}
          strokeWidth={4}
          color={dumbellColor}
          className="z-20"
        />
      </div>
      <div className="flex flex-col w-full font-light gap-3">
        <div className="flex items-center h-5 gap-2">
          <p>{restaurantName}</p>
          <Separator
            orientation="vertical"
            className={`w-[1px] h-[14px] ${separatorColor}`}
          />
          <p className={`${offsetTextColor}`}>
            Delivery in : {time}
            {'  '}
            {timeUnit}
          </p>
        </div>

        <div className="flex items-center w-full h-5 max-w-[600px] gap-2">
          <p className=" whitespace-nowrap w-max">{label} </p>

          <Separator
            orientation="vertical"
            className={`w-[1px] h-[14px] ${separatorColor}`}
          />
          <p className={`  line-clamp-1  ${offsetTextColor}`}>
            {flatNumber}, {street}, {city},{state} {pincode}{' '}
            {country ? ',' : ''} {country}
          </p>
        </div>
      </div>
    </div>
  );
}

function PaymentDetails(props: { paymentButtonClickHandler: () => void }) {
  const { paymentButtonClickHandler } = props;
  const { isLoggedIn } = useUser();
  const {
    isCartEmpty,
    getTotalItemsCount,
    getCartTotal,
    getCartRestaurantDetails,
  } = useShoppingCart();
  const { getDeliveryChargesTotal } = useCheckout();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disableLabel, setDisableLabel] =
    useState<string>('Processing Payment');

  if (!isLoggedIn) return null;
  if (isCartEmpty()) return <CartEmptyUI />;

  return (
    <div>
      {/* wrap inside div otherwise not rendered at center horizontally*/}
      <div className="w-full py-8 lg:px-3">
        <ItemsCountAndTotal
          itemsCount={getTotalItemsCount()}
          total={(getCartTotal() + getDeliveryChargesTotal()).toFixed(2)}
        />
        <hr className="mt-2 mb-4" />
        <RestaurantAndDeliveryAddress
          restaurantName={getCartRestaurantDetails().name}
        />

        <div className="mt-8">
          <AnimateWhenDisabledButton
            label={<RenderEnabledPayButton />}
            className="text-base font-normal tracking-wider text-gray-600 duration-300 w-fit"
            disabled={disabled}
            disableLabel={disableLabel}
            disableClassName="px-20 py-3 bg-slate-50/80 drop-shadow-2xl"
            disableLabelClassName="pl-2 pr-4"
            clickHandler={() => {
              paymentButtonClickHandler();
              setDisabled(true);
              setTimeout(() => {
                setDisableLabel('This page will be redirected automatically');
              }, 3000);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function RenderEnabledPayButton() {
  const { getCartTotal } = useShoppingCart();
  const { getLocationAddress, getDeliveryChargesTotal } = useCheckout();
  return (
    <div className="flex items-center justify-center w-full px-20 py-3 text-sm font-semibold text-white uppercase cursor-pointer gap-3 max-w-64 hover:shadow-md bg-buttonGreen">
      <p className="font-normal uppercase">pay</p>
      <div className="flex items-center font-bold">
        <IndianRupeeIcon size={14} strokeWidth={3} />

        {(
          getCartTotal() +
          (getLocationAddress() ? getDeliveryChargesTotal() : 0)
        ).toFixed(2)}
      </div>
    </div>
  );
}

export default PaymentDetails;
