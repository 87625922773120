// const AUTH_LOGIN_ROUTE = `
//   ${process.env.BACKEND_HOST_URL}/auth/login`;
const AUTH_LOGIN_ROUTE = `${import.meta.env.VITE_BACKEND_HOST_URL}/auth/login`;

const AUTH_LOGOUT_ROUTE = `
  ${import.meta.env.VITE_BACKEND_HOST_URL}/auth/logout`;

const AUTH_SIGNUP_ROUTE = `
  ${import.meta.env.VITE_BACKEND_HOST_URL}/auth/signup`;

const USER_ADD_NEW_ADDRESS_ROUTE = `${import.meta.env.VITE_BACKEND_HOST_URL}/user/addAddress`;
const USER_GET_CONTACT_AND_ADDRESS_ROUTE = `${import.meta.env.VITE_BACKEND_HOST_URL}/user/getContactAndAddress`;

const USER_TOKEN_COOKIE_LABEL = 'UserToken';
const USER_NAME_COOKIE_LABEL = 'username';

export {
  AUTH_LOGIN_ROUTE,
  AUTH_LOGOUT_ROUTE,
  AUTH_SIGNUP_ROUTE,
  USER_ADD_NEW_ADDRESS_ROUTE,
  USER_GET_CONTACT_AND_ADDRESS_ROUTE,
  USER_TOKEN_COOKIE_LABEL,
  USER_NAME_COOKIE_LABEL,
};
