import React, { useRef } from 'react';

const CustomScrollbar = ({ scrollPosition }) => {
  const containerRef = useRef(null);
  const scrollBarRef = useRef(null);

  if (containerRef.current && scrollBarRef.current) {
    scrollPosition *=
      containerRef.current.clientWidth - scrollBarRef.current.clientWidth;
  }

  return (
    <div
      ref={containerRef}
      className="w-[72px] h-[7px] mx-auto overflow-auto bg-[rgb(218,219,221)] rounded-xl"
    >
      <div
        ref={scrollBarRef}
        className="w-2.5 h-full bg-[rgb(224,97,41)] rounded-md "
        style={{ transform: `translateX(${scrollPosition}px)` }}
      ></div>
    </div>
  );
};

export default CustomScrollbar;
