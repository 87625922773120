import React, { useRef, useState } from 'react';
import CategoryDishes from './CategoryDishes';
import { generateKey } from '../utils/keygen';
import CategoryHeader from './CategoryHeader';

const ItemCategory = ({ restaurantId, card, infoCard, hideBottomDivider }) => {
  const [visibility, setVisibility] = useState(true);
  const { itemCards } = card;
  const ref = useRef(null);
  if (ref.current) {
    if (visibility) ref.current.classList.remove('hidden');
    else ref.current.classList.add('hidden');
  }

  function toggleVisibility() {
    setVisibility(!visibility);
  }
  return (
    <div className="mt-16">
      <CategoryHeader
        title={card.title}
        itemsCount={itemCards.length}
        visibility={visibility}
        toggleVisibility={toggleVisibility}
      />
      <div ref={ref}>
        <CategoryDishes
          restaurantId={restaurantId}
          dishes={itemCards}
          visibility={visibility}
          infoCard={infoCard}
        />
      </div>
      <CategoryDivider hidden={hideBottomDivider} />
    </div>
  );
};

const CategoryDivider = ({ hidden }) => {
  if (hidden) return null;
  return <div className="w-full h-5 my-4 bg-slate-300"></div>;
};

const RestaurantMenuItemCategoryCards = ({ restaurantId, cards, infoCard }) => {
  if (!cards) return;
  const titles = cards.map((c, index) => (
    <ItemCategory
      key={generateKey()}
      restaurantId={restaurantId}
      card={c}
      infoCard={infoCard}
      hideBottomDivider={index >= cards.length - 1}
    />
  ));
  return <div>{titles}</div>;
};

export default RestaurantMenuItemCategoryCards;
