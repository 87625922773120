import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';

function PaymentfailPage() {
  return (
    <div className="w-full h-[100vh] flex flex-col gap-10 justify-center items-center">
      <p className="tracking-widest text-slate-500 text-lg font-bold">
        {' '}
        Payment Failed!!!
      </p>
      <button
        onClick={() => navigateToUrlPostOrigin('/checkout')}
        className="flex items-center justify-center w-fit px-16 py-3 text-sm font-semibold text-white uppercase cursor-pointer gap-3 max-w-64 hover:shadow-md bg-buttonGreen"
      >
        Retry
      </button>
    </div>
  );
}

export default PaymentfailPage;
