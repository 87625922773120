function Hr(props: { showBottomDivider: boolean }) {
  const { showBottomDivider } = props;
  if (!showBottomDivider) return null;
  return <hr className="w-full h-2 bg-slate-50 "></hr>;
}

function Rating(props: { rating: number; ratingCount: string }) {
  const { rating, ratingCount } = props;
  if (!rating || !ratingCount)
    return <div className="w-20 h-16 bg-slate-300 rounded-md"></div>;
  return (
    <div className="flex flex-col w-max px-4 py-2 border border-[rgb(53,55,58)] rounded-md">
      <div className="flex gap-1 justify-center mb-2 text-[18px]">
        <span className="material-symbols-rounded text-[rgb(122,181,147)] ">
          star
        </span>

        <p className="mb-2  text-[rgb(122,181,147)] font-extrabold">{rating}</p>
      </div>
      <Hr />
      <p className="text-[10px] font-thin text-nowrap mt-4">{ratingCount}</p>
    </div>
  );
}

const RestaurantNameComponent = ({
  restaurantName,
  cuisines,
  areaName,
  distance,
}) => {
  if (!restaurantName)
    return <div className="h-20 w-28 bg-slate-300 rounded-md"></div>;
  return (
    <div>
      <p>{restaurantName}</p>
      <p className="text-xs font-thin">
        {Array.isArray(cuisines) ? cuisines.join(', ') : ''}
      </p>
      <div className="flex items-center gap-1 bg-slate-200">
        <p className="text-xs font-thin">{areaName}</p>
        <p className="text-xs font-thin">{distance ? `, ${distance}` : ''}</p>
        <span className="material-symbols-rounded text-[rgb(219,133,64)] -ml-1">
          arrow_drop_down
        </span>
      </div>
    </div>
  );
};

const RestaurantMenuCardTypeV2RestaurantInfo = ({ card }) => {
  if (!card || card.length === 0)
    return (
      <div className="flex justify-between w-full ">
        <div className="h-20 w-28 bg-slate-300 rounded-md"></div>
        <div className="w-20 h-20 bg-slate-300 rounded-md"></div>
      </div>
    );
  const {
    id,
    name,
    city,
    locality,
    feeDetails,
    slugs,
    cuisines,
    areaName,
    costForTwo,
    costForTwoMessage,
    avgRatingString,
    totalRatingsString,
    sla,
    availability,
    labels,
    aggregatedDiscountInfoV2,
  } = card[0].info;
  return (
    <div className="flex justify-between">
      <RestaurantNameComponent
        restaurantName={name}
        cuisines={cuisines}
        distance={sla.lastMileTravelString}
        areaName={areaName}
      />
      <Rating rating={avgRatingString} ratingCount={totalRatingsString} />
    </div>
  );
};

export default RestaurantMenuCardTypeV2RestaurantInfo;
