import { useRef, useState } from 'react';
import CloseIcon from './CloseIcon';
import { LOGIN_IMAGE_ROUTE } from '../../constants';
import InputLabel from './InputLabel';

import AnimateWhenDisabledButton from './AnimateWhenDisabledButton';

import { postLogin, postSignup } from '@/api/authApi';
import { User } from '@/types/user';
import { useUser } from '@/context/UserContext';
import { useHeaderVisibility } from '@/context/HeaderVisibilityContext';

function HeadingSection(
  props: Readonly<{
    label: string;
    subLabel: string;
    subLabelClickHandler: () => void;
  }>,
) {
  const { label, subLabel, subLabelClickHandler } = props;
  return (
    <div className="flex flex-col gap-3">
      <p className="text-3xl font-medium">{label}</p>
      {subLabel.length > 0 && (
        <p className="text-[13px]">
          or{' '}
          <button
            className="ml-1 text-orange-500"
            onClick={() => subLabelClickHandler()}
          >
            {`${subLabel}`}
          </button>
        </p>
      )}
      <hr className="my-4 border-gray-900 w-9 "></hr>
    </div>
  );
}

function SignupSuccessView(
  props: Readonly<{
    onCloseButtonClick: () => void;
    switchToLoginView: () => void;
  }>,
) {
  const { onCloseButtonClick, switchToLoginView } = props;

  return (
    <>
      <CloseIcon
        closeButtonClick={onCloseButtonClick}
        fontSize={'32px'}
        weight={200}
      />
      <div className="flex flex-col items-center justify-center w-full h-full gap-8 mt-8 ">
        <div className="flex justify-between w-full mb-8 h-max">
          <HeadingSection
            label={'Signup successful'}
            subLabel={''}
            subLabelClickHandler={switchToLoginView}
          />
          <img src={LOGIN_IMAGE_ROUTE} className="size-24"></img>
        </div>
        <AnimateWhenDisabledButton
          disabled={false}
          label="Login now"
          disableLabel=""
          className={`w-full py-3 mt-2 text-white bg-green-600 
        hover:bg-green-600 hover:shadow-md
        'cursor-pointer' }
        `}
          disableClassName="w-full py-3 mt-2"
          clickHandler={() => switchToLoginView()}
        />
      </div>
    </>
  );
}

function SignupView(
  props: Readonly<{
    onCloseButtonClick: () => void;
    switchToLoginView: () => void;
  }>,
) {
  const { onCloseButtonClick, switchToLoginView } = props;

  const nameFieldRef = useRef<HTMLInputElement>(null);
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const [isSignupButtonActive, setIsSignupButtonActive] =
    useState<boolean>(true);
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string>('');

  const [isSignupSuccess, setIsSignupSuccess] = useState<boolean>(false);

  const { onLogin } = useUser();
  const { setHeaderVisible } = useHeaderVisibility();
  async function handleSignupClick() {
    setValidationErrorMessage('');
    setIsValidationError(false);

    postSignup(
      nameFieldRef.current?.value,
      emailFieldRef.current?.value,
      passwordFieldRef.current?.value,
      (data) => {
        console.log(data);
        setIsSignupSuccess(true);
        setHeaderVisible();
      },
      (err: string) => {
        console.error(err);

        setValidationErrorMessage(err);
        setIsSignupButtonActive(true);
        setIsValidationError(true);
        onLogin({ name: '', isAdmin: false, isLoggedIn: false });
        setIsSignupSuccess(false);
      },
    );
  }

  if (isSignupSuccess)
    return (
      <SignupSuccessView
        onCloseButtonClick={onCloseButtonClick}
        switchToLoginView={switchToLoginView}
      />
    );

  return (
    <div className="relative">
      <div className="flex justify-end w-full h-full">
        <div className="flex flex-col items-end h-full bg-white w-fit ">
          <CloseIcon
            closeButtonClick={onCloseButtonClick}
            fontSize={'32px'}
            weight={200}
          />

          <div className="flex justify-between w-full mb-8 h-max">
            <HeadingSection
              label={'Signup'}
              subLabel={'login to your account'}
              subLabelClickHandler={switchToLoginView}
            />
            <img src={LOGIN_IMAGE_ROUTE} className="size-24"></img>
          </div>

          {isValidationError && (
            <div className="flex items-center justify-start w-full mb-4 text-xs text-validationError">
              {validationErrorMessage}
            </div>
          )}

          <InputLabel
            ref={nameFieldRef}
            inputType="text"
            label={'Name'}
            containerClassName="w-full  px-4 py-2 border border-gray-200 "
            labelClassName={` text-zinc-400  min-h-6 mb-2`}
            inputClassName="text-gray-600"
            placeHolder=""
            focussedByDefault={true}
          />
          <InputLabel
            ref={emailFieldRef}
            inputType="text"
            label={'Email'}
            containerClassName="w-full  px-4 py-2 border border-gray-200 "
            labelClassName={` text-zinc-400  min-h-6 mb-2`}
            inputClassName="text-gray-600"
            placeHolder=""
            focussedByDefault={false}
          />
          <InputLabel
            ref={passwordFieldRef}
            inputType="password"
            label={'Password'}
            containerClassName="w-full px-4 py-2 border border-gray-200 "
            labelClassName={` text-zinc-400  min-h-6 mb-2`}
            inputClassName="text-gray-600"
            placeHolder=""
            focussedByDefault={false}
          />

          <AnimateWhenDisabledButton
            disabled={!isSignupButtonActive}
            label="Continue"
            disableLabel="Signing up..."
            className={`w-full py-3 mt-2 text-white bg-green-600 
                hover:bg-green-600 hover:shadow-md
                 ${isSignupButtonActive ? 'cursor-pointer' : 'cursor-not-allowed'}
                `}
            disableClassName="w-full py-3 mt-2"
            clickHandler={() => {
              setIsSignupButtonActive(false);
              handleSignupClick();
            }}
          />
          <p className="mt-2 text-xs outline-none">
            By creating an account, I accept the Terms & Conditions & Privacy
            Policy
          </p>
        </div>
      </div>
    </div>
  );
}

function LoginView(
  props: Readonly<{
    onCloseButtonClick: () => void;
    switchToSignupView: () => void;
  }>,
) {
  const { onCloseButtonClick, switchToSignupView } = props;

  const emailFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const [isLoginButtonActive, setIsLoginButtonActive] = useState<boolean>(true);
  const [isValidationError, setIsValidationError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  const { onLogin } = useUser();
  const { setHeaderVisible } = useHeaderVisibility();
  function handleLoginClick() {
    postLogin(
      emailFieldRef.current?.value,
      passwordFieldRef.current?.value,
      (user: User) => {
        setIsLoginButtonActive(true);
        setIsValidationError(false);
        setValidationErrorMessage('');
        onLogin(user);
        setHeaderVisible();
      },
      (err: string) => {
        setValidationErrorMessage(err);
        setIsLoginButtonActive(true);
        setIsValidationError(true);
        onLogin({ name: '', isAdmin: false, isLoggedIn: false });
      },
    );
  }

  function handleLoginDemoAccountClick() {
    postLogin(
      'b',
      'b',
      (user: User) => {
        setIsLoginButtonActive(true);
        setIsValidationError(false);
        setValidationErrorMessage('');
        onLogin(user);
        setHeaderVisible();
      },
      (err: string) => {
        setValidationErrorMessage(err);
        setIsLoginButtonActive(true);
        setIsValidationError(true);
        onLogin({ name: '', isAdmin: false, isLoggedIn: false });
      },
    );
  }

  return (
    <div className="relative">
      <div className="flex justify-end w-full h-full">
        <div className="flex flex-col items-end h-full bg-white w-fit">
          <CloseIcon
            closeButtonClick={onCloseButtonClick}
            fontSize={'32px'}
            weight={200}
          />

          <div className="flex justify-between w-full mb-8 h-max">
            <HeadingSection
              label={'Login'}
              subLabel={'Create an account'}
              subLabelClickHandler={switchToSignupView}
            />
            <img src={LOGIN_IMAGE_ROUTE} className="size-24"></img>
          </div>

          {isValidationError && (
            <div className="flex items-center justify-start w-full mb-4 text-xs text-validationError">
              {validationErrorMessage}
            </div>
          )}

          <InputLabel
            ref={emailFieldRef}
            inputType="text"
            label={'Email'}
            containerClassName="w-full  px-4 py-2 border border-gray-200 "
            labelClassName={` text-zinc-400  min-h-6 mb-2`}
            inputClassName="text-gray-600"
            placeHolder=""
            focussedByDefault={true}
          />
          <InputLabel
            ref={passwordFieldRef}
            inputType="password"
            label={'Password'}
            containerClassName="w-full px-4 py-2 border border-gray-200 "
            labelClassName={` text-zinc-400  min-h-6 mb-2`}
            inputClassName="text-gray-600"
            placeHolder=""
            focussedByDefault={false}
          />

          <AnimateWhenDisabledButton
            disabled={!isLoginButtonActive}
            label="Login"
            disableLabel="Logging in..."
            className={`w-full pl-3 py-3 mt-2 text-white bg-green-600 
                hover:bg-green-600 hover:shadow-md
                 ${isLoginButtonActive ? 'cursor-pointer' : 'cursor-not-allowed'}
                `}
            disableClassName="w-full py-3 mt-2"
            clickHandler={() => {
              setIsLoginButtonActive(false);
              handleLoginClick();
            }}
          />

          <div className="w-full mt-8">
            <p className="mt-2 text-xs font-medium outline-none text-slate-700">
              Don't want to share your email?
            </p>
            <AnimateWhenDisabledButton
              disabled={!isLoginButtonActive}
              label="Login using Demo Account"
              disableLabel="Logging in..."
              className={`w-full pl-3 py-3 mt-2 text-white bg-orangeHover 
                 hover:shadow-lg shadow-inner
                 ${isLoginButtonActive ? 'cursor-pointer' : 'cursor-not-allowed'}
                `}
              disableClassName="w-full py-3 mt-2"
              clickHandler={() => {
                setIsLoginButtonActive(false);
                handleLoginDemoAccountClick();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Login(
  props: Readonly<{ visible: boolean; onCloseButtonClick: () => void }>,
) {
  const { visible, onCloseButtonClick } = props;
  const [viewingLogin, setViewingLogin] = useState(true);

  if (!visible) return null;
  document.body.style.overflow = 'hidden';
  return (
    <div className="relative ">
      <div className="flex justify-end w-full h-full">
        <div className="flex flex-col items-start w-full h-full overflow-x-auto bg-white whitespace-nowrap ">
          {viewingLogin && (
            <LoginView
              onCloseButtonClick={onCloseButtonClick}
              switchToSignupView={() => setViewingLogin(false)}
            />
          )}

          {!viewingLogin && (
            <SignupView
              onCloseButtonClick={onCloseButtonClick}
              switchToLoginView={() => setViewingLogin(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
