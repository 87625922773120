import { useEffect, useState } from 'react';
import Header from './reactcomponents/Header';

import { ROUTE_RESTAURANT_MENU } from '../constants';

import Dashboard from './reactcomponents/dashboard/Dashboard';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from 'react-router-dom';

import ErrorPage from './reactcomponents/ErrorPage';

import RestaurantMenu from './reactcomponents/RestaurantMenu';
import Categories from './reactcomponents/Categories';
import RestaurantChains from './reactcomponents/RestaurantChains';

import { ShoppingCartProvider } from './context/ShoppingCartContext';
import Checkout from './reactcomponents/checkout/Checkout';

import { UserProvider } from './context/UserContext';

import { CheckoutProvider } from './context/CheckoutContext';
import CustomPaymentCheckout from './reactcomponents/payment/CustomPaymentCheckout';
import InfiniteScrollRestaurants from './reactcomponents/InfiniteScrollRestaurants';
import { InfiniteScrollProvider } from './context/InfiniteScrollContext';
import axios from 'axios';
import { RESTAURANTS_LIST_URL } from './api/endpoints/restaurantapiendpoints';
import OrderTrackerPage from './reactcomponents/payment/OrderTrackerPage';
import PaymentFailPage from './reactcomponents/payment/PaymentFailPage';
import { HeaderVisibilityContextProvider } from './context/HeaderVisibilityContext';
import { Analytics } from '@vercel/analytics/react';

function RootComponent() {
  const RENDER_HOME_PAGE_CATEGORIES = false;
  const [categoriesTitle, setCategoriesTitle] = useState('');
  const [categories, setCategories] = useState([]);
  const [topRestaurantsTitle, setTopRestaurantsTitle] = useState('');
  const [topRestaurants, setTopRestaurants] = useState([]);

  useEffect(() => {
    fetchData();

    async function fetchData() {
      // const f = await fetch(FETCH_URL);

      const req = await axios.get(RESTAURANTS_LIST_URL);
      const data = req.data;

      setCategoriesTitle(data?.data?.cards[0]?.card?.card?.header?.title);
      setCategories(
        data?.data?.cards[0]?.card?.card?.gridElements?.infoWithStyle?.info,
      );

      setTopRestaurantsTitle(data?.data?.cards[1]?.card?.card?.header?.title);

      setTopRestaurants(
        data?.data?.cards[1]?.card?.card?.gridElements?.infoWithStyle
          ?.restaurants,
      );
    }
  }, []);

  return (
    <div className="px-[5vw] xl:px-[8vw] my-6">
      {RENDER_HOME_PAGE_CATEGORIES && (
        <Categories
          categoriesDataTitle={categoriesTitle}
          categoriesData={categories}
        />
      )}

      <RestaurantChains
        restaurantChainsTitle={topRestaurantsTitle}
        restaurantChains={topRestaurants}
      />
      <InfiniteScrollProvider>
        <InfiniteScrollRestaurants />
      </InfiniteScrollProvider>
    </div>
  );
}

function Body() {
  const location = useLocation();
  const path = location.pathname;

  switch (path) {
    case '/swiggydemo':
      return (
        <div className="bg-zinc-100 font-figtree">
          <p> swiggy demo</p>
        </div>
      );

    case '/dashboard':
      return (
        <UserProvider>
          <Dashboard />
        </UserProvider>
      );

    case '/checkout':
      return (
        <UserProvider>
          <ShoppingCartProvider>
            <div className="bg-zinc-100 font-figtree">
              <CheckoutProvider>
                <Checkout />
              </CheckoutProvider>
            </div>
          </ShoppingCartProvider>
        </UserProvider>
      );

    case '/payment':
      return (
        <UserProvider>
          <ShoppingCartProvider>
            <CheckoutProvider>
              <div className="bg-zinc-100 font-figtree">
                <CustomPaymentCheckout />
              </div>
            </CheckoutProvider>
          </ShoppingCartProvider>
        </UserProvider>
      );

    case '/order-tracker':
      return (
        <UserProvider>
          <ShoppingCartProvider>
            <CheckoutProvider>
              <div className="bg-zinc-100 font-figtree">
                <OrderTrackerPage />
              </div>
            </CheckoutProvider>
          </ShoppingCartProvider>
        </UserProvider>
      );

    case '/payment-failure':
      return (
        <UserProvider>
          <ShoppingCartProvider>
            <CheckoutProvider>
              <div className="bg-zinc-100 font-figtree">
                <PaymentFailPage />
              </div>
            </CheckoutProvider>
          </ShoppingCartProvider>
        </UserProvider>
      );

    default:
      return (
        <UserProvider>
          <ShoppingCartProvider>
            <div className="bg-zinc-100 font-figtree">
              <HeaderVisibilityContextProvider>
                <Header />
              </HeaderVisibilityContextProvider>
              <Outlet />
              {/* <Dashboard /> */}
            </div>
          </ShoppingCartProvider>
        </UserProvider>
      );
  }
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Body />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <RootComponent />,
      },
      {
        path: `${ROUTE_RESTAURANT_MENU}:resId`,
        element: <RestaurantMenu />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: 'payment',
        // element not set as we render element based on routePath
      },
      {
        path: 'order-tracker',
        // element not set as we render element based on routePath
      },
      {
        path: 'payment-failure',
        // element not set as we render element based on routePath
      },
      {
        path: 'dashboard',
        // element not set as we render element based on routePath
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />;
      <Analytics />
    </>
  );
}

export default App;
