import { fetchDeliveryCharge } from '@/api/deliveryChargeFetcher';
import { useCheckout } from '@/context/CheckoutContext';

import { useUser } from '@/context/UserContext';
import { Address, AddressWithDeliveryTime } from '@/types/user';
import { generateKey } from '@/utils/keygen';
import { CircleCheckBig, MapPinIcon } from 'lucide-react';

const addressIconStrokeWidth = '0.8px';
const addressIconColor = '#000';
const addressIconSize = 24;
const addressIconMinWidthClassName = 'min-w-8';
const cardWidthClass = 'w-80';

function NoSavedAddress(props: {
  address: Address | undefined;
  cardWidthClass: string;
}) {
  const { address, cardWidthClass } = props;
  return (
    <div>
      <p>No saved address in this location</p>
      <AddNewAddressCard cardWidthClass={cardWidthClass} address={address} />
    </div>
  );
}

function AddressCard(props: {
  addressObject: AddressWithDeliveryTime;
  cardWidthClass: string;
}) {
  const { setLocationAddress, updateDeliveryCharges, setIsCartUpdating } =
    useCheckout();

  const { addressObject, cardWidthClass } = props;

  const { address, time, timeUnit } = addressObject;
  const { label, flatNumber, city, state, pincode, street, country } = address;

  return (
    <div
      className={`flex gap-2 px-6 py-10 border border-cardBorderGray cursor-pointer hover:shadow-lg ${cardWidthClass}`}
      onClick={() => {
        setIsCartUpdating(true);

        fetchDeliveryCharge(
          addressObject.address,
          {} as Address,
          (data) => {
            updateDeliveryCharges(data);
            setLocationAddress(addressObject);
            setIsCartUpdating(false);
          },
          (error) => {
            console.error(error);
            setIsCartUpdating(false);
          },
        );
      }}
    >
      <MapPinIcon
        className={`${addressIconMinWidthClassName}`}
        strokeWidth={addressIconStrokeWidth}
        color={addressIconColor}
        size={addressIconSize}
      />
      <div className="overflow-hidden">
        <p className="font-semibold">{label ? label : 'Other'}</p>
        <p className="mt-2 mb-4 text-sm text-slate-400">
          {flatNumber} {city} {state} {street} {country} {pincode}{' '}
        </p>
        <p className="text-xs font-medium uppercase">
          {time} {'  '} {timeUnit}
        </p>
        <button className="w-full px-4 py-2 mt-8 mb-2 text-xs font-bold tracking-wide uppercase max-w-36 text-slate-50 bg-buttonGreen whitespace-nowrap ">
          Deliver here
        </button>
      </div>
    </div>
  );
}

function AddNewAddressCard(props: {
  address: Address | undefined;
  cardWidthClass: string;
}) {
  const { address, cardWidthClass } = props;

  if (!address)
    return (
      <div
        className={`flex gap-2 px-6  py-10 border border-cardBorderGray2 border-dotted  cursor-pointer hover:shadow-lg ${cardWidthClass}`}
      >
        <MapPinIcon
          className={`${addressIconMinWidthClassName}`}
          strokeWidth={addressIconStrokeWidth}
          color={addressIconColor}
          size={addressIconSize}
        />

        <div className="overflow-hidden ">
          <p className="font-semibold truncate line-clamp-1">Add New Address</p>
          <button className="w-full px-4 py-2 mt-8 mb-2 text-xs font-bold tracking-wide uppercase border max-w-36 text-buttonGreen border-buttonGreen whitespace-nowrap ">
            Add new
          </button>
        </div>
      </div>
    );

  const { label, flatNumber, city, street, state, pincode } = address;
  return (
    <div
      className={`flex gap-2 px-6  py-10 border border-cardBorderGray2 border-dotted cursor-pointer hover:shadow-lg ${cardWidthClass}`}
    >
      <MapPinIcon
        className={`${addressIconMinWidthClassName}`}
        strokeWidth={addressIconStrokeWidth}
        color={addressIconColor}
        size={addressIconSize}
      />
      <div className="overflow-hidden ">
        <p className="font-semibold truncate line-clamp-1">{label}</p>
        <p className="mt-2 mb-4 text-sm truncate text-slate-400 line-clamp-3">
          {'  '} {flatNumber} {city} {state} {street} {pincode}{' '}
        </p>

        <button className="w-full px-4 py-2 mt-8 mb-2 text-xs font-bold tracking-wide uppercase border max-w-36 text-buttonGreen border-buttonGreen whitespace-nowrap ">
          Add new
        </button>
      </div>
    </div>
  );
}

function SelectedCheckoutAddress(props: {
  addressWithDeliveryTime: AddressWithDeliveryTime;
}) {
  const { addressWithDeliveryTime } = props;
  const { address, time, timeUnit } = addressWithDeliveryTime;
  const { label, flatNumber, city, state, pincode, street } = address;

  return (
    <div className={`flex flex-col gap-2 w-full ${cardWidthClass} `}>
      <div className="flex justify-between gap-0">
        <div className="flex gap-8">
          <p className="font-semibold">Delivery Address</p>
          <CircleCheckBig color="#090" size={18} />
        </div>

        <span className="text-sm font-semibold uppercase text-orangeHover">
          Change
        </span>
      </div>
      <div className="overflow-hidden">
        <p className="font-semibold">{label ? label : 'Other'}</p>
        <p className="mt-2 text-sm text-slate-400 max-w-[500px] lg:max-w-[700px] truncate">
          {'  '} {flatNumber}, {street}
        </p>
        <p className="mt-2 text-sm text-slate-400 max-w-80 lg:max-w-[700px] truncate">
          {'  '} {city}, {state}
        </p>
        <p className="mt-1 mb-4 text-sm text-slate-400">{pincode}</p>
        <p className="text-xs font-medium uppercase">
          {time} {'  '} {timeUnit}
        </p>
      </div>
    </div>
  );
}

function CheckoutAddress() {
  const { isLoggedIn } = useUser();
  const { getAddress, getLocationAddress } = useCheckout();
  const savedAddresses = getAddress();

  if (!isLoggedIn()) {
    return (
      <div className="relative w-full">
        <p className="font-bold text-gray-500/80">Delivery Address </p>
      </div>
    );
  }
  if (null != getLocationAddress() && getLocationAddress()?.address) {
    return (
      <SelectedCheckoutAddress
        addressWithDeliveryTime={getLocationAddress()!}
      />
    );
  }

  if (!savedAddresses) {
    return (
      <NoSavedAddress
        cardWidthClass={cardWidthClass}
        address={getLocationAddress()?.address}
      />
    );
  }

  return (
    <div className="relative w-full">
      <p className="mb-4 font-bold ">
        {savedAddresses.length === 0
          ? 'Add Delivery Address'
          : 'Choose a Delivery Address'}
      </p>

      <div className="grid grid-cols-2 gap-4">
        {savedAddresses.map((address) => (
          <AddressCard
            key={generateKey()}
            addressObject={address}
            cardWidthClass={cardWidthClass}
          />
        ))}

        <AddNewAddressCard
          cardWidthClass={cardWidthClass}
          address={getLocationAddress()?.address}
        />
      </div>
    </div>
  );
}

export default CheckoutAddress;
