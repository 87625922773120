import { gsap } from 'gsap';

export function gsapTransXAlpha(
  elem,
  startX,
  finishX,
  startAlpha,
  finishAlpha,
  duration,
  onCompleteListener?,
  ease?,
) {
  gsap.fromTo(
    elem,
    { x: startX, opacity: startAlpha },
    {
      x: finishX,
      opacity: finishAlpha,
      duration: duration,
      onComplete: onCompleteListener,
      ease: ease || 'power1.inOut',
    },
  );
}

export function gsapTransYAlpha(
  elem,
  startY,
  finishY,
  startAlpha,
  finishAlpha,
  duration,
  onCompleteListener?,
  ease?,
) {
  gsap.fromTo(
    elem,
    { y: startY, opacity: startAlpha },
    {
      y: finishY,
      opacity: finishAlpha,
      duration: duration,
      onComplete: onCompleteListener,
      ease: ease || 'power1.inOut',
    },
  );
}

export function gsapTransXAlpha_delay(
  elem,
  startX,
  finishX,
  startAlpha,
  finishAlpha,
  duration,
  delay,
  onCompleteListener?,
) {
  gsap.fromTo(
    elem,
    { x: startX, opacity: startAlpha },
    {
      x: finishX,
      opacity: finishAlpha,
      duration: duration,
      delay: delay,
      onComplete: onCompleteListener,
      ease: 'power1.inOut',
    },
  );
}

export function gsapTransYAlpha_delay(
  elem,
  startY,
  finishY,
  startAlpha,
  finishAlpha,
  duration,
  delay,
  onCompleteListener?,
) {
  gsap.fromTo(
    elem,
    { y: startY, opacity: startAlpha },
    {
      y: finishY,
      opacity: finishAlpha,
      duration: duration,
      delay: delay,
      onComplete: onCompleteListener,
      ease: 'power1.inOut',
    },
  );
}
