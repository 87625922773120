import { useEffect, useState } from 'react';

import { DeliveryPartner } from '@/types/orderTrackTypes';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { ProgressBar } from 'react-loader-spinner';
import { User } from 'lucide-react';

function OrderTrackerDeliveryPartner(props: { defaultOpen?: boolean }) {
  const { defaultOpen = false } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [deliveryPartnerInfo, setDeliveryPartnerInfo] =
    useState<DeliveryPartner | null>(null);

  const [hasOpened, setHasOpened] = useState(false);

  const handleValueChange = (value: string | string[]) => {
    if (!hasOpened && value) {
      setHasOpened(true);
    }
  };

  useEffect(() => {
    async function fetchMockDeliveryPartnerInfo() {
      setLoading(true);

      const partnerInfo = {
        name: 'Chaman Mukherjee',
        contact: '+9582052413',
        languages: 'English, Hindi',
      } as DeliveryPartner;
      setTimeout(
        () => {
          setLoading(false);
          setDeliveryPartnerInfo(partnerInfo);
        },
        Math.max(2500, Math.random() * 3500),
      );
    }

    if (hasOpened) {
      if (null === deliveryPartnerInfo) fetchMockDeliveryPartnerInfo();
    }
  }, [hasOpened]);

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full text-white bg-black rounded-md "
      defaultValue={defaultOpen ? 'item-1' : ''} // Set the default open item
      onValueChange={handleValueChange}
    >
      <AccordionItem value="item-1" className="border-none ">
        <AccordionTrigger className="px-[14px] md:px-5 text-sm md:text-lg h-16 cursor-pointer font-thin no-underline border-none hover:no-underline">
          <RenderDeliveryPartnerTitle />
        </AccordionTrigger>

        <AccordionContent className="px-2 py-10 rounded-b-md md:px-5 bg-gray-700/80">
          {!loading ? (
            <RenderDeliveryPartner partnerInfo={deliveryPartnerInfo} />
          ) : (
            <RenderLoadingContent />
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function RenderLoadingContent() {
  return (
    <div className="flex items-center justify-center w-full px-4 pt-4 ">
      <p className="text-mauve8">Assigning Delivery Partner</p>
      <ProgressBar
        visible={true}
        height="38"
        borderColor="rgb(158 156 169)"
        barColor="rgb(188 186 199)"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}

function RenderDeliveryPartnerTitle() {
  return (
    <div className="flex items-center gap-2 md:gap-2.5 -ml-1">
      <User className="w-5 md:w-8" />
      <p>Delivery Partner</p>
    </div>
  );
}

function RenderDeliveryPartner(props: { partnerInfo: DeliveryPartner | null }) {
  const { partnerInfo } = props;
  if (null === partnerInfo) return null;

  return (
    <div className="text-base text-gray-100/70">
      <div className="flex gap-1.5 mt-3 text-sm  rounded-md ">
        <div className="flex flex-col py-3 pr-5 gap-3 ">
          <p>{partnerInfo.name}</p>

          {partnerInfo.languages && <p>Speaks : {partnerInfo.languages}</p>}

          <button className="w-full px-8 py-1 mt-5 rounded-lg bg-orangeHover text-slate-50">
            Call
          </button>
        </div>
      </div>
    </div>
  );
}
export default OrderTrackerDeliveryPartner;
