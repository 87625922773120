const AddButton = ({ clickHandler }) => {
  return (
    <button
      className="bg-[rgb(24,26,27)] border border-gray-700 text-green-800 font-semibold 
      text-xs px-9 py-2.5 uppercase  rounded-md 
       hover:shadow-md hover:shadow-stone-400"
      onClick={clickHandler}
    >
      Add
    </button>
  );
};

export default AddButton;
