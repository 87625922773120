import { useEffect, useRef, useState } from 'react';

import RiseAndFallAnimatedText from './RiseAndFallAnimatedText';
import { UnsplashApiResponseImageType } from '@/types/unsplashApiTypes';
import { measureTextWidth } from '@/utils/measuretextwidth';

const TrackOrderDishes = (props: {
  dishes: string[] | undefined;
  blendImages: UnsplashApiResponseImageType[];
}) => {
  const { dishes, blendImages } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [dynamicFontSize, setDynamicFontSize] = useState<number>(-1);
  const [reRenderOnAnimComplete, setReRenderOnAnimComplete] =
    useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!parentRef.current || !dishes || dishes.length === 0) {
      return;
    }

    const text = dishes[currentIndex];
    // const parentWidth = Math.min(
    //   1000,
    //   Math.max(0, parentRef.current.offsetWidth * 0.6),
    // );
    const parentWidth = Math.min(1000, Math.max(0, window.innerWidth * 0.45));

    let fontSize = 108;

    do {
      const width = measureTextWidth(text, fontSize, 'FigTree', 'medium');
      if (width <= parentWidth) {
        setDynamicFontSize(fontSize);

        break;
      }
      fontSize -= 2;
    } while (fontSize > 0);

    if (fontSize === 0) {
      console.log(
        `Dish name '${text}' cannot fit within parent width. Consider truncation.`,
      );
    }
  }, [currentIndex, parentRef, dishes, reRenderOnAnimComplete]);

  function onLetterAnimComplete() {
    setReRenderOnAnimComplete(!reRenderOnAnimComplete);
    if (dishes) setCurrentIndex((prevIndex) => (prevIndex + 1) % dishes.length);
  }

  if (!dishes) return null;

  return (
    <div className="" ref={parentRef}>
      <div className="relative flex items-center w-full h-56 bg-black rounded-lg">
        <div className="absolute flex items-center ">
          {dynamicFontSize != -1 && (
            <RiseAndFallAnimatedText
              text={dishes[currentIndex].toString()}
              fontSize={dynamicFontSize}
              onAnimComplete={onLetterAnimComplete}
            />
          )}
        </div>
        {blendImages && blendImages.length > 0 && (
          <img
            src={
              blendImages[Math.floor(Math.random() * (blendImages.length - 1))]
                .urls.small
            }
            alt=""
            className="absolute inset-0 top-4 bottom-0 object-cover w-[96%] h-[80%] mx-auto blend-color-dodge blur-[4px]"
          />
        )}
      </div>
    </div>
  );
};

export default TrackOrderDishes;
