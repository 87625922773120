import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

function RiseAndFallAnimatedText(props: {
  text: string;
  fontSize: number;
  onAnimComplete: () => void;
}) {
  const { text, fontSize, onAnimComplete } = props;

  const lettersRef = useRef<HTMLSpanElement[]>([]);
  const leftToRightTimeline = useRef<gsap.core.Timeline | null>(null);
  const rightToLeftTimeline = useRef<gsap.core.Timeline | null>(null);
  const timeoutRef = useRef<number | null>(null);

  const animLeftToRight = (onComplete: () => void) => {
    const tl = gsap.timeline({ onComplete });
    leftToRightTimeline.current = tl;

    lettersRef.current.forEach((letter, index) => {
      tl.fromTo(
        letter,
        { y: 230, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: Math.max(1.4, Math.random() * 2),
          ease: 'power1.inOut',
        },
        index * 0.1, // Add staggered delay
      );
    });

    tl.eventCallback('onComplete', onComplete);
  };

  const animRightToLeft = () => {
    const tl = gsap.timeline();
    rightToLeftTimeline.current = tl;

    const letters = lettersRef.current;

    for (let index = letters.length - 1; index >= 0; index--) {
      const letter = letters[index];

      tl.fromTo(
        letter,
        { y: 0, opacity: 1 },
        {
          y: 280,
          opacity: 0,
          duration: Math.max(1, Math.random() * 2),
          ease: 'power1.inOut',
        },
        (letters.length - index) * 0.1, // Add staggered delay
      );
    }
    tl.eventCallback('onComplete', onAnimComplete);
  };

  useEffect(() => {
    animLeftToRight(() => {
      timeoutRef.current = window.setTimeout(
        () => {
          animRightToLeft();
        },
        Math.max(1600, Math.random() * 2300),
      );
    });
    console.log(containerRef.current?.offsetWidth);

    // Cleanup function
    return () => {
      if (leftToRightTimeline.current) {
        leftToRightTimeline.current.kill();
      }
      if (rightToLeftTimeline.current) {
        rightToLeftTimeline.current.kill();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  });

  const containerRef = useRef<HTMLDivElement>(null);
  lettersRef.current = [];
  const addToRefs = (el: HTMLSpanElement) => {
    if (el && !lettersRef.current.includes(el)) {
      lettersRef.current.push(el);
    }
  };

  return (
    <div
      className="flex items-center h-40 pl-1 overflow-hidden uppercase w-max md:pl-8 " // use w-max only as w-full is causing layout issues with safari
      ref={containerRef}
    >
      {text.split('').map((letter, index) => (
        <span
          key={index}
          ref={addToRefs}
          className="inline-block antialiased font-medium text-slate-400"
          style={{ margin: '0 6px', fontSize: `${fontSize}px` }}
        >
          {letter}
        </span>
      ))}
    </div>
  );
}

export default RiseAndFallAnimatedText;
