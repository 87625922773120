import { useRef, useState } from 'react';

import {
  RESTAURANT_CHAIN_IMG_BASE_URL,
  RESTAURANT_IMAGE_PLACEHOLDER_URL,
  ROUTE_RESTAURANT_MENU,
} from '../../constants';

import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';

import CustomScrollbar from './CustomScrollbar';
import StarRatingIcon from './StarRatingIcon';

const RestaurantChainCard = ({
  id,
  imgSrc,
  offer,
  restaurantName,
  rating,
  deliveryTime,
  cuisines,
  areaName,
}) => {
  function handleClick() {
    // window.open(`${ROUTE_RESTAURANT_MENU}${id}`, "_self");
    navigateToUrlPostOrigin(`${ROUTE_RESTAURANT_MENU}${id}`);
  }

  function handleImageLoadError(event) {
    event.target.classList.add('object-scale-down');
    event.target.src = RESTAURANT_IMAGE_PLACEHOLDER_URL;
  }

  const widthClassName = 'w-40 lg:w-[274px]  min-w-44 lg:min-w-[274px]';
  const heightClassName = 'h-max min-h-[350px] ';
  return (
    <div
      className={`${widthClassName} ${heightClassName} rounded-lg  text-[rgb(163,163,161)] 
    transform transition-transform duration-100 ease-in custom-hover-scale cursor-pointer
     `}
      onClick={handleClick}
    >
      <div className="relative flex flex-col w-full h-56 filter drop-shadow-md">
        <img
          src={`${RESTAURANT_CHAIN_IMG_BASE_URL}${imgSrc}`}
          className="w-full h-[90%] object-cover object-center rounded-lg "
          onError={handleImageLoadError}
        ></img>
        <div className="absolute bottom-[10%]  w-full h-1/3 bg-gradient-to-t from-black rounded-lg">
          <p className="absolute w-full ml-2 lg:ml-5 text-sm lg:text-xl font-black text-white uppercase truncate bottom-1 ">
            {offer?.header && offer?.subHeader
              ? `${offer.header} ${offer.subHeader}`
              : ``}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-0.5 mx-1.5 lg:mx-5 text-sm lg:text-base text-slate-500/90">
        <p className="text-base w-[175px] text-slate-600 font-medium truncate text-nowrap ">
          {restaurantName}
        </p>
        <div className="flex flex-row items-center gap-1 text-sm">
          <StarRatingIcon />
          <p>{rating}</p>
          <p className=" -mt-2.5 text-xl font-bold">.</p>
          <p>{`${deliveryTime} mins`}</p>
        </div>
        <p className="text-sm truncate font-extralight">
          {cuisines.join(', ')}
        </p>
        <p className="text-sm">{areaName}</p>
      </div>
    </div>
  );
};

function Cards({ restaurants }) {
  const cards = restaurants.map((element) => {
    const {
      id,
      name,
      cloudinaryImageId,
      avgRating,
      sla,
      cuisines,
      areaName,
      aggregatedDiscountInfoV3,
    } = element.info;
    return (
      <RestaurantChainCard
        key={id}
        id={id}
        imgSrc={cloudinaryImageId}
        offer={aggregatedDiscountInfoV3}
        restaurantName={name}
        rating={avgRating}
        deliveryTime={sla.deliveryTime}
        cuisines={cuisines}
        areaName={areaName}
      />
    );
  });

  return cards;
}

const Hr = ({ showBottomDivider }) => {
  if (!showBottomDivider) return null;
  return <hr className="w-full h-2 my-4 bg-slate-50"></hr>;
};

function RestaurantChains({ restaurantChainsTitle, restaurantChains }) {
  const cardsRef = useRef(null);
  const [scrollX, setScrollX] = useState(0);

  function handleScrollEvent() {
    const cards = cardsRef.current;
    const maxScrollWidth = cards.scrollWidth - cards.clientWidth;
    const scrollX = cards.scrollLeft / maxScrollWidth;

    setScrollX(scrollX);
  }
  if (!restaurantChainsTitle || !restaurantChains) return;

  return (
    <div className="flex flex-col justify-center">
      <p className="mt-4 text-xl font-bold max-w-[90vw] overflow-hidden whitespace-nowrap">
        {restaurantChainsTitle}
      </p>
      <div
        className="flex mt-6 overflow-x-auto gap-8 no-scrollbar"
        ref={cardsRef}
        onScroll={handleScrollEvent}
      >
        <Cards restaurants={restaurantChains} />
      </div>
      <CustomScrollbar scrollPosition={scrollX} />
      <Hr />
    </div>
  );
}
export default RestaurantChains;
