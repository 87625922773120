import React from 'react';

const CategoryHeader = ({
  title,
  itemsCount,
  visibility,
  toggleVisibility,
}) => {
  return (
    <div
      className="flex justify-between w-full cursor-none hover:cursor-pointer"
      onClick={toggleVisibility}
    >
      <p className="font-black text-[rgb(159,156,151)]">{`${title} (${itemsCount})`}</p>
      <span className="material-symbols-rounded text-[rgb(170,166,161)] ">
        {visibility ? 'expand_less' : 'expand_more'}
      </span>
    </div>
  );
};

export default CategoryHeader;
