import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  AUTH_LOGOUT_ROUTE,
  USER_NAME_COOKIE_LABEL,
} from '@/backendconstants.js';
import { User } from '@/types/user';
import axios from 'axios';
import { removeCookieByName } from '@/utils/cookiehelper';

type UserContext = {
  isLoggedIn: () => boolean;
  getFirstName: () => string | null;
  getName: () => string | null;
  isAdmin: () => boolean;
  onLogin: (user: User) => void;
  /**
   * Function to logout user
   */
  useLogout: () => void;
};

const UserContext = createContext({} as UserContext);

const LOGGED_OUT_USER = {
  name: '',
} as User;

export function useUser() {
  return useContext(UserContext);
}

/**
 *
 * @param key key for value to look-up
 * @param defaultValue value to return if key not found
 * @returns
 */
function getFromLocalStorage(defaultValue: User) {
  const temp = localStorage.getItem(USER_NAME_COOKIE_LABEL);
  if (!temp) return defaultValue;
  const json = JSON.parse(temp);
  return json || defaultValue;
}

function putIntoLocalStorage(value: User) {
  const temp = JSON.stringify(value);
  localStorage.setItem(USER_NAME_COOKIE_LABEL, temp);
}

export function UserProvider(props: Readonly<{ children: ReactNode }>) {
  const { children } = props;
  const savedUserName = getFromLocalStorage(LOGGED_OUT_USER);

  const [user, setUser] = useState<User>(savedUserName);

  useEffect(() => {
    putIntoLocalStorage(user);
  }, [user]);

  function isLoggedIn() {
    return user ? user.isLoggedIn : false;
  }
  function getFirstName() {
    if (!user) return '';
    if (!user.isLoggedIn) return '';
    return user.name.split(' ')[0];
  }

  function getName() {
    if (!user) return '';
    if (!user.isLoggedIn) return '';
    return user.name;
  }

  function isAdmin() {
    return user ? user.isAdmin : false;
  }

  function onLogin(user: User) {
    setUser(user);
  }

  async function useLogout() {
    try {
      const request = await axios.post(
        AUTH_LOGOUT_ROUTE,
        {},
        {
          withCredentials: true,
        },
      );

      if (request.data.success)
        setUser({
          isLoggedIn: false,
          name: '',
          isAdmin: false,
        });
    } catch (err) {
      console.error(err);
    } finally {
      removeCookieByName(USER_NAME_COOKIE_LABEL);
      sessionStorage.clear();
    }
  }
  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        getFirstName,
        getName,
        isAdmin,
        onLogin,
        useLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
