import axios, { HttpStatusCode } from 'axios';
import MockAdapter from 'axios-mock-adapter';

const mock = new MockAdapter(axios, { delayResponse: 1000 });

// Mock the getDeliveryCharges endpoint
mock
  .onGet(`${import.meta.env.VITE_PAYMENT_HOST_URL}/api/delivery-charges`)
  .reply(HttpStatusCode.Ok, {
    deliveryFee: parseFloat((Math.random() * 90).toFixed(2)),
    deliveryDistance: `${parseFloat((Math.random() * 10).toFixed(1))} km`,
    platformFee: 5,
    gstAndRestaurantCharges: {
      gst: 28.94,
      packagingCharge: 32,
      gstOnPlatformFee: 0.9,
    },
  });

mock.onAny().passThrough();
