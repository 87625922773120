import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useUser } from './UserContext';
import { AddressWithDeliveryTime } from '@/types/user';
import {
  DeliveryChargesResponse,
  GstAndRestaurantCharges,
} from '@/api/apiResponseTypes';

type CheckoutContext = {
  getEmail: () => string;
  setEmail: (email: string) => void;
  getAddress: () => AddressWithDeliveryTime[] | null;
  setAddress: (address: AddressWithDeliveryTime[] | null) => void;
  getLocationAddress: () => AddressWithDeliveryTime | null;
  setLocationAddress: (address: AddressWithDeliveryTime | null) => void;
  getDeliveryCharges: () => DeliveryChargesResponse | null;
  updateDeliveryCharges: (deliveryCharges: DeliveryChargesResponse) => void;
  getDeliveryChargesTotal: () => number;
  getIsCartUpdating: () => boolean;
  setIsCartUpdating: (state: boolean) => void;
};

const SESSION_STORAGE_EMAIL_KEY = 'email';
const SESSION_STORAGE_ADDRESS_KEY = 'address';
const SESSION_STORAGE_LOCATION_ADDRESS_KEY = 'addressLocation';
const SESSION_STORAGE_DELIVERY_CHARGES_KEY = 'deliveryCharges';

const CheckoutContext = createContext({} as CheckoutContext);

function getEmailFromSessionStorage() {
  const savedDataString = sessionStorage.getItem(SESSION_STORAGE_EMAIL_KEY);
  if (savedDataString && savedDataString !== 'undefined') {
    const savedData: string = JSON.parse(savedDataString);
    return savedData;
  } else {
    return '';
  }
}

function getAddressFromSessionStorage(): AddressWithDeliveryTime[] | null {
  const savedDataString = sessionStorage.getItem(SESSION_STORAGE_ADDRESS_KEY);
  if (savedDataString && savedDataString !== 'undefined') {
    const savedData = JSON.parse(savedDataString);
    return savedData;
  } else {
    return null;
  }
}

function getLocationAddressFromSessionStorage(): AddressWithDeliveryTime | null {
  const savedDataString = sessionStorage.getItem(
    SESSION_STORAGE_LOCATION_ADDRESS_KEY,
  );
  if (savedDataString && savedDataString !== 'undefined') {
    const savedData = JSON.parse(savedDataString);
    return savedData;
  } else {
    return null;
  }
}

function getDeliveryChargesFromSessionStorage(): DeliveryChargesResponse | null {
  const savedDataString = sessionStorage.getItem(
    SESSION_STORAGE_DELIVERY_CHARGES_KEY,
  );
  if (savedDataString && savedDataString !== 'undefined') {
    const savedData = JSON.parse(savedDataString);
    return savedData;
  } else {
    return null;
  }
}

function putIntoSessionStorage(
  key: string,
  data:
    | string
    | AddressWithDeliveryTime[]
    | AddressWithDeliveryTime
    | DeliveryChargesResponse
    | null
    | undefined,
) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function CheckoutProvider(props: { children: ReactNode }) {
  const { children } = props;
  const [email, setEmail] = useState(getEmailFromSessionStorage());

  const [address, setAddress] = useState<AddressWithDeliveryTime[] | null>(
    getAddressFromSessionStorage(),
  );

  const [deliveryCharges, setDeliveryCharges] =
    useState<DeliveryChargesResponse | null>(
      getDeliveryChargesFromSessionStorage(),
    );

  const [locationAddress, setLocationAddress] =
    useState<AddressWithDeliveryTime | null>(
      getLocationAddressFromSessionStorage(),
    );

  const [isCartUpdating, setIsCartUpdating] = useState<boolean>(false);

  const { isLoggedIn } = useUser();
  const userLoggedIn = isLoggedIn();

  useEffect(() => {
    if (!userLoggedIn) {
      setEmail('');
      setAddress(null);
      setLocationAddress(null);
      setDeliveryCharges({
        deliveryFee: 0,
        deliveryDistance: '0km',
        platformFee: 0,
        gstAndRestaurantCharges: {
          gst: 0,
          gstOnPlatformFee: 0,
        } as GstAndRestaurantCharges,
      } as DeliveryChargesResponse);
    }

    // Store values in session storage, only if they have changed
    putIntoSessionStorage(SESSION_STORAGE_EMAIL_KEY, email);
    putIntoSessionStorage(SESSION_STORAGE_ADDRESS_KEY, address);
    putIntoSessionStorage(
      SESSION_STORAGE_LOCATION_ADDRESS_KEY,
      locationAddress,
    );
    putIntoSessionStorage(
      SESSION_STORAGE_DELIVERY_CHARGES_KEY,
      deliveryCharges,
    );
  }, [userLoggedIn]);

  // Separate useEffect for storing values in session storage
  useEffect(() => {
    putIntoSessionStorage(SESSION_STORAGE_EMAIL_KEY, email);
  }, [email]);

  useEffect(() => {
    putIntoSessionStorage(SESSION_STORAGE_ADDRESS_KEY, address);
  }, [address]);

  useEffect(() => {
    putIntoSessionStorage(
      SESSION_STORAGE_LOCATION_ADDRESS_KEY,
      locationAddress,
    );
  }, [locationAddress]);

  useEffect(() => {
    putIntoSessionStorage(
      SESSION_STORAGE_DELIVERY_CHARGES_KEY,
      deliveryCharges,
    );
  }, [deliveryCharges]);

  function getEmail() {
    return email;
  }

  function getAddress() {
    return address;
  }

  function getLocationAddress() {
    return locationAddress;
  }

  function getDeliveryCharges() {
    return deliveryCharges;
  }

  function getDeliveryChargesTotal() {
    if (!deliveryCharges) return 0;
    return (
      deliveryCharges.deliveryFee +
      deliveryCharges.gstAndRestaurantCharges.gst +
      deliveryCharges.gstAndRestaurantCharges.gstOnPlatformFee +
      (deliveryCharges.gstAndRestaurantCharges.packagingCharge
        ? deliveryCharges.gstAndRestaurantCharges.packagingCharge
        : 0)
    );
  }

  function getIsCartUpdating() {
    return isCartUpdating;
  }

  function updateDeliveryCharges(deliveryCharges: DeliveryChargesResponse) {
    setDeliveryCharges(deliveryCharges);
  }

  return (
    <CheckoutContext.Provider
      value={{
        getEmail,
        setEmail,
        getAddress,
        setAddress,
        getLocationAddress,
        setLocationAddress,
        getDeliveryCharges,
        updateDeliveryCharges,
        getDeliveryChargesTotal,
        getIsCartUpdating,
        setIsCartUpdating,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}

export function useCheckout() {
  return useContext(CheckoutContext);
}
