import {
  RESTAURANT_CHAIN_IMG_BASE_URL,
  RESTAURANT_IMAGE_PLACEHOLDER_URL,
  ROUTE_RESTAURANT_MENU,
} from '../../constants';

import { RestaurantAttributes } from '@/types/restaurantType';
import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';

import StarRatingIcon from '@/reactcomponents/StarRatingIcon';

import { generateKey } from '@/utils/keygen';
import { useInfiniteScrollContext } from '@/context/InfiniteScrollContext';

import Filters from './InfiniteScrollRestaurantsFilters';
function InfiniteScrollRestaurants() {
  const {
    getRestaurants,
    getLoadMoreReference,
    isLoading,
    getTriggerLoadRestaurantCount,
  } = useInfiniteScrollContext();
  const restaurants = getRestaurants();

  const loading = isLoading();

  return (
    <>
      <Filters />
      <div className="w-full my-4 grid col-span-1 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-3 md:gap-x-8  lg:gap-x-12 gap-y-5 lg:gap-y-8">
        {restaurants?.map((r, index) => (
          <RenderRestaurantCard key={r.id} restaurant={r} index={index} />
        ))}

        {loading && (
          <RenderCardsSkeleton count={getTriggerLoadRestaurantCount()} />
        )}
      </div>
      <div ref={getLoadMoreReference()} className="w-full h-4 my-0"></div>
    </>
  );
}

function RenderCardsSkeleton(props: Readonly<{ count: number }>) {
  const { count } = props;
  const cards = [];
  for (let i = 0; i < count; i++) {
    cards.push(<CardSkeleton key={generateKey()} />);
  }
  return <>{cards}</>;
}

function CardSkeleton() {
  return (
    <div className="relative flex flex-col w-full h-56 rounded-lg animate-pulse">
      <div className="relative h-[90%] bg-gray-300 rounded-lg"></div>
      <div className=" ml-2 my-2 w-[72%] h-4 bg-gray-200 rounded-md"></div>
      <div className="  my-2 w-[82%] h-4 bg-gray-200 rounded-md"></div>
    </div>
  );
}
function RenderRestaurantCard(
  props: Readonly<{ restaurant: RestaurantAttributes; index: number }>,
) {
  const { restaurant, index } = props;
  const offer = restaurant.offers ? restaurant.offers[0] : undefined;
  const widthClassName = 'w-[100%]';
  function handleClick() {
    navigateToUrlPostOrigin(`${ROUTE_RESTAURANT_MENU}${restaurant.swiggyId}`);
  }

  function handleImageLoadError(
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) {
    const target = event.target as HTMLImageElement;
    target.classList.add('object-scale-down');
    target.src = RESTAURANT_IMAGE_PLACEHOLDER_URL;
  }

  return (
    <div className="flex w-full justify-center">
      <div
        className="max-w-[300px] rounded-lg text-[rgb(163,163,161)] w-full transform transition-transform duration-150 ease-in custom-hover-scale cursor-pointer "
        onClick={handleClick}
      >
        <div className={`relative flex flex-col  h-56 ${widthClassName}`}>
          <img
            src={`${RESTAURANT_CHAIN_IMG_BASE_URL}${restaurant.cloudinaryImageId}`}
            className={`${widthClassName} h-[90%] object-cover object-center rounded-lg`}
            onError={handleImageLoadError}
          ></img>
          <div
            className={`absolute ${widthClassName} bottom-[10%] h-1/3 bg-gradient-to-t from-black rounded-lg`}
          >
            <p className="absolute w-full px-1 md:px-3 text-xl font-black text-white uppercase truncate bottom-1">
              {offer?.offerLabel ? `${offer.offerLabel}` : ``}
            </p>
          </div>
        </div>
        <div className="px-1 text-gray-800 w-full -mt-3">
          <p className="text-base w-full truncate text-nowrap text-gray-800 font-semibold mb-1">
            {restaurant.name}
          </p>
          <RenderRestaurantRatingAndDeliveryTime restaurant={restaurant} />
          <p className="text-base text-gray-600 truncate">
            {restaurant.cuisines.join(', ')}
          </p>
          <p className="text-gray-500">{restaurant.areaName}</p>
          {/* <p>{restaurant.costForTwoMessage}</p> */}
          <p className="text-slate-400">index:{index}</p>
        </div>
      </div>
    </div>
  );
}

function RenderRestaurantRatingAndDeliveryTime(props: {
  restaurant: RestaurantAttributes;
}) {
  const { restaurant } = props;
  const deliveryTime = restaurant.deliveryInfo?.slaString;
  if (deliveryTime) {
    return (
      restaurant.avgRating && (
        <div className="flex flex-row items-center gap-1">
          <StarRatingIcon />
          <p>{restaurant.avgRating}</p>
          <p className=" -mt-2.5">.</p>
          <p>{deliveryTime}</p>
        </div>
      )
    );
  }
  return (
    restaurant.avgRating && (
      <div className="flex flex-row items-center gap-1">
        <StarRatingIcon />
        <p className="font-medium">{restaurant.avgRating}</p>
      </div>
    )
  );
}

export default InfiniteScrollRestaurants;
