import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { useShoppingCart } from '@/context/ShoppingCartContext';
import { generateKey } from '@/utils/keygen';
import { CartRestaurant } from '@/types/carttypes';
import { IndianRupee } from 'lucide-react';
import {
  BG_COLOR_ORANGE,
  CART_ITEM_NAME_COLOR_CLASS_NAME,
} from './themecolors';
import { DISH_IMAGE_URL, ROUTE_RESTAURANT_MENU } from '../../constants';

import SwiggyIcon from '../../images/Swiggy.svg';
import VegIcon from '../../images/Veg_symbol.svg';
import NonVegIcon from '../../images/Non_veg_symbol.svg';
import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';
import SignIn from './header/SignIn';
import { useHeaderVisibility } from '@/context/HeaderVisibilityContext';

function Header() {
  const { isHeaderVisible } = useHeaderVisibility();

  return (
    <div
      className={`sticky top-0 z-10 flex items-center justify-between w-full px-2 pt-4 pb-4 bg-white shadow-sm shadow-black/5 md:px-8 lg:px-12 2xl:px-20 md:pt-8
    ${isHeaderVisible() ? 'visible animate-headerShow' : 'invisible animate-headerHide'}`}
    >
      <div className="flex gap-8">
        <img
          src={SwiggyIcon}
          alt="Logo"
          className="w-12 h-12 cursor-pointer ms-auto"
          onClick={() => {
            navigateToUrlPostOrigin('/');
          }}
        ></img>
        {/* <p>Location</p> */}
      </div>
      <div className="flex gap-4 ">
        {/* <p>Search</p>
        <p>Offers</p>
        <p>Help</p> */}
        <SignIn />

        <HeaderCartLabel />
      </div>
    </div>
  );
}

function HeaderCartLabel() {
  const { getTotalItemsCount } = useShoppingCart();
  const itemsCount = getTotalItemsCount();
  return itemsCount > 0 ? (
    <FilledCart itemsCount={itemsCount} />
  ) : (
    <EmptyCart />
  );
}

function FilledCart(props: Readonly<{ itemsCount: number }>) {
  const { itemsCount } = props;
  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger
          className={`hover:text-[rgb(237,131,46)] px-2 `}
          onClick={() => navigateToUrlPostOrigin('/checkout')}
        >
          Cart {itemsCount}
        </TooltipTrigger>
        <TooltipContent
          className={`w-max mt-4 mr-4 shadow-2xl pt-8 pb-10 bg-white rounded-lg  border-t-2 border-[rgb(237,134,57)] `}
        >
          <CartDishes />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

function CartDishes() {
  const { getCartItems, getCartRestaurantDetails, getCartTotal } =
    useShoppingCart();
  const cartRestaurantDetails: CartRestaurant = getCartRestaurantDetails();
  const items = getCartItems();
  const itemElems = items.map((item) => {
    return (
      <div
        key={generateKey()}
        className="flex items-center justify-between py-2"
      >
        <div className="flex items-center w-3/4 ">
          <img
            src={`${DISH_IMAGE_URL}${item.imageId}`}
            loading="lazy"
            className="mr-4 size-12 rounded-xl"
            alt=""
          />

          <p
            key={generateKey()}
            className={`max-w-40 my-4 mr-4 font-medium overflow-x-hidden whitespace-nowrap overflow-ellipsis ${CART_ITEM_NAME_COLOR_CLASS_NAME}`}
          >
            {item.name}
          </p>

          <img
            src={item.veg ? VegIcon : NonVegIcon}
            alt="veg icon"
            className="w-4 h-4 ms-auto"
          ></img>
        </div>

        <div className="flex items-center gap-1 mx-6 font-thin text-slate-500">
          <div className="flex items-center">
            <IndianRupee className="size-3" />
            <p className="whitespace-nowrap"> {item.price}</p>
          </div>
          x
          <p className="font-light underline whitespace-nowrap decoration-slate-900 decoration-0 underline-offset-2 ">
            {item.quantity}
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className="px-6">
      <div className="">
        <p className="text-base font-semibold">{cartRestaurantDetails.name}</p>
        <p className="text-[13px] font-thin text-gray-700 my-1">
          {cartRestaurantDetails.areaName}
        </p>
        <p
          className="my-4 font-semibold uppercase cursor-pointer text-sky-600"
          onClick={() => {
            navigateToUrlPostOrigin(
              `${ROUTE_RESTAURANT_MENU}${cartRestaurantDetails.id}`,
            );
          }}
        >
          View Full menu
        </p>
      </div>
      <hr className="mb-3 ml-2" />
      {itemElems}
      <hr className="my-4 ml-1 border border-dashed" />

      <div className="flex justify-between my-4 ">
        <p className="text-base font-medium">Sub Total</p>
        <div className="flex gap-0.5 items-center whitespace-nowrap text-[14px]">
          <IndianRupee className="size-[11px]" />
          <p className="font-normal ">{getCartTotal()}</p>
        </div>
      </div>
      <p className="text-slate-600 -mt-[14px] tracking-wide font-thin ">
        Extra Charges may apply
      </p>
      <button
        className={`w-full  py-4 mt-8 text-white font-semibold text-sm uppercase ${BG_COLOR_ORANGE}`}
        onClick={() => navigateToUrlPostOrigin('/checkout')}
      >
        Checkout
      </button>
    </div>
  );
}

function EmptyCart() {
  const emptyCartTextStyle = 'text-base text-gray-500 font-thin';
  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger className={`hover:text-orangeHover`}>
          Cart
        </TooltipTrigger>
        <TooltipContent
          className={`w-max mt-4 mr-4 shadow-2xl pt-8 pb-10  px-12 bg-white rounded-lg  border-t-2 border-orangeHover `}
        >
          <h1 className="mb-6 text-4xl font-semibold text-gray-500">
            Cart Empty
          </h1>
          <p className={`${emptyCartTextStyle}`}>
            Good food is always cooking!
          </p>

          <p className={`${emptyCartTextStyle}`}>Go ahead, order some yummy</p>

          <p className={`${emptyCartTextStyle}`}>items from the menu.</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default Header;
