import Dish from './Dish';

const CategoryDishes = ({ restaurantId, dishes, infoCard }) => {
  if (!dishes) return;
  return dishes.map((d, index) => (
    <Dish
      key={d.card.info.id}
      restaurantId={restaurantId}
      dish={d}
      infoCard={infoCard}
      showBottomDivider={index < dishes.length - 1}
    />
  ));
};

export default CategoryDishes;
