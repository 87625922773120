import axios from 'axios';
import { USER_GET_CONTACT_AND_ADDRESS_ROUTE } from '@/backendconstants';
import { UserCommunicationAndAddressRequestSuccessResponse } from '@/api/apiResponseTypes';
import { AddressWithDeliveryTime } from '@/types/user';

export async function fetchUserCommunicationAndAddressDetails(
  successCallback: (
    data: UserCommunicationAndAddressRequestSuccessResponse,
  ) => void,
  errorCallback: (err: unknown) => void,
) {
  try {
    const request = await axios.get(USER_GET_CONTACT_AND_ADDRESS_ROUTE, {
      withCredentials: true,
    });

    if (request.statusText === 'OK') {
      const { data } = request;
      successCallback(data);
    }
  } catch (err) {
    errorCallback(err);
  }
}

export async function mockFetchUserCommunicationAndAddressDetails(
  successCallback: (
    data: UserCommunicationAndAddressRequestSuccessResponse,
  ) => void,
  errorCallback: (err: unknown) => void,
) {
  try {
    const addresses: AddressWithDeliveryTime[] = [
      {
        address: {
          label: 'Home',
          city: 'new delhi',
          state: 'delhi',
          pincode: 110027,
          flatNumber: 'a-10',
          street: 'street',
        },
        time: 20,
        timeUnit: 'mins',
      },
      {
        address: {
          city: 'new delhi',
          state: 'delhi',
          pincode: 110027,
          flatNumber: 'b-10',
          street: 'street',
        },
        time: 40,
        timeUnit: 'mins',
      },
      {
        address: {
          label: 'Royal Plaza',
          city: 'New delhi',
          state: 'Delhi',
          pincode: 110001,
          flatNumber: 'c-10',
          street: 'Ashoka Rd, Janpath, Connaught Place',
          country: 'India',
        },
        time: 65,
        timeUnit: 'mins',
      },
    ];

    const locationAddress = {
      address: {
        label: 'Add New Address',
        city: 'New delhi',
        state: 'Delhi',
        pincode: 110001,
        flatNumber: 'c-20',
        street:
          'Ashoka Rd, Janpath, Connaught Place hfewhfieifebiuvefbiuverbcvberciuerbncuierbcviuerbviuerbviuerbviuerbvervbiervbebfjwkdfjebfhebferbfviervfervhervuhervfuhervfuerbvihuervuhervcuerbvcferververbvierver99999ejkfbjherfhevjhevhcvehcvhercvhercvhercvhehy3evchyveyucvy3evch3evcjhvghedvcghedce99999wdjcbhjdwvcvhhwevce88888cndwcvhjdvchedvcecjece7777',
        country: 'India',
      },
      time: 65,
      timeUnit: 'mins',
    };

    setTimeout(
      () => {
        successCallback({
          email: 'mockEmail',
          address: addresses,
          locationAddress: locationAddress,
        });
      },
      Math.max(2000, Math.random() * 3000),
    );
  } catch (err) {
    errorCallback(err);
  }
}
