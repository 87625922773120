import React from 'react';
import { useRouteError } from 'react-router-dom';

const Error_404 = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4 ">
      <p className="text-[23vw]">{404}</p>
      <p>Page Not Found</p>
    </div>
  );
};

const ErrorPage = () => {
  const error = useRouteError();
  console.log(error);
  switch (error.status) {
    case 404:
      return <Error_404 />;

    default:
      return (
        <div className="flex flex-col items-center justify-center w-screen h-screen gap-8">
          <h1 className="text-4xl font-bold">OOPS !</h1>

          <p>Sorry, an unexpected error has occurred.</p>

          <p className="text-[20vw]">{error.status}</p>
          <p>{error.statusText || error.message}</p>
        </div>
      );
  }
};

export default ErrorPage;
