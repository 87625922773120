export const PAYMENT_CREATE_ORDER_ID_URL = `${
  import.meta.env.VITE_PAYMENT_HOST_URL
}/payment/createOrder`;

export const PAYMENT_VERIFY_URL = `${
  import.meta.env.VITE_PAYMENT_HOST_URL
}/payment/verifyPayment`;

export const PAYMENT_FAIL_URL = `${
  import.meta.env.VITE_PAYMENT_HOST_URL
}/payment/paymentFail`;

export const PAYMENT_CANCEL_URL = `${
  import.meta.env.VITE_PAYMENT_HOST_URL
}/payment/paymentCancel`;
