import { Button } from '@/components/ui/button';
import { ReloadIcon } from '@radix-ui/react-icons';

/**
 * React button component built on top of shadcn/Button to render animation and label when disabled
 * @param  props.label - string label or ReactNode to be rendered on button
 * @param  props.className - class name to be applied to button when enabled
 * @param  props.disabled - flag to indicate if button is disabled
 * @param  props.disableClassName - class name to be applied to button when disabled
 * @param  props.disableLabel - label to be displayed on button when disabled
 * @param  props.disableLabelClassName - class name to be applied to label when button is disabled
 * @param  props.clickHandler - callback to be invoked on button click, not invoked if button is disabled
 * @returns
 */
function AnimateWhenDisabledButton(
  props: Readonly<{
    label: string | React.ReactNode;
    className: string;
    disabled: boolean;
    disableClassName?: string;
    disableLabel?: string;
    disableLabelClassName?: string;
    clickHandler: () => void;
  }>,
) {
  const {
    label,
    className,
    disabled,
    disableClassName,
    disableLabel,
    disableLabelClassName,
    clickHandler,
  } = props;

  if (disabled)
    return (
      <Button disabled className={disableClassName}>
        <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
        <p className={disableLabelClassName}>{disableLabel}</p>
      </Button>
    );
  return (
    <div
      className={className}
      onClick={() => {
        if (disabled) return;
        clickHandler();
      }}
    >
      {label}
    </div>
  );
}
export default AnimateWhenDisabledButton;
