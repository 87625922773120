import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { ProgressBar } from 'react-loader-spinner';

import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';

import {
  GoogleMapContextProvider,
  useGoogleMap,
} from '@/context/GoogleMapContext';
import MyGoogleMap from '@/context/MyGoogleMap';

function OrderTrackerMap(props: { defaultOpen?: boolean }) {
  const { defaultOpen = true } = props;

  return (
    <GoogleMapContextProvider>
      <OrderTrackerMapContent defaultOpen={defaultOpen} />
    </GoogleMapContextProvider>
  );
}
function OrderTrackerMapContent(props: { defaultOpen?: boolean }) {
  const { defaultOpen = true } = props;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(
      () => {
        setLoading(false);
      },
      Math.max(2500, Math.random() * 3500),
    );
  }, []);

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full text-white bg-black rounded-md "
      defaultValue={defaultOpen ? 'item-1' : ''} // Set the default open item
    >
      <AccordionItem value="item-1" className="border-none ">
        <AccordionTrigger className="px-[14px] md:px-5 text-sm md:text-lg h-16 cursor-pointer font-thin no-underline border-none hover:no-underline">
          <RenderTrackerTrigger />
        </AccordionTrigger>

        <AccordionContent className="px-2 py-10 rounded-b-md md:px-5 bg-gray-700/80">
          {!loading ? <RenderMap /> : <RenderLoadingContent />}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function RenderTrackerTrigger() {
  const { getAwayTime } = useGoogleMap();
  const awayTime = getAwayTime();
  return (
    <>
      {awayTime === -1 && 'Tracking Delivery Partner'}
      {awayTime === 0 && <p>Simulated Tracker : Arrived</p>}
      {awayTime >= 1 && (
        <p>
          Simulated Tracker : {'  '} {getAwayTime()}
          {getAwayTime() === 1 ? ' minute' : ' minutes'} away
        </p>
      )}
    </>
  );
}

function RenderLoadingContent() {
  return (
    <div className="flex items-center justify-center w-full px-4 pt-4 ">
      <p className="text-mauve8">Tracking Delivery Partner</p>
      <ProgressBar
        visible={true}
        height="38"
        borderColor="rgb(158 156 169)"
        barColor="rgb(188 186 199)"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}

function RenderMap() {
  const { isUserLocationPermissionDenied } = useGoogleMap();
  if (isUserLocationPermissionDenied()) {
    return (
      <div>
        <p>Location permission denied, grant permission for tracking order</p>
      </div>
    );
  }
  return (
    <div className="relative w-full h-[620px]  rounded-md">
      <MyGoogleMap />
    </div>
  );
}

export function MapPreviewTimeSelector() {
  return (
    <RadioGroup defaultValue="1minute">
      <div className="flex flex-row gap-3 mt-3">
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="1minute" id="r1" />
          <Label htmlFor="r1">1 minute</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="2minutes" id="r2" />
          <Label htmlFor="r2">2 minutes</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="5minutes" id="r3" />
          <Label htmlFor="r3">5 minutes</Label>
        </div>
      </div>
    </RadioGroup>
  );
}

export default OrderTrackerMap;
