import React, { useEffect, useRef, useState } from 'react';
import { DISH_IMAGE_URL } from '../../constants';
import VegIcon from '../../images/Veg_symbol.svg';
import NonVegIcon from '../../images/Non_veg_symbol.svg';

import { computeDishPrice } from '@/utils/pricecomputer';
import { useShoppingCart } from '@/context/ShoppingCartContext';
import { CartItem, CartRestaurant } from '@/types/carttypes';
import { MinusIcon, PlusIcon } from 'lucide-react';

import { gsapTransXAlpha_delay, gsapTransYAlpha } from '@/utils/gsap';
import TwoLineTextReadMore from './TwoLineTextReadMore';

type AddDishToCartFunction = () => void;
type ProvideSwitchCartRestaurantandItem = () => {
  restaurantDetails: CartRestaurant;
  dish: CartItem;
};

/**
 * React component to render Dish (label, image etc)
 */
const Dish = (props: {
  restaurantId: number;
  dish;
  infoCard;
  showBottomDivider: boolean;
}) => {
  const { restaurantId, dish, infoCard, showBottomDivider } = props;
  const { id, name, description, price, defaultPrice, imageId, itemAttribute } =
    dish.card.info;

  const dishPrice = computeDishPrice(price, defaultPrice);

  const { addItemToCart, getItemQuantity, setCartRestaurantDetails } =
    useShoppingCart();

  const restaurantImageId = infoCard?.info?.cloudinaryImageId;
  const restaurantName = infoCard?.info?.name;
  const restaurantAreaName = infoCard?.info?.areaName;

  function provideSwitchCartRestaurantandItem() {
    return {
      restaurantDetails: {
        id: restaurantId,
        name: restaurantName,
        areaName: restaurantAreaName,
      } as CartRestaurant,
      dish: {
        id: id,
        name: name,
        price: dishPrice,
        quantity: 1,
        imageId: imageId,
        veg: itemAttribute.vegClassifier === 'VEG',
      } as CartItem,
    };
  }
  function onAddClick() {
    const dish: CartItem = {
      id: id,
      name: name,
      price: dishPrice,
      quantity: 1,
      imageId: imageId,
      veg: itemAttribute.vegClassifier === 'VEG',
    };

    setCartRestaurantDetails(
      restaurantId,
      restaurantImageId,
      restaurantName,
      restaurantAreaName,
    );

    addItemToCart(dish);
  }

  const preFilledQuantity = getItemQuantity(restaurantId, id);
  return (
    <div className="w-full">
      <div className="flex items-center justify-between w-full my-8 ">
        <div className="font-medium text-[rgb(173,168,159)] w-[66%]">
          <img
            src={itemAttribute.vegClassifier === 'VEG' ? VegIcon : NonVegIcon}
            alt="veg icon"
            className="w-4 h-4"
          ></img>
          <p>{name}</p>
          <p className="mt-1 mb-4">&#x20b9;{dishPrice}</p>

          <TwoLineTextReadMore
            text={description}
            textClassName="font-light text-sm text-[rgb(184,180,173)] pr-16"
            readMoreStyleClassName={'text-sm mb-6'}
          />
        </div>
        <div className="relative">
          <DishImage imageId={imageId} name={name} />
          <AddButton
            dishId={id}
            restaurantId={restaurantId}
            addDishToCart={onAddClick}
            provideSwitchCartRestaurantandItem={
              provideSwitchCartRestaurantandItem
            }
            preFilledQuantity={preFilledQuantity}
          />
        </div>
      </div>
      <Hr showBottomDivider={showBottomDivider} />
    </div>
  );
};

function AddButton(
  props: Readonly<{
    dishId: number;
    restaurantId: number;
    addDishToCart: AddDishToCartFunction;
    provideSwitchCartRestaurantandItem: ProvideSwitchCartRestaurantandItem;
    preFilledQuantity: number;
  }>,
) {
  const {
    dishId,
    restaurantId,
    addDishToCart,
    provideSwitchCartRestaurantandItem,
    preFilledQuantity,
  } = props;
  if (preFilledQuantity === 0)
    return (
      <SimpleAddButton
        addDishToCart={addDishToCart}
        provideSwitchCartRestaurantandItem={provideSwitchCartRestaurantandItem}
        restaurantId={restaurantId}
      />
    );
  else
    return (
      <QuantityUpdaterButton
        preFilledQuantity={preFilledQuantity}
        dishId={dishId}
      />
    );
}

function SimpleAddButton(
  props: Readonly<{
    addDishToCart: AddDishToCartFunction;
    provideSwitchCartRestaurantandItem: ProvideSwitchCartRestaurantandItem;
    restaurantId: number;
  }>,
) {
  const { addDishToCart, provideSwitchCartRestaurantandItem, restaurantId } =
    props;
  const { canAddDishFromRestaurant, showCartRestaurantSwitchDialog } =
    useShoppingCart();
  const textRef = useRef(null);

  return (
    <div
      className="absolute w-[80%] bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2
       bg-white border border-gray-200 text-green-600 font-bold
      text-base px-9 py-2.5 uppercase rounded-[10px] shadow-lg
      hover:shadow-xl hover:bg-slate-300
      cursor-pointer"
      onClick={() => {
        if (!canAddDishFromRestaurant(restaurantId)) {
          const details = provideSwitchCartRestaurantandItem();
          showCartRestaurantSwitchDialog(
            details.restaurantDetails,
            details.dish,
          );
          return;
        }
        if (textRef.current) {
          gsapTransYAlpha(textRef.current, 0, -14, 1, 0, 0.1, () =>
            addDishToCart(),
          );
        } else addDishToCart();
      }}
    >
      <p ref={textRef} className="flex items-center justify-center">
        Add
      </p>
    </div>
  );
}

function QuantityUpdaterButton(
  props: Readonly<{
    preFilledQuantity: number;
    dishId: number;
  }>,
) {
  const { preFilledQuantity, dishId } = props;
  const minusElemRef = useRef(null);
  const quantityElemRef = useRef(null);
  const plusElemRef = useRef(null);
  const { decreaseQuantity, increaseQuantity } = useShoppingCart();

  const animateIncreaseQuantity = (
    quantityElement: React.MutableRefObject<HTMLElement>,
    dishId: number,
  ) => {
    gsapTransYAlpha(quantityElement, 0, -14, 1, 0, 0.1, () => {
      increaseQuantity(dishId);
      gsapTransYAlpha(quantityElement, 9, 0, 0, 1, 0.1, undefined);
    });
  };

  const animateDecreaseQuantity = (
    quantityElement: React.MutableRefObject<HTMLElement>,
    dishId: number,
  ) => {
    gsapTransYAlpha(quantityElement, 0, 9, 1, 0, 0.1, () => {
      decreaseQuantity(dishId);
      gsapTransYAlpha(quantityElement, -14, 0, 0, 1, 0.1, undefined);
    });
  };

  useEffect(() => {
    gsapTransYAlpha(quantityElemRef.current, 14, 0, 0, 1, 0.1);
    gsapTransXAlpha_delay(minusElemRef.current, '-55%', 0, 0, 1, 0.15, 0.08);
    gsapTransXAlpha_delay(plusElemRef.current, '55%', 0, 0, 1, 0.15, 0.08);
  }, []);
  return (
    <div
      className="absolute flex items-center justify-around w-[80%] h-10 bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2
       bg-white border border-gray-200 text-green-600 font-bold
      text-base  uppercase rounded-[10px] shadow-lg "
    >
      <MinusIcon
        ref={minusElemRef}
        className=" cursor-pointer me-auto w-10 px-3 h-full rounded-l-[9px] hover:bg-slate-300"
        onClick={() => {
          if (quantityElemRef.current)
            animateDecreaseQuantity(quantityElemRef.current, dishId);
          else decreaseQuantity(dishId);
        }}
      />
      <p ref={quantityElemRef} className="text-base font-bold ">
        {preFilledQuantity}
      </p>
      <PlusIcon
        ref={plusElemRef}
        className=" cursor-pointer ms-auto w-10 px-3 h-full rounded-r-[9px] hover:bg-slate-300"
        onClick={() => {
          if (quantityElemRef.current)
            animateIncreaseQuantity(quantityElemRef.current, dishId);
          else increaseQuantity(dishId);
        }}
      />
    </div>
  );
}

const Hr = (props: { showBottomDivider: boolean }) => {
  const { showBottomDivider } = props;
  if (!showBottomDivider) return null;
  return <hr className="w-full h-2 my-4 bg-slate-50"></hr>;
};

const DishImage = (props: { imageId: number; name: string }) => {
  const { imageId, name } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      event.preventDefault();
    };

    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault();
    };

    const img = document.getElementById(`dish-image-${imageId}`);

    if (img) {
      img.addEventListener('touchstart', handleTouchStart);
      img.addEventListener('touchmove', handleTouchMove);
    }

    return () => {
      if (img) {
        img.removeEventListener('touchstart', handleTouchStart);
        img.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [imageId]);

  const handleContextMenu = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    event.preventDefault();
  };

  if (!imageId || error) {
    return (
      <div className="test w-[156px] h-0 rounded-xl bg-transparent invisible"></div>
    );
  }

  //todo: image loading has been set to 'eager' for now, to fix jank (safari oniOS , iPhone SE) while displaying 'SwitchCartRestaurantDialog/tsx' as it causes re-render of entire menu, set loading back to 'lazy' post performance-optimization/fix
  return (
    <img
      id={`dish-image-${imageId}`}
      src={`${DISH_IMAGE_URL}${imageId}`}
      alt={name}
      loading="eager"
      className="object-cover w-[156px] h-[144px] rounded-xl border-none select-none"
      onError={() => setError(true)}
      onContextMenu={handleContextMenu}
    />
  );
};

export default Dish;
