import { RESTAURANT_MENU_URL } from '@/api/endpoints/restaurantapiendpoints';

const cardPaths = [
  'card.card',
  'groupedCard.cardGroupMap.REGULAR.cards',
  'ff',
  'a',
];

const RESTAURANT_MENU_CAROUSEL_CARD_TYPE =
  'type.googleapis.com/swiggy.presentation.food.v2.MenuCarousel';

const RESTAURANT_INFO_CARD_TYPE =
  'type.googleapis.com/swiggy.presentation.food.v2.Restaurant';

const RESTAURANT_GRID_WIDGET_CARD_TYPE =
  'type.googleapis.com/swiggy.gandalf.widgets.v2.GridWidget';

const RESTAURANT_ITEM_CATEGORY_CARD_TYPE =
  'type.googleapis.com/swiggy.presentation.food.v2.ItemCategory';

const RESTAURANT_LICENSE_INFO_CARD_TYPE =
  'type.googleapis.com/swiggy.presentation.food.v2.RestaurantLicenseInfo';
const RESTAURANT_ADDRESS_CARD_TYPE =
  'type.googleapis.com/swiggy.presentation.food.v2.RestaurantAddress';

const RESTAURANT_MENU_VEG_FILTER_AND_BADGE_CARD_TYPE =
  'type.googleapis.com/swiggy.presentation.food.v2.MenuVegFilterAndBadge';

const RESTAURANT_NESTED_ITEM_CATEGORY_CARD_TYPE =
  'type.googleapis.com/swiggy.presentation.food.v2.NestedItemCategory';

async function fetchRestaurantMenu(
  restaurantId,
  successCallback,
  errorCallback,
) {
  const f = await fetch(`${RESTAURANT_MENU_URL}?restaurantId=${restaurantId}`);

  if (f.ok) {
    const data = await f.json();
    console.log(data);

    try {
      data.statusCode;
    } catch (err) {
      errorCallback(
        "error fetching data from swiggy's api, actually there is no statusCode in api's response",
      );
    }
    if (data.statusCode != 0) {
      errorCallback(
        'swiggy api response statuscode error',
        'statusCode = ' +
          data.statusCode +
          ', status message = ' +
          data.statusMessage,
      );
      return;
    }

    const menu = menuParser(data);
    if (menu) successCallback(menu);
    else
      errorCallback(
        'error while parsing menu',
        "error while parsing menu from swiggy's API Response",
      );
  } else {
    errorCallback(f.status, f.statusText);
  }
}

function menuParser({ data }) {
  const menu = {
    statusMessage: data.statusMessage,
    restaurantInfoCards: [],
    menuCarouselCards: [],
    filterAndBadgeCards: [],
    gridWidgetCards: [],
    itemCategoryCards: [],
    nestedItemCategoryCards: [],
    licenseInfoCards: [],
    addressCards: [],
  };

  const cards = [];

  data?.cards?.forEach((card) => {
    cardPaths.forEach((path) => {
      const cardAtPath = getCardByPath(card, path);
      if (cardAtPath) {
        if (Array.isArray(cardAtPath))
          cardAtPath.forEach((c) => cards.push(c.card.card));
        else cards.push(cardAtPath);
      }
    });
  });

  cards.forEach((card) => {
    addCardToMenuBasedOnType(menu, card);
  });

  return menu;
}

function addCardToMenuBasedOnType(menu, card) {
  const type = card['@type'];
  if (type) {
    switch (type) {
      case RESTAURANT_MENU_CAROUSEL_CARD_TYPE:
        menu.menuCarouselCards.push(card);
        break;
      case RESTAURANT_INFO_CARD_TYPE:
        menu.restaurantInfoCards.push(card);
        break;

      case RESTAURANT_GRID_WIDGET_CARD_TYPE:
        menu.gridWidgetCards.push(card);
        break;
      case RESTAURANT_ITEM_CATEGORY_CARD_TYPE:
        menu.itemCategoryCards.push(card);
        break;
      case RESTAURANT_NESTED_ITEM_CATEGORY_CARD_TYPE:
        menu.nestedItemCategoryCards.push(card);
        break;
      case RESTAURANT_LICENSE_INFO_CARD_TYPE:
        menu.licenseInfoCards.push(card);
        break;
      case RESTAURANT_ADDRESS_CARD_TYPE:
        menu.addressCards.push(card);
        break;
      case RESTAURANT_MENU_VEG_FILTER_AND_BADGE_CARD_TYPE:
        menu.filterAndBadgeCards.push(card);
        break;

      default:
        console.error('default case reached, also manage card type ' + type);
    }
  }
}

function getCardByPath(obj, path) {
  const keys = path.split('.');

  return keys.reduce((acc, key) => {
    if (acc && typeof acc === 'object' && key in acc) {
      return acc[key];
    } else {
      return null;
    }
  }, obj);
}

export { fetchRestaurantMenu };
