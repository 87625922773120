import { useRef, useEffect, useState } from 'react';

import { LOGIN_IMAGE_ROUTE } from '../../../constants';

import CheckoutContainer from './CheckoutContainer';
import HeaderCheckout from '../header/HeaderCheckout';

import { UserIcon, Locate, Wallet, CircleCheckBig } from 'lucide-react';
import InputLabel from '../InputLabel';

import { useUser } from '@/context/UserContext';

import AnimateWhenDisabledButton from '../AnimateWhenDisabledButton';

import { Separator } from '@/components/ui/separator';
import { mockFetchUserCommunicationAndAddressDetails } from '@/api/userApiFetcher';
import { ProgressBar } from 'react-loader-spinner';
import { useCheckout } from '@/context/CheckoutContext';
import { postLogin, postSignup } from '@/api/authApi';
import { User } from '@/types/user';
import CheckoutAddress from './CheckoutAddress';
import CheckoutPayment from './CheckoutPayment';
import CheckoutCart from './CheckoutCart';
import { useShoppingCart } from '@/context/ShoppingCartContext';

import CartEmptyUI from './CartEmptyUI';

const stepImageStyle = {
  left: '-70px', // px-10 applied onto container
  width: '40px',
  height: '40px',
};

function Checkout() {
  return <CheckoutUI />;
}

/**
 * Renders dashed border from first step image top to last step image bottom
 */
function RenderDashedLeftBorder(props: {
  firstStepImageTop: number;
  lastStepImageBottom: number;
}) {
  const { firstStepImageTop, lastStepImageBottom } = props;

  return (
    <div
      className="absolute -left-2 top-[32px] w-[1px]  border-l-[1px] border-dashed border-gray-500/50"
      style={{
        height: lastStepImageBottom - firstStepImageTop,
      }}
    ></div>
  );
}

function CheckoutUI() {
  const [stepsImageTop, setStepsImageTop] = useState(0);
  const [stepsImageBottom, setStepsImageBottom] = useState(0);
  const { isLoggedIn } = useUser();
  const {
    getEmail,
    setEmail,
    getAddress,
    setAddress,
    getLocationAddress,
    getDeliveryCharges,
    setLocationAddress,
  } = useCheckout();
  const userLoggedIn = isLoggedIn();

  useEffect(() => {
    if (userLoggedIn && (getEmail().length === 0 || !getAddress())) {
      mockFetchUserCommunicationAndAddressDetails(
        (data) => {
          setEmail(data.email);
          setAddress(data.address);
          setLocationAddress(data.locationAddress);
        },
        (err) => {
          console.error(err);
          setAddress(null);
          setLocationAddress(null);
        },
      );
    }
  }, [userLoggedIn]);

  const containerPx = 'px-10';
  const containerPy = 'py-8';
  const { isCartEmpty } = useShoppingCart();

  if (isCartEmpty())
    return (
      <div>
        <HeaderCheckout />
        <CartEmptyUI />
      </div>
    );

  return (
    <div className="relative min-w-full min-h-full overflow-scroll bg-green-200 w-max h-max ">
      <div className="min-w-full min-h-full overflow-scroll w-max h-max bg-slate-200">
        <HeaderCheckout />

        <div className="flex items-center min-h-screen mr-8 2xl:justify-center">
          <div className="pl-12 pr-8 w-max min-w-[62%] 2xl:min-w-fit h-max ">
            <div className="relative">
              <RenderDashedLeftBorder
                lastStepImageBottom={stepsImageBottom}
                firstStepImageTop={stepsImageTop}
              />
              <CheckoutContainer
                className={`w-max min-w-full bg-slate-50 my-8 ${containerPx} ${containerPy}`}
                children={
                  <CheckoutAccountStep
                    onUserImageTopUpdate={setStepsImageTop}
                  />
                }
              />
              <CheckoutContainer
                className={`w-max min-w-full cursor-pointer bg-slate-50 my-8 ${containerPx} ${containerPy}`}
                children={<DeliveryAddressStep />}
                clickHandler={
                  !getLocationAddress()
                    ? undefined
                    : () => setLocationAddress(null)
                }
              />
              <CheckoutContainer
                className={`w-max min-w-full bg-slate-50 my-8 ${containerPx} ${containerPy}`}
                children={
                  <PaymentStep
                    isActive={
                      getDeliveryCharges() != null &&
                      getLocationAddress() != null
                    }
                    setWalletImageBottom={setStepsImageBottom}
                  />
                }
              />
            </div>
          </div>

          <CheckoutCart
            marginVertical={'my-8'}
            paddingHorizontal={containerPx}
            paddingVertical={containerPy}
          />
        </div>
      </div>
      <CartUpdatingUI />
    </div>
  );
}

function CartUpdatingUI() {
  const { getIsCartUpdating } = useCheckout();

  if (!getIsCartUpdating()) return null;

  return (
    <div className="absolute inset-0 w-max h-max">
      <div className="w-screen flex justify-center h-screen items-center bg-[#777777AA]">
        <p className="text-[rgb(120,20,80)] text-2xl">Cart updating...</p>
        <ProgressBar
          visible={true}
          height="56"
          borderColor="#444"
          barColor="rgb(120,20,100)"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
  );
}

function UserAccountStep_loggedIn(
  props: Readonly<{
    onUserImageTopUpdate: React.Dispatch<React.SetStateAction<number>>;
  }>,
) {
  const { onUserImageTopUpdate } = props;
  const { getName } = useUser();
  const { getEmail } = useCheckout();
  const userImageRef = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (userImageRef.current) {
      const rect = userImageRef.current.getBoundingClientRect();
      onUserImageTopUpdate(rect.top);
    }
  });
  return (
    <div className="">
      <div className="relative flex items-center mb-8 gap-6">
        <p className="font-semibold">Logged In</p>
        <CircleCheckBig color="#090" size={18} />
        <UserIcon
          ref={userImageRef}
          color="#ffffff"
          className={`absolute  -left-[70px] top-0 size-10  bg-black p-2 shadow-lg shadow-blackA7 `}
        />
      </div>
      <div className="flex items-center h-5 ">
        <p>{getName()}</p>

        <Separator orientation="vertical" className="mx-2 h-[80%] bg-black" />
        {getEmail().length > 0 && <p>{getEmail()}</p>}
        {getEmail().length === 0 && <UserDataLoadingProgressUI />}
      </div>
    </div>
  );
}

function CheckoutAccountStep(
  props: Readonly<{
    onUserImageTopUpdate: React.Dispatch<React.SetStateAction<number>>;
  }>,
) {
  const { onUserImageTopUpdate } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [imageMaxHeight, setImageMaxHeight] = useState<number>(120);
  const [state, setState] = useState<string>('default');

  const userImageRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (userImageRef.current) {
      const rect = userImageRef.current.getBoundingClientRect();
      onUserImageTopUpdate(rect.top);
    }
  });

  useEffect(() => {
    if (ref.current) {
      setImageMaxHeight(Math.max(0, ref.current.offsetHeight - 20));
    } else setImageMaxHeight(120);
  }, []);

  const [actionButtonActive, setActionButtonActive] = useState<boolean>(true);
  const [isValidationError, setIsValidationError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [isSignupSuccess, setIsSignupSuccess] = useState<boolean>(false);

  const emailFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const userNameFieldRef = useRef<HTMLInputElement>(null);
  const { onLogin } = useUser();

  const { isLoggedIn } = useUser();

  function switchToLoginView() {
    setState('login');
    setIsValidationError(false);
    setValidationErrorMessage('');
  }

  function switchToSignupView() {
    setState('signup');
    setIsValidationError(false);
    setValidationErrorMessage('');
  }

  function handleSignupClick() {
    setIsValidationError(false);
    setValidationErrorMessage('');
    postSignup(
      userNameFieldRef.current?.value,
      emailFieldRef.current?.value,
      passwordFieldRef.current?.value,
      (data: JSON) => {
        setActionButtonActive(true);

        setIsValidationError(false);
        setValidationErrorMessage('');

        setIsSignupSuccess(true);
      },
      (err: string) => {
        setActionButtonActive(true);

        setValidationErrorMessage(err);
        setIsValidationError(true);
        onLogin({ name: '', isAdmin: false, isLoggedIn: false });
        setIsSignupSuccess(false);
      },
    );
  }

  function handleLoginClick() {
    setIsValidationError(false);
    setValidationErrorMessage('');
    postLogin(
      emailFieldRef.current?.value,
      passwordFieldRef.current?.value,
      (user: User) => {
        setActionButtonActive(true);

        setIsValidationError(false);
        setValidationErrorMessage('');
        onLogin(user);
      },
      (err: string) => {
        setActionButtonActive(true);

        setValidationErrorMessage(err);
        setIsValidationError(true);
        onLogin({ name: '', isAdmin: false, isLoggedIn: false });
      },
    );
  }

  if (isLoggedIn()) {
    return (
      <UserAccountStep_loggedIn onUserImageTopUpdate={onUserImageTopUpdate} />
    );
  }
  return (
    <div
      ref={ref}
      className="relative flex items-center justify-between w-full gap-16"
    >
      <div>
        <p className="text-base font-bold">Account</p>
        <p className="text-sm text-gray-500 whitespace-nowrap">
          To place your order now, log in to your existing account or sign up.
        </p>
        <div
          className={`flex gap-8 my-8 ${state === 'default' ? 'visible' : 'hidden'}`}
        >
          <div
            className="flex flex-col items-center px-10 py-1.5 cursor-pointer border border-green-500 text-green-600 whitespace-nowrap hover:shadow-md"
            onClick={() => setState('login')}
          >
            <p className="text-xs font-extralight">Have an account?</p>
            <p className="text-sm font-semibold uppercase">Log in</p>
          </div>
          <div
            className="flex flex-col items-center px-10 py-3 text-white bg-green-500 cursor-pointer whitespace-nowrap hover:shadow-md"
            onClick={() => setState('signup')}
          >
            <p className="text-xs font-extralight">New to Swiggy?</p>
            <p className="text-sm font-semibold uppercase">Sign up</p>
          </div>
        </div>

        {state === 'login' && (
          <div className="mt-8">
            <p className="text-sm font-thin text-gray-500">
              Enter Login details or
              <span
                className="cursor-pointer text-orangeHover"
                onClick={() => switchToSignupView()}
              >
                {' '}
                create an account
              </span>
            </p>

            <div className="flex flex-col w-full mt-6 gap-2 max-w-80">
              <InputLabel
                ref={emailFieldRef}
                inputType="text"
                label={'Email'}
                containerClassName="w-full  px-4 py-2 border border-gray-200 "
                labelClassName={` text-zinc-400  min-h-6 mb-2`}
                inputClassName="text-gray-600"
                placeHolder=""
                focussedByDefault={true}
              />
              <InputLabel
                ref={passwordFieldRef}
                inputType="password"
                label={'Password'}
                containerClassName="w-full px-4 py-2 border border-gray-200 "
                labelClassName={` text-zinc-400  min-h-6 mb-2`}
                inputClassName="text-gray-600"
                placeHolder=""
                focussedByDefault={false}
              />

              {isValidationError && (
                <div className="flex items-center justify-start w-full mt-2 mb-2 text-xs text-validationError">
                  {validationErrorMessage}
                </div>
              )}

              <AnimateWhenDisabledButton
                disabled={!actionButtonActive}
                label="Login"
                disableLabel="Logging in..."
                className={`w-full py-6 mt-2 text-white bg-green-600 
                hover:bg-green-600 hover:shadow-md select-none
                 ${actionButtonActive ? 'cursor-pointer' : 'cursor-not-allowed'}
                `}
                clickHandler={() => {
                  setActionButtonActive(false);
                  handleLoginClick();
                }}
              />

              <p className="text-xs font-thin text-gray-600 ">
                By clicking on Login, I accept the
                <span className="font-medium text-gray-800">
                  {'  '}Terms & Conditions
                </span>
                {` ${'&'} `}
                <span className="font-medium text-gray-800">
                  {' '}
                  Privacy Policy
                </span>
              </p>
            </div>
          </div>
        )}

        {isSignupSuccess && state === 'signup' && (
          <CheckoutStep_SignupSuccessView
            switchToLoginView={switchToLoginView}
          />
        )}
        {!isSignupSuccess && state === 'signup' && (
          <div className="mt-8">
            <p className="text-sm font-thin text-gray-500">
              Sign up or
              <span
                className="cursor-pointer text-orangeHover "
                onClick={() => switchToLoginView()}
              >
                {' '}
                log in to your account
              </span>
            </p>
            <div className="flex flex-col w-full mt-6 gap-2 max-w-80">
              <InputLabel
                ref={userNameFieldRef}
                inputType="text"
                label={'Name'}
                containerClassName="w-full  px-4 py-2 border border-gray-200 "
                labelClassName={` text-zinc-400  min-h-6 mb-2`}
                inputClassName="text-gray-600"
                placeHolder=""
                focussedByDefault={true}
              />
              <InputLabel
                ref={emailFieldRef}
                inputType="text"
                label={'Email'}
                containerClassName="w-full  px-4 py-2 border border-gray-200 "
                labelClassName={` text-zinc-400  min-h-6 mb-2`}
                inputClassName="text-gray-600"
                placeHolder=""
                focussedByDefault={false}
              />
              <InputLabel
                ref={passwordFieldRef}
                inputType="password"
                label={'Password'}
                containerClassName="w-full px-4 py-2 border border-gray-200 "
                labelClassName={` text-zinc-400  min-h-6 mb-2`}
                inputClassName="text-gray-600"
                placeHolder=""
                focussedByDefault={false}
              />

              {isValidationError && (
                <div className="flex items-center justify-start w-full mt-2 mb-2 text-xs text-validationError">
                  {validationErrorMessage}
                </div>
              )}

              <AnimateWhenDisabledButton
                disabled={!actionButtonActive}
                label="Signup"
                disableLabel="Signing up..."
                className={`w-full py-6 mt-2 text-white bg-green-600 
                hover:bg-green-600 hover:shadow-md select-none
                 ${actionButtonActive ? 'cursor-pointer' : 'cursor-not-allowed'}
                `}
                clickHandler={() => {
                  setActionButtonActive(false);
                  handleSignupClick();
                }}
              />

              <p className="text-xs font-thin text-gray-600 ">
                By clicking on Login, I accept the
                <span className="font-medium text-gray-800">
                  {'  '}Terms & Conditions
                </span>
                {` ${'&'} `}
                <span className="font-medium text-gray-800">
                  {' '}
                  Privacy Policy
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
      <img
        alt=""
        src={LOGIN_IMAGE_ROUTE}
        style={{ maxHeight: `${imageMaxHeight}px` }}
        className="size-full max-w-40"
      ></img>

      <UserIcon
        ref={userImageRef}
        style={stepImageStyle}
        color="#ffffff"
        className={`absolute  top-0 size-10  bg-black p-2 shadow-lg shadow-blackA7 `}
      />
    </div>
  );
}

function CheckoutStep_SignupSuccessView(
  props: Readonly<{
    switchToLoginView: () => void;
  }>,
) {
  const { switchToLoginView } = props;

  return (
    <div className="flex flex-col items-start justify-center w-full h-full mt-8 gap-8 ">
      <div className="flex flex-col justify-between w-full mb-8 h-max">
        <p className="text-xl font-semibold">Signup successful</p>

        <hr className="my-2 border-gray-900 w-7 "></hr>
        <AnimateWhenDisabledButton
          disabled={false}
          label="Login now"
          disableLabel=""
          className={`w-full max-w-64 py-5 mt-10 text-white bg-green-600 
        hover:bg-green-600 hover:shadow-md select-none
        'cursor-pointer' }
        `}
          clickHandler={() => switchToLoginView()}
        />
      </div>
    </div>
  );
}

function UserDataLoadingProgressUI() {
  return (
    <ProgressBar
      visible={true}
      height="40"
      width="40"
      borderColor="#555"
      barColor="#777"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
}

function DeliveryAddressStep() {
  const { getLocationAddress } = useCheckout();
  const bgClassName = getLocationAddress() === null ? 'bg-white' : 'bg-black';
  return (
    <div className="relative w-full">
      <CheckoutAddress />
      <Locate
        style={stepImageStyle}
        strokeWidth={'1px'}
        color={getLocationAddress() === null ? '#000' : '#FFF'}
        className={`absolute top-0 p-2  shadow-lg size-10 shadow-blackA7 ${bgClassName}`}
      />
    </div>
  );
}

function PaymentStep(
  props: Readonly<{
    isActive: boolean;
    setWalletImageBottom: React.Dispatch<React.SetStateAction<number>>;
  }>,
) {
  const { isActive, setWalletImageBottom } = props;

  const walletRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (walletRef.current) {
      const rect = walletRef.current.getBoundingClientRect();
      setWalletImageBottom(rect.top);
    }
  });
  return (
    <div className="relative">
      <CheckoutPayment isActive={isActive} />
      <Wallet
        ref={walletRef}
        style={stepImageStyle}
        strokeWidth={'1px'}
        color="#000"
        className="absolute top-0 size-10  bg-white p-[7px] shadow-lg shadow-blackA7 "
      />
    </div>
  );
}

export default Checkout;
