function computeDishPrice(price: number, defaultPrice: number) {
  const p = price || defaultPrice;
  if (typeof p != 'number') throw new Error('price must be a number');
  if (Number.isNaN(p)) throw new Error('price must be a number');
  if (p < 0) throw new Error('price cannot be less than 0');

  return p / 100;
}

export { computeDishPrice };
