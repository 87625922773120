// Function to get the value of a single cookie by name
const getCookieByName = (name: string) => {
  const cookies = document.cookie.split(';');

  // Loop through each cookie
  for (let cookie of cookies) {
    // Trim leading/trailing spaces
    cookie = cookie.trim();

    // Check if the cookie starts with the specified name
    if (cookie.startsWith(name + '=')) {
      // Extract and return the cookie value
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }

  return null;
};

function removeCookieByName(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export { getCookieByName, removeCookieByName };
