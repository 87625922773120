import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';

import TrackOrderDishes from './TrackOrderDishes';
import { searchPhotos } from '@/utils/unsplashapi';
import { UnsplashApiResponseImageType } from '@/types/unsplashApiTypes';

function OrderTrackerDishes(props: {
  defaultOpen?: boolean;
  dishes: string[];
}) {
  const { defaultOpen = false, dishes } = props;

  const [blendImages, setBlendImages] = useState<
    UnsplashApiResponseImageType[]
  >([]);

  const [hasOpened, setHasOpened] = useState(false);

  const handleValueChange = (value: string | string[]) => {
    if (!hasOpened && value) {
      setHasOpened(true);
    }
  };

  useEffect(() => {
    async function fetchBlurImages() {
      const fetchedImages = await searchPhotos('color splash', 30);
      if (fetchedImages) setBlendImages(fetchedImages);
    }

    if (hasOpened) {
      fetchBlurImages();
    }
  }, [hasOpened]);

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full text-white bg-black rounded-md "
      defaultValue={defaultOpen ? 'item-1' : ''} // default open
      onValueChange={handleValueChange}
    >
      <AccordionItem value="item-1" className="border-none ">
        <AccordionTrigger className="px-[14px] md:px-5 text-sm md:text-lg h-16 cursor-pointer font-thin no-underline border-none hover:no-underline">
          <RenderDishesTitle dishes={dishes} />
        </AccordionTrigger>

        <AccordionContent className="px-2 py-10 rounded-b-md md:px-5 bg-gray-700/80">
          <TrackOrderDishes dishes={dishes} blendImages={blendImages} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function RenderDishesTitle(props: { dishes: string[] | undefined }) {
  const { dishes } = props;
  return (
    <div>
      Dishes
      {dishes && (
        <span className="text-base font-extralight">
          {` (${dishes.length})`}
        </span>
      )}
    </div>
  );
}

export default OrderTrackerDishes;
