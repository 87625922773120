import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { RestaurantAttributes } from '@/types/restaurantType';
import axios, { HttpStatusCode } from 'axios';
import { GET_RESTAURANTS_INFINITE_SCROLL_URL } from '@/api/endpoints/restaurantinfinitescrollendpoints';

type InfiniteScrollContextType = {
  getRestaurants: () => RestaurantAttributes[];
  getEndInfiniteScroll: () => boolean;
  getLoadMoreReference: () => React.MutableRefObject<HTMLDivElement | null>;
  isLoading: () => boolean;
  getTriggerLoadRestaurantCount: () => number;
  onFilterUpdate: () => void;
  toggleFilterSelection: (filter: string) => void;
  getFilters: () => Set<string>;
  getSelectedFilters: () => Set<string>;
  getSortLabel: () => string;
  updateSortLabel: (sortLabel: string) => void;
  clearSort: () => void;
  sortRestaurantsByRating: () => void;
  sortRestaurantsByCostDesc: () => void;
  sortRestaurantsByCostAsc: () => void;
  // sortRestaurantsByDeliveryTime: () => void;
};

const InfiniteScrollContext = createContext<InfiniteScrollContextType>(
  {} as InfiniteScrollContextType,
);

const SORT_TYPE_NONE = 'none';
const SORT_TYPE_RATING = 'rating';
const SORT_TYPE_COST_LOW_TO_HIGH = 'costAsc';
const SORT_TYPE_COST_HIGH_TO_LOW = 'costDesc';

const DefaultFilters = new Set<string>([
  'Fast Delivery',
  'Ratings 4.0+',
  'Pure Veg',
  'Offers',
  'Rs. 300-Rs.600',
  'Less than Rs. 300',
]);

export function InfiniteScrollProvider(
  props: Readonly<{ children: ReactNode }>,
) {
  const { children } = props;

  const [restaurants, setRestaurants] = useState<RestaurantAttributes[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageId, setPageId] = useState<number>(0);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const [endInfiniteScroll, setEndInfiniteScroll] = useState<boolean>(false);

  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(
    new Set(),
  );

  const [sortLabel, setSortLabel] = useState<string>('Sort By');

  const [sortType, setSortType] = useState<string>('none');

  const filters = DefaultFilters;

  const triggerLoadRestaurantCount = 15;

  function sortRestaurants(restaurants: RestaurantAttributes[]) {
    switch (sortType) {
      case SORT_TYPE_NONE:
        break;

      case SORT_TYPE_RATING:
        restaurants.sort((a, b) => b.avgRating - a.avgRating);
        break;

      case SORT_TYPE_COST_LOW_TO_HIGH:
        restaurants.sort((a, b) => a.costForTwo - b.costForTwo);
        break;

      case SORT_TYPE_COST_HIGH_TO_LOW:
        restaurants.sort((a, b) => b.costForTwo - a.costForTwo);
        break;
    }
  }

  const fetchRestaurants = useCallback(async () => {
    if (loading || endInfiniteScroll) return; // Prevent fetching if already loading or end of data

    setLoading(true);
    const count = triggerLoadRestaurantCount;
    const start = pageId * count;

    try {
      const filterArray = Array.from(selectedFilters);
      const filterString = JSON.stringify(filterArray);

      const req = await axios.get<RestaurantAttributes[]>(
        `${GET_RESTAURANTS_INFINITE_SCROLL_URL}?start=${start}&count=${count}&filters=${encodeURIComponent(filterString)}`,
      );

      if (req.status === HttpStatusCode.Ok) {
        if (req.data.length === 0) {
          setEndInfiniteScroll(true);
        } else {
          setRestaurants((prevRestaurants) => {
            const updatedRestaurants = [...prevRestaurants, ...req.data];
            sortRestaurants(updatedRestaurants);
            return updatedRestaurants;
          });
        }
      } else if (req.status === HttpStatusCode.NoContent) {
        setEndInfiniteScroll(true);
      } else {
        console.error('Error fetching data', req);
      }
    } catch (error) {
      console.error('Network error', error);
      setEndInfiniteScroll(true);
    }

    setLoading(false);
  }, [loading, endInfiniteScroll, pageId, triggerLoadRestaurantCount]);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (target.isIntersecting && !loading && !endInfiniteScroll) {
        setPageId((prev) => prev + 1);
      }
    },
    [loading, endInfiniteScroll],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    const currentRef = loadMoreRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    fetchRestaurants();
  }, [pageId]);

  const getRestaurants = useCallback(() => restaurants, [restaurants]);
  const getEndInfiniteScroll = useCallback(
    () => endInfiniteScroll,
    [endInfiniteScroll],
  );
  const getLoadMoreReference = useCallback(() => loadMoreRef, [loadMoreRef]);
  const isLoading = useCallback(() => loading, [loading]);
  const getTriggerLoadRestaurantCount = useCallback(
    () => triggerLoadRestaurantCount,
    [triggerLoadRestaurantCount],
  );

  const onFilterUpdate = useCallback(() => {
    setPageId(0);
    setLoading(false);
    setRestaurants([]);
  }, []);

  const getFilters = useCallback(() => filters, [filters]);
  const getSortLabel = useCallback(() => sortLabel, [sortLabel]);
  const updateSortLabel = useCallback((label: string) => {
    setSortLabel(label);
  }, []);

  const getSelectedFilters = useCallback(
    () => selectedFilters,
    [selectedFilters],
  );

  const toggleFilterSelection = useCallback((filter: string) => {
    setSelectedFilters((prevSelectedFilters) => {
      const newSelectedFilters = new Set(prevSelectedFilters);
      if (newSelectedFilters.has(filter)) {
        newSelectedFilters.delete(filter);
      } else {
        newSelectedFilters.add(filter);
      }
      return newSelectedFilters;
    });
  }, []);

  const clearSort = useCallback(() => {
    setSortType(SORT_TYPE_NONE);
    setEndInfiniteScroll(false);
  }, []);

  const sortRestaurantsByRating = useCallback(() => {
    setSortType(SORT_TYPE_RATING);
    setEndInfiniteScroll(false);
  }, []);

  const sortRestaurantsByCostAsc = useCallback(() => {
    setSortType(SORT_TYPE_COST_LOW_TO_HIGH);
    setEndInfiniteScroll(false);
  }, []);

  const sortRestaurantsByCostDesc = useCallback(() => {
    setSortType(SORT_TYPE_COST_HIGH_TO_LOW);
    setEndInfiniteScroll(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      getRestaurants,
      getEndInfiniteScroll,
      getLoadMoreReference,
      isLoading,
      getTriggerLoadRestaurantCount,
      onFilterUpdate,
      toggleFilterSelection,
      getFilters,
      getSelectedFilters,
      getSortLabel,
      updateSortLabel,
      clearSort,
      sortRestaurantsByRating,
      sortRestaurantsByCostAsc,
      sortRestaurantsByCostDesc,
    }),
    [
      getRestaurants,
      getEndInfiniteScroll,
      getLoadMoreReference,
      isLoading,
      getTriggerLoadRestaurantCount,
      onFilterUpdate,
      toggleFilterSelection,
      getFilters,
      getSelectedFilters,
      getSortLabel,
      updateSortLabel,
      clearSort,
      sortRestaurantsByRating,
      sortRestaurantsByCostAsc,
      sortRestaurantsByCostDesc,
    ],
  );

  return (
    <InfiniteScrollContext.Provider value={contextValue}>
      {children}
    </InfiniteScrollContext.Provider>
  );
}

export function useInfiniteScrollContext() {
  return useContext(InfiniteScrollContext);
}
