import {
  PAYMENT_CREATE_ORDER_ID_URL,
  PAYMENT_CANCEL_URL,
  PAYMENT_FAIL_URL,
  PAYMENT_VERIFY_URL,
} from '@/api/endpoints/paymentendpoints';
import axios, { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import logo from '@/assets/logo.svg';
import { useShoppingCart } from '@/context/ShoppingCartContext';
import { useUser } from '@/context/UserContext';
import { useCheckout } from '@/context/CheckoutContext';

import { PaymentFail } from '@/types/checkoutTypes';
import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';
import { CREATE_ORDER_ID_URL } from '@/api/endpoints/orderendpoints';

import PaymentDetails from '../checkout/PaymentDetails';

function CustomPaymentCheckout() {
  const {
    getCartTotal,
    getCartItems,
    getItemQuantity,
    getCartRestaurantDetails,
    clearCart,
  } = useShoppingCart();
  const { isLoggedIn, getName } = useUser();
  const { getEmail } = useCheckout();
  const { getDeliveryChargesTotal } = useCheckout();
  console.log(getDeliveryChargesTotal());

  const [errorCount, setErrorCount] = useState<number>(0); // used to refresh the component on error

  if (!isLoggedIn() || getEmail().length === 0) {
    console.log(`isLoggedIn : ${isLoggedIn()}`);
    console.log(`email : ${getEmail()}`);
    throw new Error("either user not logged-in or user's email not available");
    //ToDo: render UI here instead of throwing error
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  async function onPayClick() {
    try {
      const amount = (getCartTotal() + getDeliveryChargesTotal()) * 100;
      const currency = 'INR';
      const response = await axios.post(
        PAYMENT_CREATE_ORDER_ID_URL,
        {
          amount: amount,
        },
        {
          withCredentials: true,
        },
      );

      console.log(response);
      const razorPayOrderID = response.data.createdOrder.razorpay_order_id;
      console.log(razorPayOrderID);

      // Initialize Razorpay
      const razorpay = new Razorpay({
        key: import.meta.env.VITE_RAZORPAY_KEY_ID,
        amount: amount,
        currency: currency,
        name: 'Swiggy',
        description: 'Order Payment',
        image: logo,
        order_id: razorPayOrderID,
        handler: async function (response) {
          console.log(response);

          const data = {
            order_id: razorPayOrderID,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          const paymentSuccessPostRequest = await axios.post(
            PAYMENT_VERIFY_URL,
            data,
          );

          console.log(paymentSuccessPostRequest);

          if (paymentSuccessPostRequest.status === HttpStatusCode.Ok) {
            const items = getCartItems();
            const dishes = items.map((item) => {
              return {
                dishId: item.id,
                name: item.name,
                price: item.price,
                quantity: getItemQuantity(
                  getCartRestaurantDetails().id,
                  item.id,
                ),
              };
            });

            const request = await axios.post(
              CREATE_ORDER_ID_URL,
              {
                orderDate: new Date().toDateString(),
                orderTime: Date.now(),
                dishes: dishes,
                restaurantId: getCartRestaurantDetails().id,
                restaurantName: getCartRestaurantDetails().name,
                razorpayOrderId: response.razorpay_order_id,
              },
              {
                withCredentials: true,
              },
            );
            console.log(request);

            clearCart();
            navigateToUrlPostOrigin('/order-tracker');
          } else {
            navigateToUrlPostOrigin('/payment-failure');
          }
        },
        prefill: {
          name: getName(),
          email: getEmail(),
        },
        theme: {
          color: '#ed832e',
        },
        modal: {
          ondismiss: async function () {
            console.log('Checkout form closed');
            const cancelReq = await axios.post(PAYMENT_CANCEL_URL, {
              order_id: razorPayOrderID,
            });
            console.log(cancelReq);
            navigateToUrlPostOrigin('/payment-failure');
          },
        },
        allowed_methods: ['card'],
        retry: { enabled: false },
      });

      razorpay.on('payment.failed', async function (response) {
        console.log(response);

        const data = {
          failObject: {
            errorCode: response.error.code,
            errorReason: response.error.reason,
            errorSource: response.error.source,
            errorStep: response.error.step,
            orderId: response.error.metadata.order_id,
            paymentId: response.error.metadata.payment_id,
          } as PaymentFail,
        };

        const post = await axios.post(PAYMENT_FAIL_URL, data);
        console.log(post);
        navigateToUrlPostOrigin('/payment-failure');
      });

      razorpay.open();
    } catch (error) {
      setErrorCount(errorCount + 1);
      console.error('Error creating order:', error);
      alert(
        `We are facing some trouble completing your request at the moment. Please try again shortly.\n${error?.message}`,
      );
    }
  }

  return (
    <div className="relative flex flex-col h-full min-h-screen gap-0 bg-slate-100">
      <div className="sticky top-0 z-10 flex justify-between w-full min-w-full px-16 py-8 bg-white shadow-lg ">
        <p className="text-xl font-semibold ">Payment</p>
      </div>

      <div
        key={`error_${errorCount}`} // key set to re-render the component on error
        className="flex justify-center items-center w-full h-full min-h-[70vh]"
      >
        <PaymentDetails paymentButtonClickHandler={() => onPayClick()} />
      </div>
    </div>
  );
}

export default CustomPaymentCheckout;
