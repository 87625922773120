import RightSlidingLogin from '../RightSlidingLogin';
import { useEffect, useRef, useState } from 'react';
import { useUser } from '@/context/UserContext';

import * as Popover from '@radix-ui/react-popover';
import { navigateToUrlPostOrigin } from '@/utils/urlNavigator';
import { useHeaderVisibility } from '@/context/HeaderVisibilityContext';

function SignedOut() {
  const [paneOpen, setPaneOpen] = useState(false);
  const { setHeaderInvisible, setHeaderVisible } = useHeaderVisibility();

  useEffect(() => {
    if (paneOpen) setHeaderInvisible();
    else setHeaderVisible();
  }, [paneOpen]);
  return (
    <div>
      <button
        className="cursor-pointer hover:text-orangeHover"
        onClick={() => {
          setPaneOpen(true);
        }}
      >
        Sign In
      </button>
      <RightSlidingLogin
        open={paneOpen}
        onClose={() => {
          setPaneOpen(false);
        }}
      />
    </div>
  );
}

function SignedIn() {
  // offset overflow hidden (set during login process), after successful login control directly transfers here
  document.body.style.overflow = '';

  const { getName, useLogout } = useUser();
  const logout = useLogout;
  const elemStyleClassName =
    'min-w-max cursor-pointer hover:font-semibold text-gray-600 hover:text-gray-700';

  const popoverRef = useRef<HTMLDivElement>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const handleTouchOutside = (event: TouchEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      closePopover();
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchOutside);
    return () => {
      document.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);

  return (
    <Popover.Root open={isPopoverOpen} modal={true}>
      <Popover.Trigger
        className={`hover:text-[rgb(237,131,46)] ${isPopoverOpen ? 'text-[rgb(237,131,46)]' : 'text-slate-800'} min-w-12 min-h-12`}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        {getName()}
      </Popover.Trigger>

      <Popover.Content
        className="w-52 mt-4 mr-4 shadow-2xl p-4 bg-white rounded-lg border-x-0 border-y-0 border-t-2  border-[rgb(237,134,57)]"
        onInteractOutside={closePopover}
        onEscapeKeyDown={closePopover}
        onPointerDownOutside={closePopover}
        onFocusOutside={closePopover}
      >
        <div
          ref={popoverRef}
          className="flex flex-col gap-5 px-4 my-4 mr-16 text-sm font-normal tracking-wide"
        >
          <p
            className={`${elemStyleClassName}`}
            onClick={() => {
              navigateToUrlPostOrigin('/dashboard');
            }}
          >
            Profile
          </p>
          {/*  <p className={`${elemStyleClassName}`}>Orders</p>
          <p className={`${elemStyleClassName}`}>Swiggy One</p>
          <p className={`${elemStyleClassName}`}>Favourites</p> */}
          <p className={`${elemStyleClassName}`} onClick={() => logout()}>
            Logout
          </p>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}

export default function SignIn() {
  const { isLoggedIn } = useUser();
  if (isLoggedIn()) return <SignedIn />;
  return <SignedOut />;
}
