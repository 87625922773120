export const measureTextWidth = (
  text: string,
  fontSize: number,
  fontFamily: string,
  fontWeight: string,
) => {
  const temporaryElement = document.createElement('span');
  temporaryElement.style.margin = '0 6px';
  temporaryElement.style.fontSize = `${fontSize}px`;
  temporaryElement.style.fontFamily = fontFamily;
  temporaryElement.style.fontWeight = fontWeight;
  temporaryElement.innerText = text;

  document.body.appendChild(temporaryElement);

  const width = temporaryElement.offsetWidth;
  document.body.removeChild(temporaryElement);
  return width;
};
