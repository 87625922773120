import {
  UnsplashApiResponseImage,
  UnsplashImageUrlTypes,
} from '@/types/unsplashApiResonse';
import axios from 'axios';

const ACCESS_KEY = import.meta.env.VITE_UNSPLASH_ACCESS_KEY;

console.log(ACCESS_KEY);

const unsplashApi = axios.create({
  baseURL: 'https://api.unsplash.com',
  headers: {
    Authorization: `Client-ID ${ACCESS_KEY}`,
  },
});

export const searchSinglePhoto = async (query: string) => {
  const req = await unsplashApi.get('/search/photos', {
    params: { query, per_page: 1 },
  });
  console.log(req);
  if (req) {
    const photoElem = req.data.results[0];
    console.log(photoElem);
    if (photoElem) {
      return {
        id: photoElem.id,
        alt_description: photoElem.alt_description,
        color: photoElem.color,
        urls: {
          full: photoElem.urls.full,
          raw: photoElem.urls.raw,
          regular: photoElem.urls.regular,
          small: photoElem.urls.small,
          small_s3: photoElem.urls.small_s3,
          thumb: photoElem.urls.thumb,
        } as UnsplashImageUrlTypes,
      } as UnsplashApiResponseImage;
    } else return undefined;
  }
  return undefined;
};

export const searchPhotos = async (query: string, count: number) => {
  const req = await unsplashApi.get('/search/photos', {
    params: { query, per_page: count, order_by: 'curated' },
  });

  if (req) {
    const results = req.data.results;

    if (results) {
      const images = results.map((photoElem) => {
        return {
          id: photoElem.id,
          alt_description: photoElem.alt_description,
          color: photoElem.color,
          urls: {
            full: photoElem.urls.full,
            raw: photoElem.urls.raw,
            regular: photoElem.urls.regular,
            small: photoElem.urls.small,
            small_s3: photoElem.urls.small_s3,
            thumb: photoElem.urls.thumb,
          } as UnsplashImageUrlTypes,
        } as UnsplashApiResponseImage;
      });
      return images;
    } else return undefined;
  }
  return undefined;
};
