import { useInfiniteScrollContext } from '@/context/InfiniteScrollContext';

import { generateKey } from '@/utils/keygen';
import { sortFilters } from '@/utils/sortFilters';
import { useState } from 'react';
const RENDER_FILTERS = false;

function Filters() {
  const { getRestaurants, getEndInfiniteScroll, isLoading, getFilters } =
    useInfiniteScrollContext();

  const filters = getFilters();

  const restaurantsEmpty = getRestaurants().length === 0;

  if (!RENDER_FILTERS)
    return (
      <div className="w-full p-4 mt-4 overflow-x-auto py-8 rounded-md h-fit whitespace-nowrap no-scrollbar">
        <p className="text-xl font-bold">
          Restaurants with online food delivery in Delhi
        </p>
      </div>
    );

  if (getEndInfiniteScroll() && restaurantsEmpty) return null;

  if (restaurantsEmpty && isLoading()) {
    return (
      <div className="flex gap-4">
        <RenderFiltersSkeleton count={Math.max(4, filters.size / 2)} />
      </div>
    );
  }
  return (
    <div className="w-full p-4 mt-8 overflow-x-auto border rounded-md h-fit whitespace-nowrap no-scrollbar">
      <p className="text-xl font-bold">
        Restaurants with online food delivery in Delhi
      </p>

      <div className="flex mt-8 text-sm font-light gap-4">
        <SortByFilter />
        <FiltersUI />
      </div>
    </div>
  );
}

function SortByFilter() {
  const [selectionMode, setSelectionMode] = useState<boolean>(false);

  //   const [label, setLabel] = useState<string>('Sort By');

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const {
    onFilterUpdate,
    getSortLabel,
    updateSortLabel,
    sortRestaurantsByRating,
    sortRestaurantsByCostDesc,
    sortRestaurantsByCostAsc,
  } = useInfiniteScrollContext();

  function handleClick() {
    setSelectionMode(true);
  }

  function onSortOptionClicked(index: number) {
    setSelectedOptionIndex(index);
    updateSortLabel(options[index]);
    if (index === 2) sortRestaurantsByRating();
    if (index === 3) sortRestaurantsByCostAsc();
    if (index === 4) sortRestaurantsByCostDesc();
    setSelectionMode(false);
    onFilterUpdate();
  }

  const options = [
    'Relevance (default)',
    'Delivery Time',
    'Rating',
    'Cost:LowtoHigh',
    'Cost:HightoLow',
  ];

  if (selectionMode) {
    const optionsUi = options.map((o, index) => (
      <SortByOption
        key={generateKey()}
        label={o}
        index={index}
        isSelected={index === selectedOptionIndex}
        onClickHandler={onSortOptionClicked}
      />
    ));
    return (
      <div
        className="flex flex-col justify-between px-3 py-6 border border-gray-300 shadow-inner cursor-pointer select-none gap-4 
  w-fit rounded-3xl"
      >
        {optionsUi}
      </div>
    );
  }

  return (
    <div
      className="flex items-center justify-between gap-1  px-3 py-1.5 border border-gray-300 shadow-inner 
    cursor-pointer w-fit rounded-3xl  select-none"
      onClick={() => handleClick()}
    >
      <p>{getSortLabel()}</p>
      <span className="ml-1 font-normal text-[20px] material-symbols-rounded">
        keyboard_arrow_down
      </span>
    </div>
  );
}

function SortByOption(props: {
  label: string;
  index: number;
  isSelected: boolean;
  onClickHandler: (index: number) => void;
}) {
  const { label, index, isSelected, onClickHandler } = props;
  return (
    <div
      className="flex items-center justify-between gap-2 "
      onClick={() => onClickHandler(index)}
    >
      <p className="text-slate-800">{label}</p>
      <span
        className={`font-medium text-[17px] material-symbols-rounded ${isSelected ? 'text-orangeHover' : 'text-slate-500'}`}
      >
        {isSelected ? 'radio_button_checked' : 'radio_button_unchecked'}
      </span>
    </div>
  );
}

function FiltersUI() {
  const { getFilters, getSelectedFilters } = useInfiniteScrollContext();

  const filters = getFilters();
  const selectedFilters = getSelectedFilters();

  const sortedFilters = sortFilters(filters, selectedFilters);

  const items = sortedFilters.map((label) => {
    return <FilterUI key={generateKey()} filterLabel={label} />;
  });
  return items;
}

function FilterUI(
  props: Readonly<{
    filterLabel: string;
  }>,
) {
  const { filterLabel } = props;
  const { onFilterUpdate, getSelectedFilters, toggleFilterSelection } =
    useInfiniteScrollContext();
  const selectedFilters = getSelectedFilters();
  const isSelected = selectedFilters.has(filterLabel);

  function toggleSelection() {
    toggleFilterSelection(filterLabel);
    onFilterUpdate();
  }

  return (
    <div
      className={`flex items-center justify-between gap-1  px-3 py-1.5 border border-gray-300 shadow-inner 
      cursor-pointer w-fit max-h-10 rounded-3xl ${isSelected ? 'bg-slate-200' : ''} select-none`}
      onClick={() => toggleSelection()}
    >
      <p>{filterLabel}</p>

      {isSelected && (
        <span className="ml-1 font-normal text-[20px] material-symbols-rounded">
          close
        </span>
      )}
    </div>
  );
}

function RenderFiltersSkeleton(props: Readonly<{ count: number }>) {
  const { count } = props;
  const cards = [];
  for (let i = 0; i < count; i++) {
    cards.push(<FilterSkeleton key={generateKey()} />);
  }
  return (
    <div className="mt-8">
      <div className="w-2/5 h-8 bg-gray-300 max-w-96 rounded-3xl animate-pulse"></div>
      <div className="flex mt-16 gap-4">{cards}</div>
    </div>
  );
}

function FilterSkeleton() {
  return (
    <div className="w-32 h-8 bg-gray-300 shadow-inner  rounded-3xl animate-pulse" />
  );
}

export default Filters;
