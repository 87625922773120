import { useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Login from './Login';
import PropTypes from 'prop-types';
import { RightSlidingLoginProps } from '@/types/types';
import './RightSlidingLogin.css';

const RightSlidingLogin = ({
  open = false,
  onClose,
}: RightSlidingLoginProps) => {
  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [open]);

  return (
    <SlidingPane
      hideHeader={true}
      shouldCloseOnEsc={true}
      isOpen={open}
      from="right"
      width="400px"
      className="responsive-sliding-pane" // Add a custom class for responsive styles
      onRequestClose={onClose}
      children={<Login visible={open} onCloseButtonClick={onClose} />}
    ></SlidingPane>
  );
};

RightSlidingLogin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default RightSlidingLogin;
