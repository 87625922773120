import useTruncatedElement from '@/hooks/useTruncatedelement';
import React from 'react';
export default function TwoLineTextReadMore(
  props: Readonly<{
    text: string;
    textClassName: string;
    readMoreStyleClassName: string;
  }>,
) {
  const { text, textClassName, readMoreStyleClassName } = props;
  const ref = React.useRef(null);
  const { isTruncated, isReadingMore, setIsReadingMore } = useTruncatedElement({
    ref,
  });

  return (
    <div>
      <p
        ref={ref}
        className={` ${textClassName} break-words ${!isReadingMore && 'line-clamp-2'}`}
      >
        {text}
      </p>
      {isTruncated && !isReadingMore && (
        <p
          className={`${readMoreStyleClassName} cursor-pointer`}
          onClick={() => setIsReadingMore(true)}
        >
          Read more
        </p>
      )}
    </div>
  );
}
