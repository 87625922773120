import { UserDashboardLink } from '@/types/types';
import React, { useEffect, useRef, useState } from 'react';
import { generateKey } from '@/utils/keygen';
import PastOrders from './PastOrders';

import { useUser } from '@/context/UserContext';

const DashLinks: React.FC<{
  links: UserDashboardLink[];
  onLinkClick: React.Dispatch<React.SetStateAction<UserDashboardLink>>;
}> = ({ links, onLinkClick }) => {
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(0);

  const selectedLinkClassName =
    'font-medium text-primary bg-[rgb(250,250,250)]';
  return links.map((l, index: number) => {
    return (
      <button
        key={generateKey()}
        onClick={() => {
          setSelectedLinkIndex(index);
          onLinkClick(l);
        }}
        className={`ml-6  pl-4 py-4 pr-2 font ${index === selectedLinkIndex ? selectedLinkClassName : ''} hover:underline text-left whitespace-nowrap `}
      >
        {l.label}
      </button>
    );
  });
};

function SelectedLink(props: { selectedLink: UserDashboardLink }) {
  const { selectedLink } = props;
  return <div>{selectedLink.content}</div>;
}

function Dashboard() {
  const { getName } = useUser();

  const links: UserDashboardLink[] = [
    { label: 'Orders', content: <PastOrders /> },

    { label: 'Favourites', content: '#' },
    { label: 'Payments', content: '#' },
    { label: 'Addresses', content: '#' },
    { label: 'Settings', content: '#' },
  ];

  const [selectedLink, setSelectedLink] = useState(links[0]);
  const [linksStickyTop, setLinksStickyTop] = useState(0);

  const userDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLinksStickyTop(
      userDivRef.current
        ? userDivRef.current.getBoundingClientRect().height
        : 0,
    );
  }, [linksStickyTop]);

  return (
    <div className="mx-3 md:mx-16">
      <div ref={userDivRef} className="sticky top-0 py-4 mb-6">
        <h1 className="text-3xl font-semibold">{getName()}</h1>
      </div>

      <div className="flex gap-8">
        <div
          className="flex flex-col gap-8 w-fit  pr-20 h-fit py-8 bg-[rgb(238,241,246)] sticky "
          style={{ top: `${linksStickyTop}px` }}
        >
          <DashLinks links={links} onLinkClick={setSelectedLink} />
        </div>

        <div className="ml-8 ">
          <SelectedLink selectedLink={selectedLink} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
