import { useEffect, useRef, useState } from 'react';
import { useGoogleMap } from './GoogleMapContext';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { renderToStaticMarkup } from 'react-dom/server';

import { CircleAlertIcon } from 'lucide-react';
import AnimatedMarker from './AnimatedMarker';
import { animateMarker } from '@/utils/googleMarkerAnim';

function MyGoogleMap() {
  const {
    getPositionLatLng,
    getRiderPositionLatLng,
    getRoute,
    startSimulation,
    getAccumulatedStepIndexes,
  } = useGoogleMap();

  const zoom = 16;
  const freshUpdatesStepsCount = 1;
  const riderMarkerRef = useRef(null);
  const [prevTrigger, setPrevTrigger] = useState(0);
  const [riderPosition, setRiderPosition] = useState(getRiderPositionLatLng());
  const [currentRoute, setCurrentRoute] =
    useState<google.maps.LatLng[]>(getRoute());

  function updateMarkerPosition(newPosition: google.maps.LatLng) {
    setRiderPosition(newPosition);
  }

  useEffect(() => {
    if (
      getPositionLatLng() != undefined &&
      getRiderPositionLatLng() != undefined
    )
      setTimeout(() => {
        console.log('startSimulation');
        startSimulation();
      }, 1000);
  }, []);

  useEffect(() => {
    function applyFreshUpdates(fromStepIndex: number, toStepIndex: number) {
      const route = getRoute();
      const myInterval = setInterval(() => {
        fromStepIndex++;

        if (fromStepIndex < route.length && fromStepIndex <= toStepIndex) {
          console.log('applyFreshUpdates: stepIndex', fromStepIndex);

          // setRiderPosition({
          //   lat: route[fromStepIndex].lat(),
          //   lng: route[fromStepIndex].lng(),
          // });

          animateMarker(
            riderMarkerRef,
            route[fromStepIndex],
            updateMarkerPosition,
            currentRoute,
            () => {
              setCurrentRoute((prevRoute) => {
                return prevRoute.slice(1, undefined);
              });
            },
          );
        }
        if (fromStepIndex >= route.length) {
          clearInterval(myInterval);
          return;
        }

        // todo: apply remaining steps (if any) after route is compeletely traversed
      }, 60);
    }

    const freshUpdatesCount = getAccumulatedStepIndexes().length - prevTrigger;
    if (freshUpdatesCount >= freshUpdatesStepsCount) {
      setPrevTrigger(getAccumulatedStepIndexes().length);
      applyFreshUpdates(prevTrigger, getAccumulatedStepIndexes().length);
    }
  }, [prevTrigger, getRoute, getAccumulatedStepIndexes]);

  if (
    getPositionLatLng() === undefined ||
    getRiderPositionLatLng() === undefined
  )
    return null;

  const getSvgUrl = (IconComponent, color, transform) => {
    const svgString = renderToStaticMarkup(<IconComponent color={color} />);
    const modifiedSvgString = svgString.replace(
      '<svg',
      `<svg style="transform: ${transform}; fill: ${color};"`,
    );
    const encodedSvgString = encodeURIComponent(modifiedSvgString);
    return `data:image/svg+xml;charset=UTF-8,${encodedSvgString}`;
  };

  const bikeIconUrl = getSvgUrl(CircleAlertIcon, 'red', 'rotateY(0deg)');

  return (
    <div className="flex items-center justify-center w-full h-full">
      <GoogleMap
        center={riderPosition}
        zoom={zoom}
        mapContainerClassName="2xl:w-3/5 w-4/5  max-w-[1500px] h-4/5 rounded-md p-4 "
      >
        <Marker
          position={getPositionLatLng()}
          icon={{
            url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            scaledSize: new window.google.maps.Size(40, 40),
            anchor: new window.google.maps.Point(10, 12), // Sets the anchor to the center of the icon
          }}
        />

        <Marker
          ref={riderMarkerRef}
          position={riderPosition}
          icon={{
            url: bikeIconUrl,
            scaledSize: new window.google.maps.Size(20, 20),
            anchor: new window.google.maps.Point(10, 12), // Sets the anchor to the center of the icon
          }}
        />

        {/* <AnimatedMarker marker={marker} newPosition={newPosition} /> */}

        <Polyline
          path={currentRoute}
          options={{
            strokeColor: 'rgb(237, 131, 46)',
            strokeOpacity: 1.0,
            strokeWeight: 6,
          }}
        />
      </GoogleMap>
    </div>
  );
}

export default MyGoogleMap;
