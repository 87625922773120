import { useShoppingCart } from '@/context/ShoppingCartContext';
import * as Dialog from '@radix-ui/react-dialog';

/**
 * Component for switching the cart restaurant.
 * @component

 * @returns JSX to render switch cart restaurant
 * used when cart contains items from one restaurant and user is trying to
 *  add items from other restaurant
 */
function SwitchCartRestaurantDialog() {
  const {
    getCartRestaurantSwitchDialogVisibility,
    hideCartRestaurantSwitchDialog,
    performCartRestaurantSwitch,
  } = useShoppingCart();

  return (
    <Dialog.Root
      open={getCartRestaurantSwitchDialogVisibility()}
      onOpenChange={hideCartRestaurantSwitchDialog}
      modal={false}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 " />
        <Dialog.Content
          className="data-[state=closed]:animate-alphaBarDown data-[state=open]:animate-alphaBarUp 
        fixed bottom-0 left-0 h-fit max-h-[85vh] w-screen 
         focus:outline-none"
        >
          <div className="w-screen flex justify-center items-center ">
            <div
              className="w-full max-w-[300px] md:max-w-[530px] bg-white p-4 md:p-8
            shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]"
            >
              <h1 className="text-3xl font-semibold">Items already in cart</h1>
              <p className="text-[rgb(99,101,114)] text-xs mt-2  pr-8">
                Your cart contains items from other restaurant. Would you like
                to reset your cart for adding items from this restaurant?
              </p>

              <div className="mt-[32px] flex flex-col md:flex-row w-full gap-4  ">
                <button
                  className="w-full md:w-1/2 max-w-80 border-[rgb(117,176,85)] text-[rgb(117,176,85)] border-2 
                px-12 py-2 uppercase whitespace-nowrap overflow-hidden  text-center outline-none hover:scale-90 hover:drop-shadow-lg transition-transform duration-200"
                  onClick={hideCartRestaurantSwitchDialog}
                >
                  No
                </button>
                <button
                  className="w-full md:w-1/2 max-w-80  bg-[rgb(117,176,85)] text-white 
                px-12 py-2  uppercase whitespace-nowrap  overflow-hidden text-center outline-none hover:scale-90 hover:drop-shadow-lg transition-transform duration-200 "
                  onClick={() => {
                    performCartRestaurantSwitch();
                    hideCartRestaurantSwitchDialog();
                  }}
                >
                  Yes, start afresh
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default SwitchCartRestaurantDialog;
