import { CartItem, CartRestaurant, ShoppingCartData } from '@/types/carttypes';

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type ShoppingCartContext = {
  getTotalItemsCount: () => number;
  getItemQuantity: (restaurantId: number, id: number) => number;
  addItemToCart: (cartItem: CartItem) => void;
  increaseQuantity: (id: number) => void;
  decreaseQuantity: (id: number) => void;
  removeFromCart: (id: number) => void;
  clearCart: () => void;
  logCartItems: () => void;
  getCartItems: () => CartItem[];
  getCartRestaurantDetails: () => CartRestaurant;
  setCartRestaurantDetails: (
    id: number,
    imageId: string,
    name: string,
    areaName: string,
  ) => void;
  getCartTotal: () => number;
  /**
   *
   * @returns true if items can be added into cart, false otherwise
   */
  canAddDishFromRestaurant: (restaurantID: number) => boolean;

  /**
   * Sets State for {@link SwitchCartRestaurantDialog} to show
   */
  showCartRestaurantSwitchDialog: (
    restaurantDetails: CartRestaurant,
    cartItem: CartItem,
  ) => void;
  /**
   * Sets State for {@link SwitchCartRestaurantDialog} to hide
   */
  hideCartRestaurantSwitchDialog: () => void;
  /**
   * returns show/hide State for {@link SwitchCartRestaurantDialog}
   */
  getCartRestaurantSwitchDialogVisibility: () => boolean;

  /**
   * Performs cart restaurant switch by replacing prev data with switch restaurant and dish data

   */
  performCartRestaurantSwitch: () => void;

  isCartEmpty: () => boolean;
};

const CART_DATA_LOCAL_STORAGE_KEY = 'cartData';

type ShoppingCartProviderProps = {
  children: ReactNode;
};

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

const EMPTY_CART_RESTAURANT = {
  id: -1,
  name: '',
  areaName: '',
} as CartRestaurant;

/**
 *
 * @param key key for value to look-up
 * @param defaultValue value to return if key not found
 * @returns
 */
function getFromLocalStorage(key: string, defaultValue: ShoppingCartData) {
  const temp = localStorage.getItem(key);
  if (!temp) return defaultValue;
  const json = JSON.parse(temp);
  return json || defaultValue;
}

function putIntoLocalStorage(key: string, value: ShoppingCartData) {
  const temp = JSON.stringify(value);
  localStorage.setItem(key, temp);
}

export function ShoppingCartProvider({
  children,
}: Readonly<ShoppingCartProviderProps>) {
  const [shoppingCartData, setShoppingCartData] = useState<ShoppingCartData>(
    getFromLocalStorage(CART_DATA_LOCAL_STORAGE_KEY, {
      cartItems: [],
      cartRestaurant: EMPTY_CART_RESTAURANT,
    }),
  );

  const [cartItems, setCartItems] = useState<CartItem[]>(
    shoppingCartData.cartItems,
  );
  const [cartRestaurant, setCartRestaurant] = useState<CartRestaurant>(
    shoppingCartData.cartRestaurant,
  );
  /**
   * State variable to hold Restaurant data used during Cart restaurant Switch
   */
  const [switchCartRestaurant, setSwitchCartRestaurant] =
    useState<CartRestaurant>(EMPTY_CART_RESTAURANT);

  /**
   * State variable to hold dish data used during Cart restaurant Switch
   */
  const [switchCartItem, setSwitchCartItem] = useState<CartItem>();

  const [cartRestaurantSwitchDialogState, setCartRestaurantSwitchDialogState] =
    useState(false);

  useEffect(() => {
    setShoppingCartData({ cartItems, cartRestaurant });
    putIntoLocalStorage(CART_DATA_LOCAL_STORAGE_KEY, {
      cartItems,
      cartRestaurant,
    });
  }, [cartItems, cartRestaurant]);

  function getCartRestaurantDetails() {
    return cartRestaurant;
  }

  function setCartRestaurantDetails(
    id: number,
    imageId: string,
    name: string,
    areaName: string,
  ) {
    setCartRestaurant({
      id: id,
      imageId: imageId,
      name: name,
      areaName: areaName,
    });
  }

  function getTotalItemsCount() {
    return cartItems.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  }

  function getItemQuantity(restaurantId: number, id: number) {
    if (cartRestaurant.id != restaurantId) return 0;
    return cartItems.find((item) => item.id === id)?.quantity || 0;
  }

  function addItemToCart(cartItem: CartItem) {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === cartItem.id)) return currItems;
      return [...currItems, { ...cartItem, quantity: 1 }];
    });
  }

  function increaseQuantity(id: number) {
    console.log('increase quantity called');

    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id) == null) {
        return currItems;
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity + 1 };
          } else return item;
        });
      }
    });
  }

  function decreaseQuantity(id: number) {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id)?.quantity === 1) {
        return currItems.filter((item) => item.id != id);
      } else {
        return currItems.map((item) => {
          return item.id === id
            ? { ...item, quantity: item.quantity - 1 }
            : item;
        });
      }
    });
  }

  function removeFromCart(id: number) {
    setCartItems((currItems) => {
      return currItems.filter((item) => item.id != id);
    });
  }

  function clearCart() {
    setCartRestaurantDetails(-1, '', '', '');
    setCartItems([]);
  }

  function logCartItems() {
    console.log(cartItems);
  }

  function getCartItems() {
    return cartItems;
  }

  function getCartTotal() {
    return cartItems.reduce((total, item) => {
      return total + item.quantity * item.price;
    }, 0);
  }

  function canAddDishFromRestaurant(restaurantID: number) {
    return getTotalItemsCount() === 0
      ? true
      : cartRestaurant.id === -1 || cartRestaurant.id === restaurantID;
  }

  function showCartRestaurantSwitchDialog(
    restaurantDetails: CartRestaurant,
    cartItem: CartItem,
  ) {
    setSwitchCartRestaurant(restaurantDetails);
    setSwitchCartItem(cartItem);
    setCartRestaurantSwitchDialogState(true);
    console.log('showCartRestaurantSwitchDialog called');
  }

  function hideCartRestaurantSwitchDialog() {
    setCartRestaurantSwitchDialogState(false);
  }

  function getCartRestaurantSwitchDialogVisibility() {
    return cartRestaurantSwitchDialogState;
  }

  function performCartRestaurantSwitch() {
    clearCart();
    if (!switchCartRestaurant)
      console.error(
        'switchCartRestaurant not there, make sure to pass switchCartRestaurant on Add button click',
      );
    setCartRestaurant(switchCartRestaurant);
    if (!switchCartItem)
      console.error(
        'switchCartItem not there, make sure to pass switchCartItem on Add button click',
      );
    else addItemToCart(switchCartItem);
    setSwitchCartRestaurant(EMPTY_CART_RESTAURANT);
    setSwitchCartItem(undefined);
  }

  function isCartEmpty() {
    return (
      shoppingCartData.cartItems.length === 0 ||
      shoppingCartData.cartRestaurant.id < 0
    );
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getTotalItemsCount,
        getItemQuantity,
        addItemToCart,
        increaseQuantity,
        decreaseQuantity,
        removeFromCart,
        clearCart,
        logCartItems,
        getCartItems,
        setCartRestaurantDetails,
        getCartRestaurantDetails,
        getCartTotal,
        canAddDishFromRestaurant,
        showCartRestaurantSwitchDialog,
        hideCartRestaurantSwitchDialog,
        getCartRestaurantSwitchDialogVisibility,
        performCartRestaurantSwitch,
        isCartEmpty,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
