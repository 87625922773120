/**
 * Navigates to url from origin in same tab
 * @param url :url endpoint
 */
export const navigateToUrlPostOrigin = (url: string) => {
  window.open(`${window.location.origin}${url}`, '_self');
};
/**
 * Navigates to url from origin in new tab
 * @param url :url endpoint
 */
export const navigateToUrlPostOrigin_newTab = (url: string) => {
  window.open(`${window.location.origin}${url}`);
};
