import { AUTH_LOGIN_ROUTE, AUTH_SIGNUP_ROUTE } from '@/backendconstants';
import { User } from '@/types/user';
import axios, { AxiosError, HttpStatusCode } from 'axios';

export const postLogin = async (
  email: string | undefined,
  password: string | undefined,
  successCallback: (user: User) => void,
  errCallback: (err: string) => void,
) => {
  const formData = { email: email, password: password };
  try {
    const postRequest = await axios.post(AUTH_LOGIN_ROUTE, formData, {
      withCredentials: true,
    });

    if (postRequest.status === HttpStatusCode.Ok) {
      const { data } = postRequest;
console.log(data);

      if (data.success) {
        successCallback({
          name: data.name,
          isLoggedIn: true,
          isAdmin: false,
        });
      }
    }
  } catch (err: unknown) {
    console.error(`error while attempting to login `);
    console.log(err);

    if (err instanceof AxiosError && !err.response) {
      errCallback(err.code);
    } else {
      if (err.response.data) errCallback(err.response.data);
      else errCallback('some error occured');
    }
  }
};

export async function postSignup(
  name: string | undefined,
  email: string | undefined,
  password: string | undefined,
  successCallback: (data: JSON) => void,
  errCallback: (err: string) => void,
) {
  try {
    const formData = { name: name, email: email, password: password };
    // console.log(formData);

    const postRequest = await axios.post(AUTH_SIGNUP_ROUTE, formData, {
      withCredentials: true,
    });

    if (postRequest.statusText === 'OK') {
      const { data } = postRequest;
      console.log(data);

      if (data.success) {
        successCallback(data);
      }
    }
  } catch (err: unknown) {
    console.error(`error while attempting to signup `);
    console.log(err);

    if (err instanceof AxiosError && !err.response) {
      errCallback(err.code);
    } else {
      if (err.response.data) errCallback(err.response.data);
      else errCallback('some error occured');
    }
  }
}
